import isEmpty from 'lodash/isEmpty';
import { v4 as uuidv4 } from 'uuid';

export const getDuplicateChildrenNodes = (data = {}, id) => {
  let collection = {};
  let newGenerateId = uuidv4();
  let firstChildrenNodeId = '';
  const foo = (node, newId, nodeId, newNodePath, parent) => {
    let newChildrenIds = node.children.map(() => uuidv4());
    let path = nodeId ? [...newNodePath] : [...node.path];
    if (firstChildrenNodeId === '') {
      firstChildrenNodeId = newId;
    }
    if (node.id) {
      if (nodeId) {
        path.push({
          id: nodeId, type: parent.type
        })
        collection = {
          ...collection,
          [newId]: {
            ...node,
            id: newId,
            children: [...newChildrenIds],
            path: [...path ]
          }
        }
      } else {
        collection = {
          ...collection,
          [newId]: {
            ...node,
            id: newId,
            children: [...newChildrenIds],
            path: [...path]
          }
        }
      }
    }
    if (!isEmpty(node.children)) {
      node.children.forEach((item, index) => {
        foo(data[item], newChildrenIds[index], newId, path, node)
      })
    }
  }
  foo(data[id], newGenerateId);
  return [ collection, firstChildrenNodeId ];
};
