import { Icon } from '@chakra-ui/react';

export const Angle = (props) => {
  return (
    <Icon viewBox="0 0 8 8" {...props}>
      <g fill="currentColor">
        <path d="M1,0 L1,7 L8,7 L8,8 L0,8 L0,0 L1,0 Z" />
      </g>
    </Icon>
  );
};
