import { FAILURE, REQUEST, SUCCESS } from './actions-type';
import axios from 'axios';

export const ACTION_TYPES = {
  FETCH_ENTITIES_LIST: 'projects/FETCH_ENTITIES_LIST',
  RESET_ENTITIES_LIST: 'projects/RESET_ENTITIES_LIST',
  CREATE_ENTITY: 'projects/CREATE_ENTITY',
  UPDATE_ENTITY: 'projects/UPDATE_SMTP',
  DELETE_ENTITY: 'projects/DELETE_ENTITY',
  RESET_ENTITY: 'projects/RESET_SMTP',
  RESET: 'projects/RESET'
};

const initialState = {
  entities: [],
  loading: false,
  loadingDelete: false,
  errorMessage: ''
};

export const projectListReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.DELETE_ENTITY):{
      return {
        ...state,
        loadingDelete: true
      }
    }
    case REQUEST(ACTION_TYPES.FETCH_ENTITIES_LIST):
    case REQUEST(ACTION_TYPES.CREATE_ENTITY):
    case REQUEST(ACTION_TYPES.UPDATE_ENTITY): {
      return {
        ...state,
        loading: true,
        errorMessage: ''
      }
    }
    case FAILURE(ACTION_TYPES.DELETE_ENTITY):{
      return {
        ...state,
        loadingDelete: false
      }
    }
    case FAILURE(ACTION_TYPES.FETCH_ENTITIES_LIST):
    case FAILURE(ACTION_TYPES.CREATE_ENTITY):
    case FAILURE(ACTION_TYPES.UPDATE_ENTITY): {
      return {
        ...state,
        loading: false,
        errorMessage: 'error'
      }
    }
    case SUCCESS(ACTION_TYPES.FETCH_ENTITIES_LIST): {
      return {
        ...state,
        loading: false,
        errorMessage: '',
        entities: action.payload.data.items
      }
    }
    case SUCCESS(ACTION_TYPES.UPDATE_ENTITY): {
      const index = state.entities.findIndex(item => item.id === action.payload.data.id);
      const entities = [...state.entities];
      entities[index].name = action.payload.data.name;
      return {
        ...state,
        loadingDelete: false,
        entities: entities
      }
    }
    case SUCCESS(ACTION_TYPES.CREATE_ENTITY): {
      const newEntities = state.entities.concat([{
        id: action.payload.data.id,
        name: action.payload.data.name
      }])
      return {
        ...state,
        loading: false,
        errorMessage: '',
        entities: newEntities
      }
    }
    case SUCCESS(ACTION_TYPES.DELETE_ENTITY): {
      const entities = state.entities.filter(item => item.id !== action.payload.data.id);
      return {
        ...state,
        entities,
        loading: false,
        errorMessage: ''
      }
    }
    case ACTION_TYPES.RESET_ENTITIES_LIST: {
      return {
        ...state,
        entities: []
      }
    }
    case ACTION_TYPES.RESET: {
      return {
        ...state,
        entities: [],
        loading: false
      }
    }
    default: return state;
  }
};

const API_URL = '/api';

export const getProjectList = (dataRequest) => {
  const requestUrl = `${API_URL}/templates/search`;
  return {
    type: ACTION_TYPES.FETCH_ENTITIES_LIST,
    payload: axios.post(requestUrl, {
      ...dataRequest
    })
  };
};

export const resetProjectList = () => {
  return {
    type: ACTION_TYPES.RESET_ENTITIES_LIST
  };
}

export const deleteProject = (id) => {
  const requestUrl = `${API_URL}/templates/${id}`;
  return {
    type: ACTION_TYPES.DELETE_ENTITY,
    payload: axios.delete(requestUrl)
  };
};

export const reset = () => {
  return {
    type: ACTION_TYPES.RESET
  };
};
