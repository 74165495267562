import { v4 as uuidv4 } from 'uuid';

export const dividerPreset = () => {
  return {
    id: uuidv4(),
    type: 'divider',
    attributes: {
      'padding-left': '0px',
      'padding-right': '0px',
      'padding-top': '20px',
      'padding-bottom': '0px',
      'border-width': '1px',
      'border-color': '#E2E8F0'
    },
    properties: {},
    children: []
  }
};
