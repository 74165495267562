export const downNodeAction = (state, action) => {
  const entityNodes = { ...state.entityNodes };
  const children = [...entityNodes[action.node.id].children];
  const indexChildren = children.findIndex(item => item === action.childrenNodeId);
  const lengthChildren = children.length;
  [children[indexChildren], children[indexChildren + 1]] = [children[indexChildren + 1], children[indexChildren]];

  if (indexChildren !== lengthChildren - 1) {
    entityNodes[action.node.id] = {
      ...entityNodes[action.node.id],
      children: [
        ...children
      ]
    }
  }

  return entityNodes;
};
