import { getCssFromAttributes } from '../../../utils/getCssFromAttributes';

export const headStyleHtml = (data, parentNode, node) => {
  const { properties, attributes } = node;
  return (`
    <mj-style inline="inline">
      .${properties.className} {
        ${getCssFromAttributes(attributes)}
      }
    </mj-style>
  `);
};
