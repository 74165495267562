import React, { useCallback, useEffect, useRef, useState } from 'react';
import './style.css';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Box,
  Portal
} from '@chakra-ui/react';
import ColorPicker, { useColor } from '../react-color-palette';

export const PopoverPickerMemo = ({ color, defaultIsOpen = false, onChange }) => {
  const [colorValue, setColorValue] = useColor('hex', color);

  useEffect(() => (
    onChange(colorValue.hex)
  ), [colorValue]);

  return (
    <Popover
     //maxWidth="200px"
     //width="207px"
     defaultIsOpen={defaultIsOpen}
     placement="auto"
    >
      <PopoverTrigger>
        <Box
          height="14px"
          width="14px"
          borderRadius={2}
          backgroundColor={color}
          borderWidth={1}
          borderColor={color === '#ffffff' ? 'gray.200' : 'transparent'}
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          //width="207px"
          overflow="hidden"
          borderRadius={3}
          boxShadow="rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.1) 0px 9px 24px"
          borderWidth={0}
          maxWidth="207px"
          marginLeft="auto"
          _focus={{
            outline: 'none'
          }}

        >
          <PopoverBody padding={0}>
            <ColorPicker width={207} height={180} color={colorValue} onChange={setColorValue} />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

function areEqual(prevProps, nextProps) {
  return (prevProps.color === nextProps.color)
}

export const PopoverPicker = React.memo(PopoverPickerMemo, areEqual);
