import React from 'react';
import { StackControl } from '../stackControl';
import { GridControl } from '../gridControl';
import { PaddingTopControl } from '../controls/paddingTop.control';
import { PaddingRightControl } from '../controls/paddingRight.control';
import { PaddingBottomControl } from '../controls/paddingBottom.control';
import { PaddingLeftControl } from '../controls/paddingLeft.control';
import { BorderRadiusControl } from '../controls/borderRadius.control';
import { FullWidthControl } from '../controls/fullWidth.control';
import { ColorControl } from '../controls/color.control';
import { ImageControl } from '../controls/image.control';
import { BorderControl } from '../controls/border.control';
import { TextAlignControl } from '../controls/textAlign.control';
import { DirectionControl } from '../controls/direction.control';
import { useSelector } from 'react-redux';

export const SectionModuleMemo = ({ nodeId }) => {
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  return (
    <>
      <StackControl>
        <GridControl label="Отступы">
          <PaddingLeftControl nodeId={node.id} placeholder="0px"/>
          <PaddingRightControl nodeId={node.id} placeholder="0px"/>
          <PaddingTopControl nodeId={node.id} placeholder="20px"/>
          <PaddingBottomControl nodeId={node.id} placeholder="20px"/>
        </GridControl>
        <GridControl label="Скругление">
          <BorderRadiusControl nodeId={node.id} placeholder="0px"/>
        </GridControl>
      </StackControl>
      <StackControl>
        <GridControl templateColumns="1fr">
          <FullWidthControl nodeId={node.id}/>
        </GridControl>
      </StackControl>
      <StackControl>
        <GridControl label="Фон">
          <ColorControl nodeId={node.id} attribute="background-color"/>
        </GridControl>
        <GridControl templateColumns="1fr" label="Фоновая картинка">
          <ImageControl nodeId={node.id} attribute="background-url" />
        </GridControl>
      </StackControl>
      <StackControl>
        <GridControl templateColumns="1fr" label="Рамка">
          <BorderControl nodeId={node.id}/>
        </GridControl>
        <GridControl label="Выравнивание">
          <TextAlignControl nodeId={node.id}/>
        </GridControl>
      </StackControl>
      <StackControl>
        <GridControl templateColumns="1fr" >
          <DirectionControl nodeId={node.id}/>
        </GridControl>
      </StackControl>
    </>
  );
};

export const SectionModule = React.memo(SectionModuleMemo);
