import { combineReducers } from 'redux';
import undoable from 'redux-undo';
import { projectReducer } from './project.reducer';
import { authenticationReducer } from './authentication.reducer';
import { settingMailReducer } from './settingMail.reducer';
import { projectListReducer } from './projectList.reducer';
import { imageReducer } from './image.reducer';
import { userReducer } from './user.reducer';
import { htmlReducer } from './html.reducer';
import { selectedReducer } from './selected.reducer';

export const rootReducer = combineReducers({
  project: undoable(projectReducer),
  authentication: authenticationReducer,
  settingMail: settingMailReducer,
  projectList: projectListReducer,
  image: imageReducer,
  user: userReducer,
  html: htmlReducer,
  selected: selectedReducer
});
