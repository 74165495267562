import React from 'react';
import { Element } from '../element';
import { SelectedNode } from '../../selectedNode';
import { getAttribute } from '../../../utils/getAttribute';
import { useSelector } from 'react-redux';

const GroupMemo = ({ parentNodeId, nodeId }) => {
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  const parentNode = projectNodes[parentNodeId];
  const { attributes, children } = node;
  const width = attributes.width ? attributes.width : `${100/parentNode.children.length}%`;
  return (
    <SelectedNode
      nodeId={node.id}
      className="group"
      display="inline-block"
      lineHeight={1}
      width={width}
      //width={getAttribute(attributes, 'width', '100%')}
      backgroundColor={getAttribute(attributes, 'background-color')}
      verticalAlign={getAttribute(attributes, 'vertical-align', 'top')}
      direction={getAttribute(attributes, 'direction', 'ltr')}
    >
      {children.map(item => (
        <Element
          key={item}
          parentNodeId={node.id}
          nodeId={item}
        />
      ))}
    </SelectedNode>
  );
};

export const Group = React.memo(GroupMemo);