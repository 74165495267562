import { v4 as uuidv4 } from 'uuid';

export const groupPreset = () => {
  return {
    id: uuidv4(),
    type: 'group',
    attributes: {},
    properties: {},
    children: [
      {
        id: uuidv4(),
        type: 'column',
        attributes: {
          'padding-top': '10px',
          'padding-bottom': '10px'
        },
        properties: {},
        children: []
      }
    ]
  }
};
