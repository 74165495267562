import React from 'react';
import { Route } from 'react-router-dom';
import { getAccess } from '../../utils/getAccess';

export const PrivateRoute = ({ children, role, hasAnyRole, ...props }) => {
  const access = getAccess(role, hasAnyRole);
  return (
    <Route {...props}>
      {access ? children : 'Нет доступа'}
    </Route>
  );
};
