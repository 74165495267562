import React from 'react';
import { Box, Image, MenuItem } from '@chakra-ui/react';
import { SnippetGrid } from '../../components/layout';
import { MenuList } from '../../components/menu';
import { deleteImagesCategories } from '../../store/image.reducer';
import { useDispatch } from 'react-redux';

export const CategoryItem = ({ item, ...props }) => {
  const dispatch = useDispatch();
  const { name } = item;

  const handleDeleteClick = (id) => {
    dispatch(deleteImagesCategories(id))
  };

  return (
    <SnippetGrid
      preview={
        <Box
          className="fixed-border-safari"
          borderTopLeftRadius={4}
          borderTopRightRadius={4}
          padding="6px 6px 0 6px"
          //backgroundColor="#ffffff"
          width="160px"
          height="100px"
          overflow="hidden"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box>
              <Box
                width="34px"
                height="4px"
                borderTopLeftRadius="6px"
                borderTopRightRadius="6px"
                bg="blue.300"
              />
              <Box
                width="70px"
                height="5px"
                borderTopRightRadius="6px"
                bg="blue.300"
              />
            </Box>
            <Box
              width="90px"
              height="56px"
              borderRadius="6px"
              bg="blue.200"
            />
          </Box>
        </Box>
      }
      menuItems={
        <MenuItem color="red.500" onClick={() => handleDeleteClick(item.id)}>Удалить</MenuItem>
      }
      {...props}
    >
      {name}
    </SnippetGrid>
  );
};
