export const upNodeAction = (state, action) => {
  const entityNodes = { ...state.entityNodes };
  const children = [...entityNodes[action.node.id].children];
  const indexChildren = children.findIndex(item => item === action.childrenNodeId);
  [children[indexChildren], children[indexChildren - 1]] = [children[indexChildren - 1], children[indexChildren]];

  if (indexChildren !== 0) {
    entityNodes[action.node.id] = {
      ...entityNodes[action.node.id],
      children: [
        ...children
      ]
    }
  }

  return entityNodes;
};
