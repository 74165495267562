import isEmpty from 'lodash/isEmpty';
import { getFileNameFromUrl } from '../getFileNameFromUrl';

export const getImagesFromNodes = (data) => {
  let collection = [];
  !isEmpty(data) && Object.entries(data).forEach(([id, item]) => {
    if (item.type === 'image' || item.type === 'social') {
      const isExternalImage = item.properties?.imageSource === 'externalUrl';
      !isExternalImage && collection.push({
        name: getFileNameFromUrl(item.attributes?.src),
        url: item.attributes?.src,
      })
    }
    if (item.type === 'section' || item.type === 'module' || item.type === 'hero') {
      const isExternalImage = item.properties?.imageSource === 'externalUrl';
      if (item.attributes['background-url']) {
        !isExternalImage && collection.push({
          name: getFileNameFromUrl(item.attributes['background-url']),
          url: item.attributes['background-url'],
        })
      }
    }
  })
  return collection;
}
