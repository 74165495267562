import React from 'react';
import { Box, Flex, Grid, Image, MenuItem } from '@chakra-ui/react';
import { SnippetGrid } from '../../../components/layout';

export const ImageItem = ({ item, ...props }) => {
  const { name, imageUrl } = item;
  return (
    <Box
      role="group"
      width="100%"
      cursor="pointer"
      transition="0.2s"
      borderWidth={1}
      tabIndex={0}
      borderRadius="6px"
      backgroundColor="white"
      _hover={{
        borderColor: 'gray.300'
      }}
      _focus={{
        outline: 'none'
      }}
      _active={{
        backgroundColor: 'gray.50',
        boxShadow: '0 0 0 3px rgba(66,153,225,0.6)',
        borderRadius: '4px',
        borderColor: 'transparent'
      }}
      {...props}
    >
      <Box
        width="100%"
        height="80px"
        borderTopLeftRadius="5px"
        borderTopRightRadius="5px"
        backgroundColor="gray.100"
        display="flex"
        alignItems="flex-end"
        justifyContent="center"
        position="relative"
      >
        <Flex
          className="fixed-border-safari"
          borderTopLeftRadius={4}
          borderTopRightRadius={4}
          padding="6px 6px 0 6px"
          backgroundColor="color.50"
          overflow="hidden"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Image
            objectFit="cover"
            border="none"
            fallbackSrc="empty.jpg"
            maxWidth="110px"
            maxHeight="40px"
            height="auto"
            src={imageUrl ? imageUrl : ''}
          />
        </Flex>
      </Box>
    </Box>
  );
};
