import { Icon } from '@chakra-ui/react';

export const Normal = (props) => {
  return (
    <Icon viewBox="0 0 8 8" {...props}>
      <g fill="currentColor">
        <polygon fillRule="nonzero" points="1.94433594 8 1.94433594 1.98974609 2.03027344 1.98974609 6.23583984 8 7.17578125 8 7.17578125 0.249511719 6.21972656 0.249511719 6.21972656 6.28125 6.13378906 6.28125 1.92822266 0.249511719 0.98828125 0.249511719 0.98828125 8"/>
      </g>
    </Icon>
  );
};
