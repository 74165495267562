import React, { useContext } from 'react';
import { ModulesPanel } from '../../../components/layout';
import { templates } from './templates';
import { Box, Image, Stack, Tooltip } from '@chakra-ui/react';
import { Scrollbars } from 'react-custom-scrollbars';
import { ProjectContext } from '../../../components/context/projectContext';
import { Heading } from '../../../components/customizer/ui/heading';
import { useDispatch, useSelector } from 'react-redux';
import { addNode } from '../../../store/project.reducer';

export const ModulesMemo = () => {
  const dispatch = useDispatch();
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const projectNodesType = useSelector(state => state.project.present.entityNodesType);
  const nodeBody = projectNodes[projectNodesType?.body?.id];
  const { isReadOnly } = useContext(ProjectContext);

  const handleClick = (type) => {
    dispatch(addNode(nodeBody, type));
  }

  return (
    <ModulesPanel>
      <Scrollbars style={{ height: 'calc(100vh - 40px)' }}>
        <Box>
          {!isReadOnly && templates.map(category => (
            <Box key={category.id} pt={4} pb={4} pl={4} pr={4} borderTopWidth={1}>
              <Heading>{category.title}</Heading>
              <Stack spacing="20px" mt={0}>
                {category.children.map(item => (
                  <Box
                    key={item.id}
                    role="group"
                    cursor="pointer"
                    onClick={() => handleClick(item.type)}>
                    <Tooltip label={item.title} aria-label="A tooltip" fontSize="0.7rem">
                      <Box
                        borderWidth={1}
                        borderColor="#EDF2F7"
                        borderRadius={6}
                        mb={2}
                        overflow="hidden"
                        _groupHover={{
                          borderColor: 'gray.300'
                        }}
                      >
                        <Image src={item.src}/>
                      </Box>
                    </Tooltip>
                  </Box>
                ))}
              </Stack>
            </Box>
          ))}

        </Box>
      </Scrollbars>
    </ModulesPanel>
  );
};

export const Modules = React.memo(ModulesMemo);
