import { Icon } from '@chakra-ui/react';

export const Inner = (props) => {
  return (
    <Icon viewBox="0 0 12 12" {...props}>
      <g fill="currentColor">
        <path d="M2,0 L2,1 L10,1 L10,0 L12,0 L12,2 L11,2 L11,10 L12,10 L12,12 L10,12 L10,11 L2,11 L2,12 L0,12 L0,10 L1,10 L1,2 L0,2 L0,0 L2,0 Z M10,2 L2,2 L2,10 L10,10 L10,2 Z"/>
      </g>
    </Icon>
  );
};
