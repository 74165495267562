import { getAttributesFromNode } from '../../../utils/dataObj/getAttributesFromNode';

export const imageHtml = (data, parentNode, node, preview) => {
  const { attributes } = node;
  if (attributes.src) {
    return (`
      <mj-image${getAttributesFromNode(node, parentNode, preview)}></mj-image>
  `)
  } else {
    return (`
      <mj-image height="40px" width="40px"></mj-image>
  `)
  }
};
