import React, { useEffect, useState } from 'react';
import { Box, Image, MenuItem } from '@chakra-ui/react';
import { SnippetGrid } from '../../components/layout';
import { deleteProject } from '../../store/projectList.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { getAccess } from '../../utils/getAccess';
import empty from '../../images/empty-image.svg';

export const GridItem = ({ item, ...props }) => {
  const { name, imageUrl } = item;
  const dispatch = useDispatch();
  const loading = useSelector(state => state.projectList.loadingDelete);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const user = useSelector(state => state.authentication.user);

  const handleDeleteClick = (id) => {
    setIsLoadingDelete(true)
    dispatch(deleteProject(id))
  }

  useEffect(() => {
    if (!loading) {
      setIsLoadingDelete(false)
    }
  }, [loading])

  return (
    <SnippetGrid
      isLoading={isLoadingDelete}
      preview={
        <Box
          className="fixed-border-safari"
          borderTopLeftRadius={4}
          borderTopRightRadius={4}
          backgroundColor="gray.50"
          height="120px"
          overflow="hidden"
        >
          <Image
            objectFit="cover"
            border="none"
            fallbackSrc="empty.jpg"
            src={imageUrl ? imageUrl : ''}
          />
        </Box>
      }
      menuItems={
        getAccess(user.authorities, ['ROLE_ADMIN']) && (
          <MenuItem color="red.500" onClick={() => handleDeleteClick(item.id)}>Удалить</MenuItem>
        )
      }
      {...props}
    >
      {name}
    </SnippetGrid>
  );
};
