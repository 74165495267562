import React, { useEffect, useRef, useState } from 'react';
import './style.scss';

export const TextArea = ({ value, onChange, onMouseUp, isEditing, style }) => {

  const textareaRef = useRef(null);
  const [currentValue, setCurrentValue ] = useState('');

  const handleTextArea = () => {
    onChange(currentValue);
  };

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  useEffect(() => {
    textareaRef.current.style.height = '0px';
    const scrollHeight = textareaRef.current.scrollHeight;
    textareaRef.current.style.height = `${scrollHeight}px`;
  }, [currentValue, style]);

  useEffect(() => {
    if (isEditing) {
      textareaRef.current.focus();
    }
  }, [isEditing]);

  return (
    <textarea
      rows="10"
      cols="20"
      ref={textareaRef}
      className="text-area"
      style={{
        ...style,
        direction: 'ltr',
        color: '#000000',
        opacity: isEditing ? '1' : '0',
        zIndex: isEditing ? '4' : '0',
        position: isEditing ? 'absolute' : 'absolute',
        pointerEvents: isEditing ? 'auto' : 'none'
      }}
      value={currentValue}
      onChange={(e) => setCurrentValue(e.target.value)}
      onBlur={() => handleTextArea()}
      onMouseUp={onMouseUp}
    />
  );
};
