import React from 'react';
import { MenuList as ChakraMenuList } from '@chakra-ui/react';

export const MenuList = React.forwardRef(( { children, ...props }) => {
  return (
    <ChakraMenuList
      paddingBottom="0.4rem"
      paddingTop="0.4rem"
      fontSize="0.9rem"
      boxShadow="rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.1) 0px 9px 24px"
      borderWidth={0}
      {...props}
    >
      {children}
    </ChakraMenuList>
  );
});
