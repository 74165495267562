import React from 'react';
import { Checkbox } from '../ui/checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { updateNode } from '../../../store/project.reducer';

const DirectionControlMemo = ({ nodeId, ...props }) => {
  const dispatch = useDispatch();
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];

  const handleControl = (value) => {
    const newNode = {
      ...node,
      attributes: {
        ...node.attributes,
        direction: value ? 'rtl' : ''
      }
    };
    dispatch(updateNode(newNode));
  }

  return (
    <Checkbox
      name="direction"
      label="Инвертировать"
      onChange={handleControl}
      value={node?.attributes?.direction === 'rtl'}
      {...props}
    />
  );
};

export const DirectionControl = React.memo(DirectionControlMemo);
