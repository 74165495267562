import React from 'react';
import { InputColor } from '../ui/inputColor';
import { useDispatch, useSelector } from 'react-redux';
import { updateNode } from '../../../store/project.reducer';

const ColorControlMemo = ({ nodeId, attribute, isClear, ...props }) => {
  const dispatch = useDispatch();
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  const { attributes } = node;
  const handleControl = (value) => {
    const newNode = {
      ...node,
      attributes: {
        ...node.attributes,
        [attribute]: value
      }
    };
    dispatch(updateNode(newNode));
  };

  return (
    <InputColor
      isClear={isClear}
      value={attributes[attribute]}
      placeholder="Нет"
      onBlur={handleControl}
      {...props}
    />
  );
};

export const ColorControl = React.memo(ColorControlMemo);
