import React from 'react';
import { Box, Grid } from '@chakra-ui/react';

export const CategoryItem = ({ item, ...props }) => {
  const { name } = item;
  return (
    <Grid
      cursor="pointer"
      templateColumns="62px 1fr"
      gap="18px"
      borderTopLeftRadius={4}
      borderTopRightRadius={4}
      padding="6px"
      //backgroundColor="#ffffff"
      width="100%"
      height="60px"
      overflow="hidden"
      borderWidth={1}
      borderColor="transparent"
      alignItems="center"
      _hover={{
        backgroundColor: 'gray.50',
        borderColor: 'gray.100'
      }}
      {...props}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box>
          <Box
            width="20px"
            height="2px"
            borderTopLeftRadius="6px"
            borderTopRightRadius="6px"
            bg="blue.300"
          />
          <Box
            width="40px"
            height="3px"
            borderTopRightRadius="6px"
            bg="blue.300"
          />
        </Box>
        <Box
          width="50px"
          height="34px"
          borderRadius="4px"
          bg="blue.200"
        />
      </Box>
      <Box>
        {name}
      </Box>
    </Grid>
  );
};
