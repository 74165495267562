import { v4 as uuidv4 } from 'uuid';

export const imagePreset = () => {
  return {
    id: uuidv4(),
    type: 'image',
    attributes: {
      'padding': '0',
      'align': 'center',
      'href': ''
    },
    properties: {
      imageFileName: '',
      imageHeight: 0,
      imageWidth: 0,
    },
    children: []
  }
};
