import React from 'react';
import { StackControl } from '../stackControl';
import { PaddingTopControl } from '../controls/paddingTop.control';
import { PaddingRightControl } from '../controls/paddingRight.control';
import { PaddingBottomControl } from '../controls/paddingBottom.control';
import { PaddingLeftControl } from '../controls/paddingLeft.control';
import { GridControl } from '../gridControl';
import { WidthControl } from '../controls/width.control';
import { ColorControl } from '../controls/color.control';
import { useSelector } from 'react-redux';

const DividerModuleMemo = ({ nodeId }) => {
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  return (
    <StackControl>
      <GridControl label="Отступы">
        <PaddingLeftControl nodeId={node.id} placeholder="25px"/>
        <PaddingRightControl nodeId={node.id} placeholder="25px"/>
        <PaddingTopControl nodeId={node.id} placeholder="10px"/>
        <PaddingBottomControl nodeId={node.id} placeholder="10px"/>
      </GridControl>
      <GridControl label="Линия">
        <WidthControl nodeId={node.id} attribute="border-width" placeholder="4px"/>
        <ColorControl nodeId={node.id} attribute="border-color" placeholder="#000000"/>
      </GridControl>
      <GridControl label="Ширина">
        <WidthControl nodeId={node.id} placeholder="100%"/>
      </GridControl>
      <GridControl label="Фон">
        <ColorControl nodeId={node.id} attribute="container-background-color"/>
      </GridControl>
    </StackControl>
  );
};

export const DividerModule = React.memo(DividerModuleMemo);