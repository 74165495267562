import React, { useEffect, useState } from 'react';
import { Input as ChakraInput, InputGroup, InputLeftElement } from '@chakra-ui/react';

const parseValue = (valueNumber, valueString, units, change = []) => {
  if (valueNumber === '' && valueString === '') {
    return '';
  }
  const [typeChange, valueChange] = change;
  const resultNumber = valueNumber ? valueNumber : 0;
  const resultString = units.includes(valueString) ? valueString : 'px';
  if (change && (typeChange === 'increment')) {
    return `${resultNumber + valueChange}${resultString}`;
  }
  if (change && (typeChange === 'decrement')) {
    const decrementResult = resultNumber - valueChange;
    return `${decrementResult < 0 ? 0 : decrementResult}${resultString}`;
  }
  return `${resultNumber}${resultString}`;
}

export const InputSize = React.forwardRef((allProps, ref) => {
  const {
    leftElement,
    pointerEvents = 'none',
    value = '',
    units = ['px', '%'],
    onBlur,
    ...props
  } = allProps;

  const [valueInput, setValueInput] = useState(value);
  const valueNumberInput = valueInput && parseFloat(valueInput.match(/\d+.\d+|\d+/));
  const valueStringInput = valueInput && valueInput.replace(/((?!%)[^(a-z])/g, '');

  useEffect(() => {
    setValueInput(value !== undefined ? value : '');
  }, [value]);

  const handleInputBlur = () => {
    if (value !== valueInput) {
      const result = parseValue(valueNumberInput, valueStringInput, units);
      setValueInput(result)
      onBlur && onBlur(result);
    }
  };

  const handleKeyDown = (e) => {
    switch (e.which) {
      case 27: // Escape
      {
        if (e.altKey) {
          return;
        }
        onBlur(value);
        break;
      }
      case 38: // Up Arrow
      {
        if (e.shiftKey) {
          onBlur && onBlur(parseValue(valueNumberInput === '' ? 0 : valueNumberInput, valueStringInput, units, ['increment', 10]));
          break;
        }
        onBlur(parseValue(valueNumberInput === '' ? 0 : valueNumberInput, valueStringInput, units,['increment', 1]));
        break;
      }
      // case 32: // Space
      case 13: // Enter Key
      {
        if (e.altKey) {
          return;
        }
        onBlur && onBlur(parseValue(valueNumberInput, valueStringInput, units));
        break;
      }
      //case 9:
      case 40: // Down Arrow
      {
        if (e.shiftKey) {
          onBlur && onBlur(parseValue(valueNumberInput === '' ? 0 : valueNumberInput, valueStringInput, units,['decrement', 10]));
          break;
        }
        onBlur(parseValue(valueNumberInput === '' ? 0 : valueNumberInput, valueStringInput, units,['decrement', 1]));
        break;
      }
      //case 16: // Shift
      default:
        return;
    }
    e.preventDefault();
  };

  return (
    <InputGroup size="xs">
      <InputLeftElement
        pointerEvents={pointerEvents}
        children={leftElement}
      />
      <ChakraInput
        ref={ref}
        borderColor="transparent"
        value={valueInput}
        onChange={e => setValueInput(`${e.target.value}`)}
        onBlur={handleInputBlur}
        onKeyDown={handleKeyDown}
        onClick={e => e.stopPropagation()}
        {...props}
      />
    </InputGroup>
  )
});
