import React, { useContext, useEffect } from 'react';
import { ConstructorContainer, ContentWrapper, ToolBar } from '../../../components/layout';
import { Box, Flex, MenuItem, Text, Tooltip, Icon, useClipboard, useToast } from '@chakra-ui/react';
import { ProjectContext } from '../../../components/context/projectContext';
import { Template } from '../../../components/elements/template';
import { Customizer } from './customizer';
import { useDispatch, useSelector } from 'react-redux';
import { Modules } from './modules';
import { useHistory } from 'react-router';
import { ToolbarButton } from '../../../components/toolbar/toolbarButton';
import { ArrowBackIcon, LockIcon } from '@chakra-ui/icons';
import { GrUndo, GrRedo } from 'react-icons/gr';
import { FiLink } from 'react-icons/fi';
import { Loader } from '../../../components/loader/loader';
import isEmpty from 'lodash/isEmpty';
import { Editable } from '../../../components/editable';
import { getAttribute } from '../../../utils/getAttribute';
import { ProjectMenu } from '../../../components/toolbar/projectMenu';
import { Private } from '../../../components/private';
import { useRouteMatch } from 'react-router-dom';
import { deleteProject, duplicateProject, updateProject } from '../../../store/project.reducer';
import { ActionCreators } from 'redux-undo';
import { GlobalHotKeys } from 'react-hotkeys';
import { reset, resetHover } from '../../../store/selected.reducer';

export const Constructor = ({ source }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const toast = useToast();
  const {
    isReadOnly,
    isProjectDisabled,
    goBack,
  } = useContext(ProjectContext);
  const user = useSelector(state => state.authentication.user);
  const project = useSelector(state => state.project.present.entity);
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const availableEntity = useSelector(state => state.project.present.availableEntity);
  const loading = useSelector(state => state.project.present.loading);
  const hoverNodeId = useSelector(state => state.selected.hover);
  const projectNodesType = useSelector(state => state.project.present.entityNodesType);
  const future = useSelector(state => state.project.future);
  const past = useSelector(state => state.project.past);
  const nodeBody = projectNodes[projectNodesType?.body?.id];
  const bodyBackgroundColor = nodeBody && getAttribute(nodeBody.attributes, 'background-color', '#F7FAFC');
  const bodyWidth = nodeBody && getAttribute(nodeBody.attributes, 'width', '600px')

  const { hasCopied, onCopy } = useClipboard(`https://omail.su/#/i/share/${project.id}`)

  useEffect(() => {
    if (hasCopied) {
      toast({
        title: 'Готово',
        description: 'Ссылка скопирована в буфер обмена',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
    }
  }, [hasCopied]);

  const handleDuplicateProject = (sources = 'USER') => {
    const promise = dispatch(duplicateProject({
      sources,
      user,
      userId: user.id
    }));
    promise.then(result => {
      if (sources === 'USER') {
        history.push(`/projects/user/${result.value.data.id}`);
      }
      if (sources === 'SYSTEM') {
        history.push(`/templates/system/${result.value.data.id}`);
      }
    });
  }

  const handleConstructorClick = (e) => {
    e.stopPropagation()
    dispatch(reset());
  }

  const handleUndo = () => {
    dispatch(reset());
    dispatch(ActionCreators.undo());
  }

  const handleRedo = () => {
    dispatch(reset());
    dispatch(ActionCreators.redo())
  }

  const handleProjectName = (e) => {
    if (project.name !== e ) {
      dispatch(updateProject({ name: e }))
    }
  }

  const handleMouseLeave = (e) => {
    e.stopPropagation();
    if (hoverNodeId !== '') {
      dispatch(resetHover())
    }
  }

  const handleDeleteProject = (id) => {
    const promise = dispatch(deleteProject(id));
    promise.then(result => {
      goBack();
    })
  }

  const keyMap = {
    SNAP_LEFT: "command+left",
    UNDO: 'command+z',
    REDO: 'command+shift+z',
    DELETE: ['del', 'backspace']
  };

  const handlers = {
    UNDO: handleUndo,
    REDO: handleRedo
  };

  return (
    <GlobalHotKeys handlers={handlers} keyMap={keyMap}>
      <ToolBar
        center={
          !isEmpty(project) && !isProjectDisabled && (
            <Flex mr="-24px" alignItems="center">
              {isReadOnly && (
                <Tooltip label="Только для просмотра" aria-label="A tooltip" fontSize="0.7rem">
                  <LockIcon boxSize="0.8rem" color="gray.600" mr={3} />
                </Tooltip>
              )}
              {(!availableEntity || isReadOnly) ? (
                <Text>{project?.name}</Text>
              ) : (
                <Editable
                  isDisabled={!availableEntity || isReadOnly}
                  value={project?.name}
                  onSubmit={handleProjectName}
                />
              )}
              <ProjectMenu>
                <MenuItem color="black" onClick={() => handleDuplicateProject()}>Копировать в проекты</MenuItem>
                <Private
                  role={user.authorities}
                  hasAnyRole={['ROLE_ADMIN']}
                >
                  <MenuItem color="black" onClick={() => handleDuplicateProject('SYSTEM')}>Копировать в шаблоны</MenuItem>
                </Private>
                {!isReadOnly && (
                  <MenuItem color="red.500" onClick={() => handleDeleteProject(project.id)}>Удалить</MenuItem>
                )}
              </ProjectMenu>
            </Flex>
          )
        }
        left={
          source !== 'share' && (
            <ToolbarButton
              label={<ArrowBackIcon color="white"/>}
              onClick={() => goBack()}
            />
          )
        }
        right={
          !isProjectDisabled && (
            <>
              <Flex>
                {availableEntity && !isReadOnly && (
                  <>
                    <ToolbarButton
                      label={<GrUndo color="white"/>}
                      isDisabled={isEmpty(past)}
                      onClick={handleUndo}
                    />
                    <ToolbarButton
                      label={<GrRedo color="white"/>}
                      isDisabled={isEmpty(future)}
                      onClick={handleRedo}
                    />
                  </>
                )}
                <Flex alignItems="center">
                  <ToolbarButton
                    label={<Icon as={FiLink} boxSize="0.9rem" color="white"/>}
                    onClick={onCopy}
                  />
                  <ToolbarButton
                    type="solid"
                    onClick={() => history.push(`${match.url}/preview`)}
                    mr="14px"
                    ml="14px"
                  >
                    Экспорт
                  </ToolbarButton>
                </Flex>
              </Flex>
            </>
          )
        }
      />
      <ContentWrapper padding="0">
        <Loader message={!availableEntity && 'Проект нельзя открыть'} isLoading={loading}>
          {availableEntity && !isProjectDisabled && (
            <ConstructorContainer
              onMouseDown={handleConstructorClick}
            >
              {!isReadOnly && (
                <Modules/>
              )}
              <Box
                flex={1}
                overflowX="scroll"
                backgroundColor={bodyBackgroundColor}
              >
                <Box
                  width={bodyWidth}
                  ml="auto"
                  mr="auto"
                  mb="100px"
                  onMouseLeave={handleMouseLeave}
                >
                  <div id="email">
                    <Template/>
                  </div>
                </Box>
              </Box>
              {!isReadOnly && (
                <Customizer/>
              )}
            </ConstructorContainer>
          )}
        </Loader>
      </ContentWrapper>
    </GlobalHotKeys>
  );
};
