import React from 'react';
import { Box } from '@chakra-ui/react';
import { Element } from '../element';
import { SelectedNode } from '../../selectedNode';
import { getAttribute } from '../../../utils/getAttribute';
import { useSelector } from 'react-redux';

export const SocialCollection = ({ parentNodeId, nodeId }) => {
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  const { children, attributes } = node;
  return (
    <SelectedNode
      nodeId={node.id}
    >
      <Box
        textAlign={getAttribute(attributes, 'align', 'center')}
        backgroundColor={getAttribute(attributes, 'container-background-color')}
        paddingTop={getAttribute(attributes, 'padding-top', '10px')}
        paddingRight={getAttribute(attributes, 'padding-right', '25px')}
        paddingBottom={getAttribute(attributes, 'padding-bottom', '10px')}
        paddingLeft={getAttribute(attributes, 'padding-left', '25px')}
      >
        {children.map(item => (
          <Element
            key={item}
            parentNodeId={node.id}
            nodeId={item}
          />
        ))}
      </Box>
    </SelectedNode>
  )
};
