import React from 'react';
import { StackControl } from '../stackControl';
import { GridControl } from '../gridControl';
import { ColorControl } from '../controls/color.control';
import { WidthControl } from '../controls/width.control';
import { VerticalAlignControl } from '../controls/verticalAlign.control';
import { DirectionControl } from '../controls/direction.control';
import { useSelector } from 'react-redux';

export const GroupModuleMemo = ({ nodeId }) => {
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  const parentId = node?.path[node.path.length - 1].id;
  const parentNode = projectNodes[parentId];
  const widthColumn = node?.attributes.width ? node.attributes.width : `${100/parentNode?.children?.length}%`;

  return (
    <>
      <StackControl>
        <GridControl label="Размеры">
          <WidthControl nodeId={node.id} placeholder={`${widthColumn}`}/>
          <VerticalAlignControl nodeId={node.id}/>
        </GridControl>
        <GridControl label="Фоновый цвет">
          <ColorControl nodeId={node.id} attribute="background-color"/>
        </GridControl>
      </StackControl>
      <StackControl>
        <GridControl templateColumns="1fr">
          <DirectionControl nodeId={node.id}/>
        </GridControl>
      </StackControl>
    </>
  );
};

export const GroupModule = React.memo(GroupModuleMemo);
