import React from 'react';
import { InputSize } from '../ui/inputSize';
import { Height } from '../../icons';
import { updateNode } from '../../../store/project.reducer';
import { useDispatch, useSelector } from 'react-redux';

const HeightControlMemo = ({ nodeId, attribute = 'height', ...props }) => {
  const dispatch = useDispatch();
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  const { attributes } = node;

  const handleControl = (value) => {
    const newNode = {
      ...node,
      attributes: {
        ...node.attributes,
        [attribute]: value
      }
    };
    dispatch(updateNode(newNode));
  };

  return (
    <InputSize
      value={attributes[attribute]}
      leftElement={<Height boxSize="0.56rem" color="gray.300"/>}
      placeholder="Авто"
      onBlur={handleControl}
      {...props}
    />
  );
};

export const HeightControl = React.memo(HeightControlMemo);
