import React from 'react';
import { FormControl, FormLabel, Input, FormErrorMessage } from '@chakra-ui/react';

export const FormInput = React.forwardRef(( props, ref ) => {
  const { name, label, errors } = props;
  return (
    <FormControl isInvalid={errors && errors[name]}>
      {label && (
        <FormLabel
          htmlFor={name}
          fontSize="0.86rem"
          width="100%"
          mb={1}
          fontWeight="normal"
        >
          {label}
        </FormLabel>
      )}
      <Input
        name={name}
        ref={ref}
        {...props}
      />
     {/* <FormErrorMessage>
        {errors && errors[name] && errors[name].message}
      </FormErrorMessage>*/}
    </FormControl>
  );
});
