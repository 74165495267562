import React from 'react';
import { Element } from '../element';
import { SelectedNode } from '../../selectedNode';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

export const RowMemo = ({ parentNodeId, nodeId }) => {
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  const { children } = node;
  return (
    <SelectedNode
      nodeId={node.id}
      height={isEmpty(children) && '20px'}
    >
     {children.map(item => (
       <Element
         key={item}
         parentNodeId={node.id}
         nodeId={item}
       />
     ))}
    </SelectedNode>
  );
};

export const Row = React.memo(RowMemo);
