import React from 'react';
import { Flex } from '@chakra-ui/react';

export const ModalFooter = ({ children }) => {
  return (
    <Flex
      justifyContent="flex-end"
      paddingBottom="0.5rem"
      paddingTop="1.8rem"
    >
      {children}
    </Flex>
  );
};
