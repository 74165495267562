import React from 'react';
import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Button,
  HStack,
  Box,
  useToast
} from '@chakra-ui/react';
import { ImagePreview } from '../ui/imagePreview';
import { DropZone } from '../dropzone';
import isEmpty from 'lodash/isEmpty';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateNode, uploadImage } from '../../../store/project.reducer';
import { LinkIcon } from '@chakra-ui/icons';
import { Input } from '../ui/input';

const RadioItem = ({ isActive, children, ...props }) => {
  return (
    <Box
      padding="1px 5px"
      borderRadius={4}
      fontSize="0.68rem"
      backgroundColor={isActive ? 'gray.100' : 'transparent'}
      cursor="pointer"
      transition="color 0.2s"
      _hover={{
        color: 'blue.500'
      }}
      {...props}
    >
      {children}
    </Box>
  )
}

const ImageControlMemo = ({ nodeId, attribute }) => {
  const dispatch = useDispatch();
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  const toast = useToast();
  const history = useHistory();
  const match = useRouteMatch();
  const { attributes, properties } = node;
  const isExternalUrl = properties?.imageSource === 'externalUrl';

  const handleUpload = (acceptedFiles) => {
    if (!isEmpty(acceptedFiles)) {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const promise = dispatch(uploadImage({
          category: 'test',
          image: reader.result,
          name: file.path,
          file: file.path,
          link: node.id
        }));
        promise.catch(error => {
          toast({
            title: 'Ошибка',
            description: 'Слишком большое изображение',
            status: 'warning',
            duration: 9000,
            isClosable: true,
          })
        })
      };
    }
  }

  const handleUploadError = () => {
    toast({
      title: 'Ошибка',
      description: 'Слишком большое изображение',
      status: 'warning',
      duration: 9000,
      isClosable: true,
    })
  }

  const handleClear = () => {
    const newNode = {
      ...node,
      attributes: {
        ...node.attributes,
        [attribute]: ''
      },
      properties: {
        imageFileName: '',
        imageHeight: 0,
        imageWidth: 0
      }
    };
    dispatch(updateNode(newNode));
  };

  const handleRadio = (type) => {
    const newNode = {
      ...node,
      attributes: {
        ...node.attributes,
      },
      properties: {
        ...node.properties,
        imageSource: type
      }
    };
    dispatch(updateNode(newNode));
  };

  const handleExternalUrl = (value) => {
    const newNode = {
      ...node,
      attributes: {
        ...node.attributes,
        [attribute]: value
      },
      properties: {
        ...node.properties,
        imageFileName: '',
        imageHeight: 0,
        imageWidth: 0
      }
    };
    dispatch(updateNode(newNode));
  };

  const handleOpenImageManager = () => {
    history.push(`${match.url}/images/${node.id}`)
  }

  return (
    <>
      <HStack spacing="2px">
        <RadioItem
          isActive={!isExternalUrl}
          onClick={() => handleRadio('system')}
        >
          Файл
        </RadioItem>
        <RadioItem
          isActive={isExternalUrl}
          onClick={() => handleRadio('externalUrl')}
        >
          Ссылка
        </RadioItem>
      </HStack>
      {isExternalUrl ? (
        <Input
          placeholder="Нет ссылки"
          value={attributes[attribute]}
          leftElement={<LinkIcon color="gray.300" boxSize="0.8rem"/>}
          onBlur={handleExternalUrl}
        />
      ) : (
        <Popover
          placement="bottom"
          maxWidth="10rem"
        >
          <PopoverTrigger>
            <ImagePreview src={attributes[attribute]} label={node?.properties?.imageFileName}/>
          </PopoverTrigger>
          <PopoverContent
            ml="16px"
            mr="16px"
            borderRadius={3}
            boxShadow="rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.1) 0px 9px 24px"
            borderWidth={0}
            maxWidth="200px"
            _focus={{
              outline: 'none'
            }}
          >
            <PopoverBody>
              <DropZone
                uploadedImage={attributes[attribute]}
                onUpload={handleUpload}
                onError={handleUploadError}
              />
              <Button size="xs" width="100%" mt={1} mb={1} onClick={handleOpenImageManager}>Из галереи</Button>
              {attributes[attribute] && (
                <Button size="xs" width="100%" mt={1} onClick={handleClear}>Очистить</Button>
              )}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    </>
  );
};

export const ImageControl = React.memo(ImageControlMemo)
