import { FAILURE, REQUEST, SUCCESS } from './actions-type';
import axios from 'axios';

export const ACTION_TYPES = {
  FETCH_ENTITY: 'html/FETCH_ENTITY',
  FETCH_ENTITY_PREVIEW: 'html/FETCH_ENTITY_PREVIEW',
  RESET_ENTITY: 'html/RESET_ENTITY'
}

const initialState = {
  entity: {},
  entityPreview: {},
  loading: false,
  errorMessage: ''
};

export const htmlReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ENTITY_PREVIEW):
    case REQUEST(ACTION_TYPES.FETCH_ENTITY): {
      return {
        ...state,
        loading: true,
        errorMessage: ''
      }
    }
    case FAILURE(ACTION_TYPES.FETCH_ENTITY_PREVIEW):
    case FAILURE(ACTION_TYPES.FETCH_ENTITY): {
      return {
        ...state,
        loading: false,
        errorMessage: ''
      }
    }
    case SUCCESS(ACTION_TYPES.FETCH_ENTITY): {
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      }
    }
    case SUCCESS(ACTION_TYPES.FETCH_ENTITY_PREVIEW):{
      return {
        ...state,
        loading: false,
        entityPreview: action.payload.data
      }
    }
    case ACTION_TYPES.RESET_ENTITY: {
      return {
        ...state,
        entity: {},
        loading: false,
        errorMessage: ''
      }
    }
    default:
      return state;
  }
};

//const API_URL = `https://api.mjml.io/v1/render`;
const API_URL = `api/templates/html`;

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Authorization': `Basic ${btoa(`${'51e556cd-8679-4f81-ba11-5c52ad81dd5a'}:${'3738de6d-abde-4d6d-ba50-4832ab0bda70'}`)}`
};

export const getHtml = (html, htmlPreview) => async dispatch => {
  const requestUrl = `${API_URL}`;
  await dispatch ({
    type: ACTION_TYPES.FETCH_ENTITY,
    payload: axios({
      method: 'post',
      url: requestUrl,
      headers,
      data: {
        mjml: html
      }
    })
  });
  await dispatch ({
    type: ACTION_TYPES.FETCH_ENTITY_PREVIEW,
    payload: axios({
      method: 'post',
      url: requestUrl,
      headers,
      data: {
        mjml: htmlPreview
      }
    })
  });
};

export const resetHtml = () => {
  return {
    type: ACTION_TYPES.RESET_ENTITY
  };
};
