import React from 'react';
import { Element } from '../element';
import { SelectedNode } from '../../selectedNode';
import { useSelector } from 'react-redux';

export const RowCollectionMemo = ({ parentNodeId, nodeId }) => {
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  const { children } = node;

  return (
    <SelectedNode
      nodeId={node.id}
    >
      {children.map(item => (
        <Element
          key={item}
          parentNodeId={node.id}
          nodeId={item}
        />
      ))}
    </SelectedNode>
  )
};

export const RowCollection = React.memo(RowCollectionMemo);
