import { v4 as uuidv4 } from 'uuid';

export const sectionPreset = () => {
  return {
    id: uuidv4(),
    type: 'section',
    attributes: {
      'padding-left': '20px',
      'padding-right': '20px',
      'padding-top': '20px',
      'padding-bottom': '20px',
      'background-color': '#ffffff'
    },
    properties: {},
    children: [
      {
        id: uuidv4(),
        type: 'column',
        attributes: {
          'padding-top': '10px',
          'padding-bottom': '10px'
        },
        properties: {},
        children: []
      }
    ]
  }
};
