import React from 'react';
import { Box, Stack } from '@chakra-ui/react';
import { HeadingControl } from './headingControl';
export const StackControl = ({ label, children }) => {
  return (
    <Box borderTopWidth={1}>
      {label && (
        <HeadingControl>{label}</HeadingControl>
      )}
      <Box pt={4} pb={4} pl={4} pr={4}>
        <Stack spacing="16px">
          {children}
        </Stack>
      </Box>
    </Box>
  );
};
