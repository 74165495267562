import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Spinner, Text } from '@chakra-ui/react';
import { uploadImage } from '../../store/image.reducer';

export const DropZone = ({ fullSize, setFullSize, selectedCategory })  => {
    const dispatch = useDispatch();
    const loadingUploadImage = useSelector(state => state.image.loadingUploadImage);
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/jpeg, image/png',
        maxSize: 1048576,
        onDropAccepted: () => {
            setFullSize(false)
        },
        onDropRejected: () => {
            setFullSize(false)
        },
        onDrop: acceptedFiles => {
            acceptedFiles.forEach(file => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = () => {
                    dispatch(uploadImage({
                        category: selectedCategory,
                        image: reader.result,
                        name: file.path,
                        file: file.path,
                        link: ''
                    }))
                };
            })
        }
    });
    return (
        <section className="dropzone-input">
            <Box
                width="100%"
                height={fullSize ? '200px' : '40px'}
                borderWidth={1}
                marginBottom="30px"
                borderStyle="dashed"
                borderRadius={5}
                display="flex"
                alignItems="center"
                justifyContent="center"
                transition="0.2s"
                outline="none"
                cursor="pointer"
                borderColor={fullSize ? 'blue.300' : 'gray.200'}
                {...getRootProps({className: 'dropzone'})}
                _hover={{
                    borderColor: 'blue.300'
                }}
            >
                <input {...getInputProps()} />
                {loadingUploadImage && (
                    <Spinner
                        color="blue.500"
                        size="sm"
                        marginRight="10px"
                    />
                )}
                <Text
                    fontSize={fullSize ? '1rem' : '0.8rem'}
                    color={fullSize ? 'blue.300' : 'gray.400'}
                    transition="0.2s"
                >
                    Перетащите сюда несколько картинок или щелкните, чтобы выбрать
                </Text>
            </Box>
        </section>
    );
};
