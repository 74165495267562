import React, { useEffect } from 'react';
import { Grid } from '@chakra-ui/react';
import { ToolBar } from '../../components/toolbar';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectList, resetProjectList } from '../../store/projectList.reducer';
import { GridItem } from '../projects/gridItem';
import { ToolbarButton } from '../../components/toolbar/toolbarButton';
import { Loader } from '../../components/loader/loader';
import { useParams } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons';
import EmptyBox from '../../images/empty-box.svg';
import { ContentWrapper, EmptyMessageBox } from '../../components/layout';

export const UsersShow = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const list = useSelector(state => state.projectList.entities);
  const loading = useSelector(state => state.projectList.loading);

  useEffect(() => {
    dispatch(getProjectList({ userId: id }))
    return () => {
      dispatch(resetProjectList());
    }
  }, []);

  return (
    <>
      <ToolBar
        center="Проекты пользователя"
        left={
          <ToolbarButton
            label={<ArrowBackIcon color="white"/>}
            onClick={() => history.push('/users')}
          />
        }
      />
      <ContentWrapper>
        <Loader isLoading={loading} message={!list.length && (
          <EmptyMessageBox src={EmptyBox}>Пока ничего нет</EmptyMessageBox>
        )}>
          <Grid
            templateColumns={`repeat(auto-fill, minmax(200px, 1fr))`}
            gap={6}
          >
            {list && list.map(item => (
              <GridItem
                key={item.id}
                item={item}
                onClick={() => history.push(`/users/${id}/admin/${item.id}`)}
              />
            ))}
          </Grid>
        </Loader>
      </ContentWrapper>
    </>
  );
};
