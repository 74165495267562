import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Users } from './users';
import { UsersShow } from './usersShow';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Login } from '../login/login';
import { Project } from '../project';

const Routes = () => {
  const match = useRouteMatch();
  const userAuth = useSelector(state => state.authentication.user);
  const isAuthenticated = !isEmpty(userAuth);
  return (
    isAuthenticated ? (
    <Switch>
      <Route path={`${match.url}/`} exact>
        <Users/>
      </Route>
      <Route path={`${match.url}/:id`} exact>
        <UsersShow/>
      </Route>
      <Route path={`${match.url}/:userAdminId/:source/:id`}>
        <Project/>
      </Route>
    </Switch>
    ) : (
      <Login/>
    )
  );
};

export default Routes;
