import React from 'react';
import { Box, Grid, IconButton, Menu, MenuButton, Flex, Spinner } from '@chakra-ui/react';
import { ToolbarButton } from '../toolbar/toolbarButton';
import { Burger, MenuPoints } from '../icons';
import { MenuList } from '../menu';

export const SnippetGrid = ({ preview, menuItems, isLoading, children, ...props }) => {
  return (
    <Box
      role="group"
      width="100%"
      cursor="pointer"
      transition="0.2s"
      borderWidth={1}
      tabIndex={0}
      borderRadius="6px"
      backgroundColor="white"
      _hover={{
        borderColor: 'gray.300'
      }}
      _focus={{
        outline: 'none'
      }}
      _active={{
        backgroundColor: 'gray.50',
        boxShadow: '0 0 0 3px rgba(66,153,225,0.6)',
        borderRadius: '4px',
        borderColor: 'transparent'
      }}
      {...props}
    >
      <Box
        width="100%"
        height="120px"
        borderTopLeftRadius="5px"
        borderTopRightRadius="5px"
        backgroundColor="gray.100"
        display="flex"
        alignItems="flex-end"
        justifyContent="center"
        position="relative"
      >
        {preview}
      </Box>
      <Grid
        templateColumns="1fr 30px"
        height="60px"
        alignItems="center"
        padding="4px 10px"
        transition="0.3s"
        fontSize="0.84rem"
      >
        <Box
          transition="0.2s"
          wordBreak="break-all"
          lineHeight="18px"
          _groupHover={{
          color: 'blue.400'
        }}
        >
          {children}
        </Box>
        {isLoading ? (
          <Flex justifyContent="flex-end">
            <Spinner color="gray.200" size="sm"/>
          </Flex>
        ) : (
          menuItems && (
            <Flex justifyContent="flex-end" onClick={e => e.stopPropagation()}>
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Опции"
                  variant="ghost"
                  isRound
                  color="gray.300"
                  icon={<MenuPoints/>}
                  size="xs"
                  opacity="0"
                  _active={{
                    color: 'blue.500'
                  }}
                  _hover={{
                    color: 'blue.500'
                  }}
                  _focus={{
                    boxShadow: 'none'
                  }}
                  _groupHover={{
                    opacity: '1'
                  }}
                />
                <MenuList
                  marginLeft={3}
                >
                  {menuItems}
                </MenuList>
              </Menu>
            </Flex>
          )
        )}
      </Grid>
    </Box>
  );
};
