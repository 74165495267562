import { Icon } from '@chakra-ui/react';

export const Html = (props) => {
  return (
    <Icon viewBox="0 0 44 56" {...props}>
      <g>
        <path d="M30.985,0 L1.963,0 C1.155,0 0.5,0.655 0.5,1.926 L0.5,55 C0.5,55.345 1.155,56 1.963,56 L42.037,56 C42.845,56 43.5,55.345 43.5,55 L43.5,12.978 C43.5,12.282 43.407,12.058 43.243,11.893 L31.607,0.257 C31.442,0.093 31.218,0 30.985,0 Z" fill="#E9E9E0" fillRule="nonzero"/>
        <polygon fill="#D9D7CA" fillRule="nonzero" points="31.5 0.151 31.5 12 43.349 12"/>
        <path d="M42.037,56 L1.963,56 C1.155,56 0.5,55.345 0.5,54.537 L0.5,39 L43.5,39 L43.5,54.537 C43.5,55.345 42.845,56 42.037,56 Z" fill="#3182CE" fillRule="nonzero"/>
        <polygon fill="#FFFFFF" fillRule="nonzero" points="11.455 42.924 11.455 53 9.814 53 9.814 48.461 5.453 48.461 5.453 53 3.785 53 3.785 42.924 5.453 42.924 5.453 47.34 9.814 47.34 9.814 42.924 11.455 42.924"/>
        <polygon fill="#FFFFFF" fillRule="nonzero" points="21.107 42.924 21.107 44.045 18.1 44.045 18.1 53 16.446 53 16.446 44.045 13.438 44.045 13.438 42.924"/>
        <polygon fill="#FFFFFF" fillRule="nonzero" points="30.705 42.924 32.373 42.924 32.373 53 30.705 53 30.705 46.068 28.449 51.673 27 51.673 24.73 46.068 24.73 53 23.062 53 23.062 42.924 24.73 42.924 27.724 49.815"/>
        <polygon fill="#FFFFFF" fillRule="nonzero" points="36.57 42.924 36.57 51.756 41.205 51.756 41.205 53 34.902 53 34.902 42.924"/>
        <path d="M17.207,16.293 C16.816,15.902 16.184,15.902 15.793,16.293 L9.793,22.293 C9.402,22.684 9.402,23.316 9.793,23.707 L15.793,29.707 C15.988,29.902 16.244,30 16.5,30 C16.756,30 17.012,29.902 17.207,29.707 C17.598,29.316 17.598,28.684 17.207,28.293 L11.914,23 L17.207,17.707 C17.598,17.316 17.598,16.684 17.207,16.293 Z"  fill="#3182CE" fillRule="nonzero"/>
        <path d="M35.207,22.293 L29.207,16.293 C28.816,15.902 28.184,15.902 27.793,16.293 C27.402,16.684 27.402,17.316 27.793,17.707 L33.086,23 L27.793,28.293 C27.402,28.684 27.402,29.316 27.793,29.707 C27.988,29.902 28.244,30 28.5,30 C28.756,30 29.012,29.902 29.207,29.707 L35.207,23.707 C35.598,23.316 35.598,22.684 35.207,22.293 Z" fill="#3182CE" fillRule="nonzero"/>
      </g>
    </Icon>
  );
};
