import React from 'react';
import { LinkIcon } from '@chakra-ui/icons';
import { Input } from '../ui/input';
import { useDispatch, useSelector } from 'react-redux';
import { updateNode } from '../../../store/project.reducer';

const LinkControlMemo = ({ nodeId, attribute = 'href', property, ...props }) => {
  const dispatch = useDispatch();
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  const { attributes } = node;

  const handleControl = (value) => {
    const newNode = {
      ...node,
      attributes: {
        ...node.attributes,
        [attribute]: value
      }
    };
    dispatch(updateNode(newNode));
  };

  return (
    <Input
      placeholder="Нет ссылки"
      value={attributes[attribute]}
      leftElement={<LinkIcon color="gray.300" boxSize="0.8rem"/>}
      onBlur={handleControl}
      {...props}
    />
  );
};

export const LinkControl = React.memo(LinkControlMemo);