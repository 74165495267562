import React from 'react';
import { Box } from '@chakra-ui/react';

export const ContentWrapper = ({ children, ...props }) => {
  return (
    <Box
      padding="30px 30px"
      height="calc(100vh - 40px)"
      {...props}
    >
      {children}
    </Box>
  );
};
