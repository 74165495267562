import React from 'react';
import { Grid, Flex } from '@chakra-ui/react';

export const ConstructorContainerMemo = ({ children, ...props }) => {
  return (
    <Flex
      width="100%"
      height="calc(100vh - 40px)"
      position="relative"
      backgroundColor="gray.50"
      {...props}
    >
      {children}
    </Flex>
  );
};

export const ConstructorContainer = React.memo(ConstructorContainerMemo);
