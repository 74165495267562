import React from 'react';
import { Box, Image, MenuItem, Flex } from '@chakra-ui/react';
import { SnippetGrid } from '../../components/layout';
import { deleteImage } from '../../store/image.reducer';
import { useDispatch } from 'react-redux';
import { download } from '../../utils/download';

export const ImagesItem = ({ item, ...props }) => {
  const { name, imageUrl } = item;
  const dispatch = useDispatch();

  const handleSnippetClick = () => {
    download(item.imageUrl);
  }

  const handleDeleteClick = (id) => {
    dispatch(deleteImage(id))
  };

  return (
    <SnippetGrid
      preview={
        <Flex
          className="fixed-border-safari"
          borderTopLeftRadius={4}
          borderTopRightRadius={4}
          padding="6px 6px 0 6px"
          backgroundColor="color.50"
          overflow="hidden"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Image
            objectFit="cover"
            border="none"
            fallbackSrc="empty.jpg"
            maxWidth="110px"
            maxHeight="70px"
            height="auto"
            src={imageUrl ? imageUrl : ''}
          />
        </Flex>
      }
      menuItems={
        <MenuItem color="red.500" onClick={() => handleDeleteClick(item.id)}>Удалить</MenuItem>
      }
      {...props}
      onClick={handleSnippetClick}
    >
      {name}
    </SnippetGrid>
  );
};
