import React from 'react';
import { Checkbox } from '../ui/checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { updateNode } from '../../../store/project.reducer';

export const WrapperControlMemo = ({ nodeId, ...props }) => {
  const dispatch = useDispatch();
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];

  const handleControl = (value) => {
    const newNode = {
      ...node,
      properties: {
        ...node.properties,
        wrapper: value
      }
    };
    dispatch(updateNode(newNode));
  }

  return (
    <Checkbox
      name="wrapper"
      label="Использовать рамку"
      onChange={handleControl}
      value={node?.properties?.wrapper}
      {...props}
    />
  );
};

export const WrapperControl = React.memo(WrapperControlMemo);
