import React, { useRef } from 'react';
import {
  Box,
  Button,
  Flex,
  Input,
  MenuButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { Modal, ModalFooter } from '../../components/modal';
import { useForm } from 'react-hook-form';
import { ToolbarButton } from '../../components/toolbar/toolbarButton';
import { AddIcon } from '@chakra-ui/icons';
import { FormInput } from '../../components/formInput';
import { useDispatch, useSelector } from 'react-redux';
import { createImagesCategories } from '../../store/image.reducer';

export const CategoryAdd = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.image.loading);
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      name: ''
    }
  });

  const onSubmit = async (values) => {
    const dataRequest = {
      name: values.name,
      category: values.name
    };
    await dispatch(createImagesCategories(dataRequest));
    onClose();
  };

  return (
    <>
      <ToolbarButton
        label={<AddIcon color="white" boxSize="0.9rem"/>}
        onClick={() => onOpen()}
      />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        header="Добавление каталога"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing="16px">
            <FormInput
              autoFocus
              name="name"
              placeholder="Введите название"
              errors={errors}
              ref={register({ required: true })}
            />
          </VStack>
          <ModalFooter>
            <Button colorScheme="blue" width="100%" size="md" type="submit" isLoading={loading}>Добавить</Button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};
