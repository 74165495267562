import { FAILURE, REQUEST, SUCCESS } from './actions-type';
import axios from 'axios';

export const ACTION_TYPES = {
    FETCH_IMAGES_CATEGORIES: 'image/FETCH_IMAGES_CATEGORIES',
    RESET_IMAGES_CATEGORIES: 'image/RESET_IMAGES_CATEGORIES',
    CREATE_IMAGES_CATEGORIES: 'image/CREATE_IMAGES_CATEGORIES',
    DELETE_IMAGES_CATEGORIES: 'image/DELETE_IMAGES_CATEGORIES',
    FETCH_IMAGES: 'image/FETCH_IMAGES',
    RESET_IMAGES: 'image/RESET_IMAGES',
    UPLOAD_IMAGE: 'image/UPLOAD_IMAGE',
    DELETE_IMAGE: 'image/DELETE_IMAGE',
};

const initialState = {
    images: [],
    categories: [],
    selectedCategory: [],
    loading: false,
    loadingUploadImage: false,
    errorMessage: ''
};

export const imageReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_IMAGES_CATEGORIES):
        case REQUEST(ACTION_TYPES.CREATE_IMAGES_CATEGORIES):
        case REQUEST(ACTION_TYPES.FETCH_IMAGES):{
            return {
                ...state,
                loading: true
            }
        }
        case REQUEST(ACTION_TYPES.UPLOAD_IMAGE):{
            return {
                ...state,
                loadingUploadImage: true
            }
        }
        case FAILURE(ACTION_TYPES.FETCH_IMAGES_CATEGORIES):
        case FAILURE(ACTION_TYPES.CREATE_IMAGES_CATEGORIES):
        case FAILURE(ACTION_TYPES.FETCH_IMAGES):{
            return {
                ...state,
                loading: false
            }
        }
        case FAILURE(ACTION_TYPES.UPLOAD_IMAGE):{
            return {
                ...state,
                loadingUploadImage: false
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_IMAGES_CATEGORIES): {
            return {
                ...state,
                loading: false,
                categories: action.payload.data.items
            }
        }
        case SUCCESS(ACTION_TYPES.CREATE_IMAGES_CATEGORIES): {
            const categories = state.categories.concat([action.payload.data])
            return {
                ...state,
                loading: false,
                categories
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_IMAGES): {
            return {
                ...state,
                loading: false,
                images: action.payload.data.items,
                selectedCategory: action.payload.data.selectedCategory[0]
            }
        }
        case SUCCESS(ACTION_TYPES.UPLOAD_IMAGE): {
            const images = state.images.concat([action.payload.data]);
            return {
                ...state,
                loadingUploadImage: false,
                images
            }
        }
        case SUCCESS(ACTION_TYPES.DELETE_IMAGES_CATEGORIES): {
            const categories = state.categories.filter(item => item.id !== action.payload.data.id);
            return {
                ...state,
                categories
            }
        }
        case SUCCESS(ACTION_TYPES.DELETE_IMAGE): {
            const images = state.images.filter(item => item.id !== action.payload.data.id);
            return {
                ...state,
                images
            }
        }
        case ACTION_TYPES.RESET_IMAGES_CATEGORIES: {
            return {
                ...state,
                categories: []
            }
        }
        case ACTION_TYPES.RESET_IMAGES: {
            return {
                ...state,
                images: [],
                selectedCategory: []
            }
        }
        default: return state;
    }
};

const API_URL = '/api/images';

export const getImagesCategories = () => {
    const requestUrl = `${API_URL}/categories`;
    return {
        type: ACTION_TYPES.FETCH_IMAGES_CATEGORIES,
        payload: axios.get(requestUrl, {
            headers: {

            },
            params: {

            }
        })
    };
};

export const createImagesCategories = (dataRequest) => {
    const requestUrl = `${API_URL}/categories`;
    return {
        type: ACTION_TYPES.CREATE_IMAGES_CATEGORIES,
        payload: axios.post(requestUrl, {
            ...dataRequest
        })
    };
};

export const deleteImagesCategories = (id) => {
    const requestUrl = `${API_URL}/categories/${id}`;
    return {
        type: ACTION_TYPES.DELETE_IMAGES_CATEGORIES,
        payload: axios.delete(requestUrl)
    };
};

export const getImages = (dataRequest) => {
    const requestUrl = `${API_URL}/search`;
    return {
        type: ACTION_TYPES.FETCH_IMAGES,
        payload: axios.post(requestUrl, {
            ...dataRequest
        })
    };
};

export const uploadImage = (dataRequest) => {
    const requestUrl = `${API_URL}`;
    const { category, image, name, file, link } = dataRequest;
    return {
        type: ACTION_TYPES.UPLOAD_IMAGE,
        payload: axios.post(requestUrl, {
            category, image, name, file, link
        })
    };
};

export const deleteImage = (id) => {
    const requestUrl = `${API_URL}/${id}`;
    return {
        type: ACTION_TYPES.DELETE_IMAGE,
        payload: axios.delete(requestUrl)
    };
};

export const resetImagesCategories = () => {
    return {
        type: ACTION_TYPES.RESET_IMAGES_CATEGORIES
    };
};

export const resetImages = () => {
    return {
        type: ACTION_TYPES.RESET_IMAGES
    };
};
