import React from 'react';
import { StackControl } from '../stackControl';
import { GridControl } from '../gridControl';
import { useSelector } from 'react-redux';
import { LinkParentControl } from '../controls/linkParent.control';

export const RowModuleMemo = ({ nodeId }) => {
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  return (
    <>
      <StackControl>
        <GridControl templateColumns="1fr" label="Ссылка">
          <LinkParentControl nodeId={node.id} />
        </GridControl>
      </StackControl>
    </>
  );
};

export const RowModule = React.memo(RowModuleMemo);
