import React, { useContext } from 'react';
import { Flex, Badge, Box } from '@chakra-ui/react';
import { ProjectContext } from '../../context/projectContext';
import { ELEMENTS_NAME, nodeType } from '../../../models';
import { Angle } from '../../icons';
import { AddNodeButton } from './addNodeButton';
import { useDispatch, useSelector } from 'react-redux';
import { reset, resetHover, setLevel, updateHover } from '../../../store/selected.reducer';

const getColor = (type) => {
  const TYPE_COLOR = {
    body: 'telegram',
    module: 'orange',
    hero: 'purple',
    section: 'purple',
    group: 'purple',
    column: 'green',
    rowCollection: 'pink',
    row: 'pink',
    element: 'blackAlpha'
  }
  if (TYPE_COLOR[type]) {
    return TYPE_COLOR[type]
  } else {
    return 'purple'
  }
}

const allowedTypesForAdd = [
  nodeType.body,
  nodeType.module,
  nodeType.section,
  nodeType.group,
  nodeType.column,
  nodeType.rowCollection,
  nodeType.row,
  nodeType.hero,
  nodeType.socialCollection
];

export const SelectedNodeButtonMemo = ({ id, type, index, length }) => {
  const dispatch = useDispatch();
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const pathSelected = useSelector(state => state.selected.path);
  const hoverNodeId = useSelector(state => state.selected.hover);
  const { isReadOnly } = useContext(ProjectContext);
  const levelSelected = pathSelected.length === 0 ? undefined : pathSelected.length - 1;
  const activeId = levelSelected !== undefined && pathSelected[levelSelected].id;
  const node = projectNodes[id] ? projectNodes[id] : {};

  const handleCLick = () => {
    if (type === 'body') {
      dispatch(reset());
    } else {
      dispatch(setLevel());
    }
  }

  const handleMouseMove = (e) => {
    e.stopPropagation();
    if (hoverNodeId !== node.id) {
      dispatch(updateHover(node.id, node.type, node.path))
    }
  }

  const handleMouseLeave = (e) => {
    e.stopPropagation();
    dispatch(resetHover())
  }

  return (
    <Flex
      role="group"
      position="relative"
      height="37px"
      paddingLeft="1rem"
      paddingRight="8px"
      alignItems="center"
      justifyContent="space-between"
      backgroundColor={hoverNodeId === node.id && 'gray.100'}
      transition="all 250ms"
      cursor="pointer"
      _hover={{
        backgroundColor: 'gray.100'
      }}
      _active={{
        backgroundColor: 'gray.200'
      }}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onClick={handleCLick}
      pl={`${(index + 2) * 13 }px`}
    >
      <Flex alignItems="center">
        <Flex alignItems="center" justifyContent="flex-start" width="1rem" height="1.2rem">
          <Angle color="gray.300" boxSize="0.4rem" mb="4px" />
        </Flex>
        <Badge flex="none" colorScheme={getColor(type)} fontSize="0.58rem">{ELEMENTS_NAME[node.type ? node.type : type]}</Badge>
        {id === activeId && (
          <Box ml={2} w="6px" h="6px" backgroundColor="blue.400" borderRadius="20px"/>
        )}
      </Flex>
      {(allowedTypesForAdd.includes(node.type) && !isReadOnly) && (
        <AddNodeButton
          nodeId={node.id}
          opacity={index === length - 1 ? 1 : 0}
          _groupHover={{
            opacity: 1
          }}
        />
      )}
    </Flex>
  );
};

export const SelectedNodeButton = React.memo(SelectedNodeButtonMemo);
