import React from 'react';
import { InputSize } from '../ui/inputSize';
import { ArrowUpIcon } from '@chakra-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { updateNode } from '../../../store/project.reducer';

const PaddingTopControlMemo = ({ nodeId, attribute = 'padding-top', ...props }) => {
  const dispatch = useDispatch();
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  const { attributes } = node;

  const handleControl = (value) => {
    const newNode = {
      ...node,
      attributes: {
        ...node.attributes,
        [attribute]: value
      }
    };
    dispatch(updateNode(newNode));
  };

  return (
    <InputSize
      value={attributes[attribute]}
      leftElement={<ArrowUpIcon color="gray.300"/>}
      placeholder="Авто"
      onBlur={handleControl}
      {...props}
    />
  );
};

export const PaddingTopControl = React.memo(PaddingTopControlMemo);
