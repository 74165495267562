import { Icon } from '@chakra-ui/react';

export const AlignLeft = (props) => {
  return (
    <Icon viewBox="0 0 12 7" {...props}>
      <g fill="currentColor">
        <rect x='0' y='0' width='12' height='1'/>
        <rect x='0' y='3' width='8' height='1'/>
        <rect x='0' y='6' width='10' height='1'/>
      </g>
    </Icon>
  );
};
