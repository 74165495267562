export const addNodeFromBufferAction = (state, action) => {
  const entityNodes = {
    ...state.entityNodes,
    ...action.childrenNodes
  };
  const children = [...entityNodes[action.node.id].children];
  const childrenIndex = children.findIndex(item => item === action.node.id);
  //console.log(children, action.node)
  // children.splice(childrenIndex, 0, action.childrenNodes[0].id)

  entityNodes[action.node.id] = {
    ...entityNodes[action.node.id],
    children: [
      ...children,
      action.firstChildrenNodeId
    ]
  };
  return entityNodes;
};
