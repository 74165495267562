import React from 'react';
import { Box } from '@chakra-ui/react';

export const Container = ({ children, ...props }) => {
  return (
    <Box
      marginLeft="auto"
      marginRight="auto"
      paddingLeft="30px"
      paddingRight="30px"
      maxWidth="960px"
      {...props}
    >
      {children}
    </Box>
  );
};
