import React from 'react';
import { Box, Flex, Input, IconButton } from '@chakra-ui/react';
import empty from '../../../images/empty-image.svg';
import { truncate } from '../../../utils/truncate';

export const ImagePreview = React.forwardRef((allProps, ref) => {
  const { src, label, ...props } = allProps;
  return (
    <Flex
      ref={ref}
      height="1.5rem"
      alignItems="center"
      {...props}
    >
     <Flex
       position="absolute"
       alignItems="center"
       justifyContent="center"
       height="1.5rem"
       width="1.5rem"
     >
       <Box
         borderRadius={2}
         borderWidth={1}
         borderColor="gray.200"
         width="14px"
         height="14px"
         backgroundRepeat="no-repeat"
         backgroundPosition="center"
         backgroundSize="contain"
         backgroundImage={`url(${src ? src : empty})`}
         src={src}
       />
     </Flex>
     <Flex
       fontSize="xs"
       height="1.5rem"
       width="100%"
       borderWidth={1}
       paddingLeft="1.5rem"
       alignItems="center"
       borderColor="transparent"
       borderRadius="0.125rem"
       tabIndex={0}
       outline="none"
       transition="all 0.2s"
       color={label ? 'black' : 'gray.400'}
       _hover={{
         borderColor: 'gray.300'
       }}
       _focus={{
         borderColor: '#3182ce',
         boxShadow: '0 0 0 1px #3182ce'
       }}
     >
       <Input
         value={label ? truncate(label, 20) : (
           src ? 'Использутся ссылка' : 'Нет файла'
         )}
         isReadOnly={true}
         pl={0}
         size="xs"
         border="none"
         _focus={{
           border: 'none'
         }}
       />
     </Flex>
    </Flex>
  );
});
