import React, { useContext } from 'react';
import { Flex, Image as ImageChakra, Box } from '@chakra-ui/react';
import { SelectedNode } from '../../selectedNode';
import { ProjectContext } from '../../context/projectContext';
import empty from '../../../images/empty-image.svg';
import { getAttribute } from '../../../utils/getAttribute';
import { useSelector } from 'react-redux';

const TYPE_ALIGN = {
  'center': 'center',
  'left': 'flex-start',
  'right': 'flex-end'
}

const ImageMemo = ({ parentNodeId, nodeId }) => {
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const pathSelected = useSelector(state => state.selected.path);
  const node = projectNodes[nodeId];
  const levelSelected = pathSelected.length === 0 ? undefined : pathSelected.length - 1;
  const activeId = levelSelected !== undefined && pathSelected[levelSelected].id;
  const { attributes, properties } = node;

  if (!properties.hidden) {
    return (
      <SelectedNode
        nodeId={node.id}
      >
        <Flex
          justifyContent={TYPE_ALIGN[getAttribute(attributes, 'align', 'center')]}
          paddingTop={getAttribute(attributes, 'padding-top')}
          paddingRight={getAttribute(attributes, 'padding-right')}
          paddingBottom={getAttribute(attributes, 'padding-bottom')}
          paddingLeft={getAttribute(attributes, 'padding-left')}
          backgroundColor={getAttribute(attributes, 'container-background-color')}
        >
          {attributes.src ? (
            <ImageChakra
              alignSelf="flex-start"
              height={getAttribute(attributes, 'height', 'auto')}
              width={getAttribute(attributes, 'width', '100%')}
              src={getAttribute(attributes, 'src')}
              borderRadius={getAttribute(attributes, 'border-radius')}
              border={getAttribute(attributes, 'border')}
              {...activeId === node.id && { 'boxShadow': 'inset 0 0 0 1px #CBD5E0'}}
            />
          ) : (
            <Box
              width={attributes.width ? attributes.width : '40px'}
              height={attributes.height ? attributes.height : '40px'}
              backgroundImage={`url(${empty})`}
            />
          )}
        </Flex>
      </SelectedNode>
    );
  } else return null
};

export const Image = React.memo(ImageMemo);

