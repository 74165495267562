import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Spinner, Image } from '@chakra-ui/react';
import empty from '../../images/empty-image.svg';

export const DropZone = ({ uploadedImage, onUpload, onError })  => {
    const [fullSize, setFullSize] = useState();
    const loadingUploadImage = false;
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/jpeg, image/png',
        maxSize: 1048576,
        onDropAccepted: (e) => {
            setFullSize(false);
            onUpload(e)
        },
        onDropRejected: () => {
            setFullSize(false);
            onError()
        },
        //onDrop: onUpload
    });
    return (
        <section className="dropzone-input">
            <Box
                mt="4px"
                mb="4px"
                width="100%"
                height="160px"
                borderWidth={1}
                //borderStyle="dashed"
                borderRadius={5}
                display="flex"
                alignItems="center"
                justifyContent="center"
                transition="0.2s"
                outline="none"
                cursor="pointer"
                borderColor={fullSize ? 'blue.300' : 'gray.200'}
                backgroundImage={`url(${empty})`}
                overflow="hidden"
                {...getRootProps({className: 'dropzone'})}
                _hover={{
                    borderColor: 'blue.300'
                }}
            >
                <input {...getInputProps()} />
                <Image src={uploadedImage}/>
                {loadingUploadImage && (
                    <Spinner
                        color="blue.500"
                        size="sm"
                        marginRight="10px"
                    />
                )}
            </Box>
        </section>
    );
};
