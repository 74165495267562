import { getFileNameFromUrl } from '../getFileNameFromUrl';
import { replaceSlash } from '../replaceSlash';
import { nodeType } from '../../models';

const allowTypeFromParentHref = [nodeType.text, nodeType.image, nodeType.button];

const getUrl = (preview, value) => (preview ? `https://omail.su/${replaceSlash(value)}` : `cid:${getFileNameFromUrl(value)}`);

export const getAttributesFromNode = (node, parentNode, preview = true) => {
  const { attributes, properties } = node;
  const isExternalImage = properties?.imageSource === 'externalUrl';
  let newAttributes = '';

  newAttributes = Object.entries(attributes).reduce((previousValue, item) => {
    const [property, value] = item;
      if (property === 'src') {
        return isExternalImage ? (
          `${previousValue} ${property}=${value}"`
        ) : (`${previousValue} ${property}=${getUrl(preview, value)}""`)
      }
      if (property === 'background-url') {
        return isExternalImage ? (
          `${previousValue} ${property}=${value}"`
        ) : (`${previousValue} ${property}=${getUrl(preview, value)}""`)
      }
    return `${previousValue} ${property}="${value}"`
  }, '');

  if (parentNode && parentNode.properties?.href) {
    if (allowTypeFromParentHref.includes(node.type)) {
      if (!newAttributes.includes('href')) {
        newAttributes = `${newAttributes} href="${parentNode.properties?.href}"`
      }
    }
  }
  return newAttributes;
};
