import { getNodesByIds } from '../../../utils/dataObj/getNodesByIds';
import { getElementsHtml } from '../elementHtml';
import { getAttributesFromNode } from '../../../utils/dataObj/getAttributesFromNode';

export const heroHtml = (data, parentNode, node, preview) => {
  const children = getNodesByIds(data, node.children);
  return (`
    <mj-section${getAttributesFromNode(node, parentNode, preview)}>
      ${children.map(item => (
        getElementsHtml(data, node, item, preview)
      )).join('')}
    </mj-section>
  `);
};
