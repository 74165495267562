import { getNodesByIds } from '../../../utils/dataObj/getNodesByIds';
import { getElementsHtml } from '../elementHtml';

export const headAttributesHtml = (data, parentNode, node, preview) => {
  const children = getNodesByIds(data, node.children);
  return (`
    <mj-attributes>
      ${children.map(item => (
        getElementsHtml(data, node, item, preview)
      )).join('')}
    </mj-attributes>
  `);
};