import React from 'react';
import { Checkbox } from '../ui/checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { updateNode } from '../../../store/project.reducer';

const EmbeddedImageControlMemo = ({ ...props }) => {
  const dispatch = useDispatch();
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const projectNodesType = useSelector(state => state.project.present.entityNodesType);
  const nodeContainer = projectNodes[projectNodesType?.container?.id];

  const handleControl = (value) => {
    const newNode = {
      ...nodeContainer,
      properties: {
        ...nodeContainer.properties,
        embeddedImage: value
      }
    };
    dispatch(updateNode(newNode));
  }

  return (
    <Checkbox
      name="embeddedImage"
      label="Встраивать картинки"
      onChange={handleControl}
      value={nodeContainer?.properties?.embeddedImage}
      {...props}
    />
  );
};

export const EmbeddedImageControl = React.memo(EmbeddedImageControlMemo);
