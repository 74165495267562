import React, { useEffect } from 'react';
import { Box, Button, Text, Grid } from '@chakra-ui/react';
import { SendMail } from '../project/preview/sendMail';
import { ToolBar } from '../../components/toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectList, resetProjectList } from '../../store/projectList.reducer';
import { GridItem } from './gridItem';
import { ToolbarButton } from '../../components/toolbar/toolbarButton';
import { AddIcon } from '@chakra-ui/icons';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Loader } from '../../components/loader/loader';
import { createProject } from '../../store/project.reducer';
import { ContentWrapper, EmptyMessageBox } from '../../components/layout';
import EmptyBox from '../../images/empty-box.svg';

export const Projects = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();

  const loading = useSelector(state => state.projectList.loading);
  const user = useSelector(state => state.authentication.user);
  const list = useSelector(state => state.projectList.entities);

  const handleCreateProject = () => {
    const promise = dispatch(createProject({ userId: user.id }));
    promise.then(result => {
      history.push(`${match.url}/user/${result.value.data.id}`);
    });
  };

  useEffect(() => {
    dispatch(getProjectList({ userId: user.id }))
    return () => {
      dispatch(resetProjectList());
    }
  }, []);

  return (
    <>
      <ToolBar
        center="Мои проекты"
        right={
          <>
            <ToolbarButton
              label={<AddIcon color="white" boxSize="0.9rem"/>}
              onClick={handleCreateProject}
            />
          </>
        }
      />
      <ContentWrapper>
        <Loader isLoading={loading} message={!list.length && (
          <EmptyMessageBox src={EmptyBox}>
            <Box textAlign="center" maxWidth="260px">
              <Text mb={2}>Пока ничего нет</Text>
              <Text fontSize="0.86rem" color="gray.400">Создайте новый проект или скопируйте из шаблонов</Text>
            </Box>
          </EmptyMessageBox>
        )}>
          <Grid
            templateColumns={`repeat(auto-fill, minmax(200px, 1fr))`}
            gap={6}
          >
            {list && list.map(item => (
              <GridItem
                key={item.id}
                item={item}
                onClick={() => history.push(`${match.url}/user/${item.id}`)}
              />
            ))}
          </Grid>
        </Loader>
      </ContentWrapper>
    </>
  );
};
