import React from 'react';
import { StackControl } from '../stackControl';
import { GridControl } from '../gridControl';
import { PaddingTopControl } from '../controls/paddingTop.control';
import { PaddingRightControl } from '../controls/paddingRight.control';
import { PaddingBottomControl } from '../controls/paddingBottom.control';
import { PaddingLeftControl } from '../controls/paddingLeft.control';
import { BorderRadiusControl } from '../controls/borderRadius.control';
import { WidthControl } from '../controls/width.control';
import { ColorControl } from '../controls/color.control';
import { BorderControl } from '../controls/border.control';
import { VerticalAlignControl } from '../controls/verticalAlign.control';
import { useSelector } from 'react-redux';
import { LinkParentControl } from '../controls/linkParent.control';

const ColumnModuleMemo = ({ nodeId }) => {
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  const parentId = node?.path[node.path.length - 1].id;
  const parentNode = projectNodes[parentId];
  const widthColumn = node?.attributes.width ? node.attributes.width : `${100/parentNode?.children?.length}%`;
  return (
    <>
      <StackControl>
        <GridControl label="Отступы">
          <PaddingLeftControl nodeId={node.id} placeholder="0px"/>
          <PaddingRightControl nodeId={node.id} placeholder="0px"/>
          <PaddingTopControl nodeId={node.id} placeholder="0px"/>
          <PaddingBottomControl nodeId={node.id} placeholder="0px"/>
        </GridControl>
      </StackControl>
      <StackControl>
        <GridControl label="Размеры">
          <WidthControl nodeId={node.id} placeholder={`${widthColumn}`}/>
          <VerticalAlignControl nodeId={node.id}/>
        </GridControl>
      </StackControl>
      <StackControl>
        <GridControl label="Скругление снаружи">
          <BorderRadiusControl nodeId={node.id} placeholder="0px"/>
        </GridControl>
        <GridControl label="Скругление внутри">
          <BorderRadiusControl nodeId={node.id} placeholder="0px" attribute="inner-border-radius"/>
        </GridControl>
      </StackControl>
      <StackControl>
        <GridControl label="Цвет фона снаружи">
          <ColorControl nodeId={node.id} attribute="background-color"/>
        </GridControl>
        <GridControl label="Цвет фона внутри">
          <ColorControl nodeId={node.id} attribute="inner-background-color"/>
        </GridControl>
      </StackControl>
      <StackControl>
        <GridControl templateColumns="1fr" label="Рамка снаружи">
          <BorderControl nodeId={node.id}/>
        </GridControl>
        <GridControl templateColumns="1fr" label="Рамка внутри">
          <BorderControl nodeId={node.id} attribute="inner-border"/>
        </GridControl>
      </StackControl>
      <StackControl>
        <GridControl templateColumns="1fr" label="Ссылка">
          <LinkParentControl nodeId={node.id} />
        </GridControl>
      </StackControl>
    </>
  );
};

export const ColumnModule = React.memo(ColumnModuleMemo);