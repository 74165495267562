import { v4 as uuidv4 } from 'uuid';
import { nodeType } from '../models';

export const buttonPreset = () => {
  return {
    id: uuidv4(),
    type: nodeType.button,
    attributes: {
      'background-color': '#2753AC',
      'font-size': '14px',
      'color': '#ffffff',
      'font-weight': 'bold',
      'font-style': 'normal'
    },
    content: ['Текст'],
    properties: {},
    children: []
  }
};
