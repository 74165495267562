import { IconButton } from '@chakra-ui/react';
import React from 'react';

export const ItemButton =  React.forwardRef(({ ...props }, ref) => {
  return (
    <IconButton
      ref={ref}
      size="xs"
      variant="ghost"
      _focus={{
        boxShadow: 'none'
      }}
      {...props}
    />
  )
});
