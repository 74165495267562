import { getAttributes } from '../../../utils/getAttributes';
import { parseMdHtml } from '../../../utils/parseMd';

export const textHtml = (data, parentNode, node) => {
  const { attributes, content } = node;
  const text = content.length && content.join('\n');

  const html = parseMdHtml(text);

  if (parentNode?.properties?.href) {
    return (`
      <mj-text${getAttributes(attributes)}>
        <a href="${parentNode?.properties?.href}" style="text-decoration: none !important; color: ${attributes?.color}">${html}</a>
      </mj-text>
    `)
  }
  return (`
    <mj-text${getAttributes(attributes)}>
      ${html}
    </mj-text>
  `)
};
