export const clearObject = (obj) => {
  return Object.entries(obj).reduce((previousValue, item) => {
    const [property, value] = item;
    if (value) {
      return {
        ...previousValue,
        [property]: value
      }
    }
    return previousValue;
  }, {})
};
