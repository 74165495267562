import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { ProjectContext } from '../../components/context/projectContext';
import { Constructor } from './constructor';
import { useDispatch, useSelector } from 'react-redux';
import { getProject, resetProject, } from '../../store/project.reducer';
import { Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { Preview } from './preview';
import { useDebouncedCallback } from 'use-debounce';
import axios from 'axios';
import { ActionCreators } from 'redux-undo';
import isEmpty from 'lodash/isEmpty';
import { getAccess } from '../../utils/getAccess';
import { ImageManager } from './imageManager/imageManager';
import { denormalizeObj } from '../../utils/dataObj/denormalizeObj';
import { reset } from '../../store/selected.reducer';
import { templateHtml } from '../../components/elements/template';

const TYPE_PUSH = {
  user: '/projects',
  share: '/i',
  system: '/templates',
  admin: '/users'
}

export const Project = () => {
  const match = useRouteMatch();
  const { id, source, userAdminId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.authentication.user);
  const project = useSelector(state => state.project.present.entity);
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const projectNodesType = useSelector(state => state.project.present.entityNodesType);
  const nodeContainer = projectNodes[projectNodesType?.container?.id];
  const availableEntity = useSelector(state => state.project.present.availableEntity);
  const imagePreview = useRef({});
  const projectUserId = !isEmpty(project) && project.user?.id;
  const userId = !isEmpty(user) && user.id;

  const isReadOnly = useMemo(() => (
    (projectUserId !== userId) && (!getAccess(user.authorities, ['ROLE_ADMIN']) || source !== 'system')
  ), [projectUserId, source, user.authorities, userId]);
  const isProjectDisabled = isReadOnly && source === 'user';

  useEffect(() => {
    if (source === 'share') {
      if (projectUserId === userId && id === project?.id ) {
        history.push(`/projects/user/${id}`);
      }
    }
  }, [source, id, projectUserId, userId, project.id]);

  const goBack = useCallback((id) =>{
    if (source === 'admin') {
      const path = `${TYPE_PUSH[source]}/${userAdminId}`
      const pathId = `${TYPE_PUSH[source]}/${userAdminId}/${source}/${id}`
      history.push(id ? pathId : path);
    } else {
      const path = TYPE_PUSH[source];
      const pathId = `${TYPE_PUSH[source]}/${source}/${id}`;
      history.push(id ? pathId : path);
    }
  }, [history, source, userAdminId]);

  const saveProject = useDebouncedCallback((e) => {
    if (availableEntity && !isReadOnly) {
      const dataRequest = {
        ...project,
        items: [
          denormalizeObj(projectNodes, nodeContainer),
          templateHtml(projectNodes, nodeContainer, true)
        ],
        ...!isEmpty(imagePreview.current) && { image: imagePreview.current }
      };
      axios.put('/api/templates', {
        ...dataRequest
      }).then(r => {
        console.log('save done')
      })
    }

  }, 500);

  useEffect(() => {
    dispatch(getProject(id));
    return () => {
      dispatch(resetProject());
      dispatch(reset());
    }
  }, [id, source, userAdminId]);

  useEffect(() => {
    saveProject.callback();
  }, [project, projectNodes]);

  useEffect(() => {
    if (availableEntity) {
      dispatch(ActionCreators.clearHistory())
    }
  }, [availableEntity]);

  return (
    <ProjectContext.Provider value={{
      isReadOnly,
      isProjectDisabled,
      projectUserId,
      availableEntity,
      goBack
    }}>
      <>
        <Switch>
          <Route path={`${match.url}/preview`}>
            <Preview id={id}/>
          </Route>
          <Route path={`${match.url}/`}>
            <Constructor source={source}/>
          </Route>
        </Switch>
        <Route path={`${match.url}/images/:id`} children={<ImageManager/>}/>
      </>
    </ProjectContext.Provider>
  );
};
