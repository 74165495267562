import React, { useEffect } from 'react';
import { Button, VStack } from '@chakra-ui/react';
import { Modal, ModalFooter } from '../../components/modal';
import { useForm } from 'react-hook-form';
import { FormInput } from '../../components/formInput';
import { useDispatch, useSelector } from 'react-redux';
import { FormSelect } from '../../components/formSelect';
import { createUser, updateUser } from '../../store/user.reducer';
import isEmpty from 'lodash/isEmpty';

export const UsersUpdate = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.entity);
  const loadingUser = useSelector(state => state.user.loadingUser);
  const { register, handleSubmit, errors, reset } = useForm({
    defaultValues: {
      name: '',
      email: '',
      login: '',
      password: '',
      authorities: 'ROLE_USER'
    }
  });

  useEffect(() => {
    const { name, email, password, login } = user;
    if (!isEmpty(user)) {
      reset({
        name,
        email,
        login,
        password,
        authorities: user.authorities[0]
      });
    } else {
      reset({
        name: '',
        email: '',
        login: '',
        password: '',
        authorities: 'ROLE_USER'
      })

    }
  }, [user]);

  const onSubmit = async (values) => {
    const dataRequest = {
      ...values,
      authorities: [values.authorities],
      id: !isEmpty(user) ? user.id : undefined
    };
    if (!isEmpty(user)) {
      await dispatch(updateUser(dataRequest));
    } else {
      await dispatch(createUser(dataRequest));
    }
    onClose();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        header={!isEmpty(user) ? 'Редактирование' : 'Добавление пользователя'}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing="14px">
            <FormInput
              autoFocus
              name="name"
              label="Имя пользователя"
              placeholder=""
              errors={errors}
              ref={register({ required: true })}
            />
            <FormInput
              name="email"
              label="Электронная почта"
              placeholder=""
              errors={errors}
              ref={register({ required: true })}
            />
            <FormInput
              name="login"
              label="Логин для входа"
              placeholder=""
              errors={errors}
              ref={register({ required: true })}
            />
            <FormInput
              type="password"
              name="password"
              label="Пароль"
              placeholder=""
              errors={errors}
              ref={register({ required: Boolean(isEmpty(user)) })}
            />
            <FormSelect
              name="authorities"
              label="Роль"
              placeholder=""
              errors={errors}
              ref={register({ required: true })}
              options={[
                {
                  value: 'ROLE_USER',
                  label: 'Пользователь'
                },
                {
                  value: 'ROLE_MANAGER',
                  label: 'Менеджер'
                },
                {
                  value: 'ROLE_ADMIN',
                  label: 'Админ'
                }
              ]}
            />
          </VStack>
          <ModalFooter>
            <Button
              colorScheme="blue"
              width="100%"
              size="md"
              type="submit"
              isLoading={loadingUser}
            >
              {!isEmpty(user) ? 'Сохранить' : 'Добавить'}
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};
