import { getAttributes } from '../../../utils/getAttributes';
import { socialHtml } from '../social';
import { getNodesByIds } from '../../../utils/dataObj/getNodesByIds';

export const socialCollectionHtml = (data, parentNode, node, preview) => {
  const children = getNodesByIds(data, node.children);
  const { attributes } = node;
  return (`
    <mj-social${getAttributes(attributes)}>
      ${children.map(item => (
        socialHtml(data, node, item, preview)
      )).join('')}
    </mj-social>
  `);
};
