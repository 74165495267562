import React from 'react';
import { Element } from '../element';
import { SelectedNode } from '../../selectedNode';
import { getAttribute } from '../../../utils/getAttribute';
import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

const ColumnMemo = ({ parentNodeId, nodeId }) => {
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  const parentNode = projectNodes[parentNodeId];
  const { attributes, children } = node;
  const width = attributes.width ? attributes.width : `${100/parentNode.children.length}%`;
  return (
    <Box
      className="column"
      display="inline-block"
      width={width}
      border={getAttribute(attributes, 'border')}
      verticalAlign={getAttribute(attributes, 'vertical-align', 'top')}
    >
      <SelectedNode
        nodeId={node.id}
        backgroundColor={getAttribute(attributes, 'background-color')}
        borderRadius={getAttribute(attributes, 'border-radius')}
        paddingTop={getAttribute(attributes, 'padding-top')}
        paddingRight={getAttribute(attributes, 'padding-right')}
        paddingBottom={getAttribute(attributes, 'padding-bottom')}
        paddingLeft={getAttribute(attributes, 'padding-left')}
      >
        <Box
          border={getAttribute(attributes, 'inner-border')}
        >
          <Box
            borderRadius={getAttribute(attributes, 'inner-border-radius')}
            backgroundColor={getAttribute(attributes, 'inner-background-color')}
          >
            {children.map(item => (
              <Element
                key={item}
                parentNodeId={node.id}
                nodeId={item}
              />
            ))}
          </Box>
        </Box>
      </SelectedNode>
    </Box>
  );
};

export const Column = React.memo(ColumnMemo);