import React from 'react';
import { SectionModule } from './modules/section.module';
import { ImageModule } from './modules/image.module';
import { TextModule } from './modules/text.module';
import { SocialModule } from './modules/social.module';
import { SocialCollectionModule } from './modules/socialCollection.module';
import { ColumnModule } from './modules/column.module';
import { RowCollectionModule } from './modules/rowCollection.module';
import { RowModule } from './modules/row.module';
import { SpacerModule } from './modules/spacer.module';
import { ModuleModule } from './modules/module.module';
import { BodyModule } from './modules/body.module';
import { DividerModule } from './modules/divider.module';
import { ButtonModule } from './modules/button.module';
import { HeroModule } from './modules/hero.module';
import { GroupModule } from './modules/group.module';
import { useSelector } from 'react-redux';

export const CustomizerModuleMemo = ({ nodeId }) => {
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  switch (node.type) {
    case 'body':
      return <BodyModule nodeId={node.id} />
    case 'module':
      return <ModuleModule nodeId={node.id} />
    case 'section':
      return <SectionModule nodeId={node.id} />
    case 'group':
      return <GroupModule nodeId={node.id} />
    case 'column':
      return <ColumnModule nodeId={node.id} />
    case 'rowCollection':
      return <RowCollectionModule nodeId={node.id} />
    case 'row':
      return <RowModule nodeId={node.id} />
    case 'spacer':
      return <SpacerModule nodeId={node.id} />
    case 'divider':
      return <DividerModule nodeId={node.id} />
    case 'image':
      return <ImageModule nodeId={node.id} />
    case 'text':
      return <TextModule nodeId={node.id} />
    case 'button':
      return <ButtonModule nodeId={node.id} />
    case 'socialCollection':
      return <SocialCollectionModule nodeId={node.id} />
    case 'social':
      return <SocialModule nodeId={node.id} />
    case 'hero':
      return <HeroModule nodeId={node.id} />
    default:
      return null
  }
};

export const CustomizerModule = React.memo(CustomizerModuleMemo);