import { Icon } from '@chakra-ui/react';

export const Outer = (props) => {
  return (
    <Icon viewBox="0 0 12 12" {...props}>
      <g fill="currentColor">
        <path d="M0,12 L0,0 L12,0 L12,12 L0,12 Z M9,1 L3,1 L3,3 L1,3 L1,9 L3,9 L3,11 L9,11 L9,9 L11,9 L11,3 L9,3 L9,1 Z"/>
      </g>
    </Icon>
  );
};
