import React from 'react';
import { Flex, Image, Box } from '@chakra-ui/react';

export const EmptyMessageBox = ({ src, children }) => {
  return (
    <Flex flexDirection="column" alignItems="center" mt="30px" mb="30px">
      <Image src={src} boxSize="200px" mb="30px"/>
      <Box>{children}</Box>
    </Flex>
  );
};
