import { getAttributes } from '../../../utils/getAttributes';
import { getElementsHtml } from '../elementHtml';
import { getNodesByIds } from '../../../utils/dataObj/getNodesByIds';

export const columnHtml = (data, parentNode, node, preview) => {
  const children = getNodesByIds(data, node.children);
  const { attributes } = node;
  return (`
    <mj-column${getAttributes(attributes)}>
      ${children.map(item => (
        getElementsHtml(data, node, item, preview)
      )).join('')}
    </mj-column>
  `);
};
