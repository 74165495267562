import React from 'react';
import { FormControl, FormLabel, Select, FormErrorMessage } from '@chakra-ui/react';

export const FormSelect = React.forwardRef((props, ref ) => {
  const { name, label, errors, options } = props;
  return (
    <FormControl isInvalid={errors && errors[name]}>
      {label && (
        <FormLabel
          htmlFor={name}
          fontSize="0.86rem"
          width="100%"
          fontWeight="normal"
          mb={1}
        >
          {label}
        </FormLabel>
      )}
      <Select name={name} ref={ref} {...props}>
        {options.map(item => (
          <option key={item.value} value={item.value}>{item.label}</option>
        ))}
      </Select>
      <FormErrorMessage>
        {errors && errors[name] && errors[name].message}
      </FormErrorMessage>
    </FormControl>
  );
});
