import React, { useContext } from 'react';
import { Flex, Text, Icon } from '@chakra-ui/react';
import { ProjectContext } from '../../context/projectContext';
import { ELEMENTS_NAME } from '../../../models';
import {
  BiColumns,
  BiLayer,
  BiGridAlt,
  BiFont,
  BiImageAlt,
  BiSpaceBar,
  BiMinus,
  BiCollection,
  BiSquareRounded,
  BiShapeCircle,
  BiLink,
  BiBoltCircle,
  BiStar,
  BiShow,
  BiTrash,
  BiCarousel,
  BiCodeAlt
} from 'react-icons/bi';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { deleteNode, updateNode } from '../../../store/project.reducer';
import { resetHover, setLevel, updateHover } from '../../../store/selected.reducer';

const TYPE_NODE_ICON = {
  raw: BiCodeAlt,
  module: BiGridAlt,
  section: BiLayer,
  group: BiCarousel,
  column: BiColumns,
  text: BiFont,
  image: BiImageAlt,
  spacer: BiSpaceBar,
  divider: BiMinus,
  rowCollection: BiCollection,
  row: BiSquareRounded,
  socialCollection: BiShapeCircle,
  social: BiLink,
  button: BiBoltCircle,
  hero: BiStar
}

export const ChildrenNodeButtonMemo = ({ nodeId }) => {
  const dispatch = useDispatch();
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const hoverNodeId = useSelector(state => state.selected.hover);
  const node = projectNodes[nodeId];

  const { isReadOnly } = useContext(ProjectContext);

  const handleCLick = (e) => {
    e.stopPropagation();
    dispatch(setLevel());
  }

  const handleHidden = (e) => {
    e.stopPropagation();
    const { properties } = node;
    const newNode = {
      ...node,
      properties: {
        ...node.properties,
        hidden: !properties?.hidden
      }
    };
    dispatch(updateNode(newNode));
  }

  const handleDelete = (e) => {
    e.stopPropagation();
    const parentNodeId = !isEmpty(node) && node.path[node.path?.length - 1].id;
    const parentNode = projectNodes[parentNodeId];
    dispatch(deleteNode(parentNode, node.id));
  }

  const handleMouseMove = (e) => {
    e.stopPropagation();
    if (hoverNodeId !== node.id) {
      dispatch(updateHover(node.id, node.type, node.path))
    }
  }

  const handleMouseLeave = (e) => {
    e.stopPropagation();
    dispatch(resetHover())
  }

  return (
    <Flex
      role="group"
      position="relative"
      height="37px"
      paddingLeft="1rem"
      paddingRight="8px"
      alignItems="center"
      justifyContent="space-between"
      transition="color 250ms"
      cursor="pointer"
      backgroundColor={hoverNodeId === node.id && 'gray.100'}
      _hover={{
        backgroundColor: 'gray.100'
      }}
      _active={{
        backgroundColor: 'gray.200'
      }}
      onClick={handleCLick}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      pl={`${node.level * 13 }px`}
    >
      <Flex alignItems="center">
        {!isReadOnly ? (
          <Flex
            alignItems="center"
            justifyContent="center"
            position="relative"
            mr={3}
            onClick={handleHidden}
          >
            {!node?.properties?.hidden && (
              <Icon
                boxSize="0.8rem"
                as={TYPE_NODE_ICON[node.type]}
                position="absolute"
                right={0}
                transition="opacity 0.1s"
                _groupHover={{
                  opacity: 0
                }}
              />
            )}
            <Icon
              as={BiShow}
              boxSize="0.8rem"
              transition="opacity 0.1s"
              opacity={node?.properties?.hidden ? 1 : 0}
              color={node?.properties?.hidden ? 'red.500' : 'gray.400'}
              _hover={{
                color: node?.properties?.hidden ? 'red.500' : 'red.600'
              }}
              _groupHover={{
                opacity: '1'
              }}
            />
          </Flex>
        ) : (
          <Flex
            alignItems="center"
            justifyContent="center"
            position="relative"
          >
            <Icon
              boxSize="0.8rem"
              as={TYPE_NODE_ICON[node.type]}
              right={0}
              transition="opacity 0.1s"
            />
          </Flex>
        )}
        <Text
          fontSize="0.78rem"
          ml={isReadOnly && '13px'}
        >
          {ELEMENTS_NAME[node.type]}
        </Text>
      </Flex>
      <Flex
        transition="0.2s"
      >
        {!isReadOnly && (
          <Flex
            alignItems="center"
            justifyContent="center"
            onClick={handleDelete}
            opacity="0"
            mr={1}
            _groupHover={{
              opacity: '1'
            }}
          >
            <Icon
              as={BiTrash}
              color="gray.400"
              transition="0.2s"
              boxSize="0.78rem"
              _hover={{
                color: 'gray.400'
              }}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export const ChildrenNodeButton = React.memo(ChildrenNodeButtonMemo);
