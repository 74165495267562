import { v4 as uuidv4 } from 'uuid';

export const header1Preset = () => {
  return {
    id: uuidv4(),
    type: 'module',
    attributes: {},
    children: [
      {
        id: uuidv4(),
        type: 'section',
        attributes: {
          'padding-left': '20px',
          'padding-right': '20px',
          'padding-top': '20px',
          'padding-bottom': '20px',
          'background-color': '#ffffff'
        },
        properties: {},
        children: [
          {
            id: uuidv4(),
            type: 'column',
            attributes: {
              'padding-left': '0px',
              'padding-right': '0px',
              'padding-top': '0px',
              'padding-bottom': '0px',
              'vertical-align': 'middle'
            },
            properties: {},
            children: [
              {
                id: uuidv4(),
                type: 'image',
                attributes: {
                  'width': '226px',
                  'padding': '0',
                  'align': 'left',
                  'src': '/images/51011ed54b648e7a6733ee09995fa8b7.png'
                },
                properties: {
                  imageFileName: 'eb3c4afd18146ebed64c6bc3a6cb1718.png',
                  imageHeight: 58,
                  imageWidth: 452
                },
                children: []
              }
            ]
          },
          {
            id: uuidv4(),
            type: 'column',
            attributes: {
              'padding': '0',
              'vertical-align': 'middle'
            },
            properties: {},
            children: [
              {
                id: uuidv4(),
                type: 'text',
                attributes: {
                  'padding-left': '0px',
                  'padding-right': '0px',
                  'padding-top': '0px',
                  'padding-bottom': '0px',
                  'font-size': '16px',
                  'align': 'right',
                  'line-height': '1',
                  'color': '#000000',
                  'font-weight': 'normal',
                  'font-style': 'normal'
                },
                content: ['Январь 2021'],
                properties: {},
                children: []
              }
            ]
          }
        ]
      }
    ]
  };
};
