import React from 'react';
import { Box } from '@chakra-ui/react';
import { SelectedNode } from '../../selectedNode';
import { getAttribute } from '../../../utils/getAttribute';
import { useSelector } from 'react-redux';

export const Spacer = ({ parentNodeId, nodeId }) => {
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  const { attributes } = node;
  return (
    <SelectedNode
      nodeId={node.id}
    >
      <Box
        backgroundColor={getAttribute(attributes, 'container-background-color')}
        paddingTop={getAttribute(attributes, 'padding-top')}
        paddingRight={getAttribute(attributes, 'padding-right')}
        paddingBottom={getAttribute(attributes, 'padding-bottom')}
        paddingLeft={getAttribute(attributes, 'padding-left')}
        height={getAttribute(attributes, 'height', '20px')}
      />
    </SelectedNode>
  );
};
