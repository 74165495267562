import React, { useEffect, useState } from 'react';
import { Box, Input as ChakraInput, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { PopoverPicker } from '../../popoverPicker';
import { useDebouncedCallback } from 'use-debounce';
import isHexColor from 'is-hexcolor';
import empty from '../../../images/empty-image.svg';

const parseValue = (value, valueEnter, isClear) => {
  if (!isClear) {
    if (isHexColor(valueEnter)) {
      return valueEnter
    } else {
      return('#000000')
    }
  } else {
    if (isHexColor(valueEnter)) {
      return valueEnter
    } else {
      if (valueEnter === '#') {
        return ''
      } else {
        return(value);
      }
    }
  }
}

export const InputColor = React.forwardRef((allProps, ref) => {
  const {
    value = '',
    isClear = true,
    onBlur,
    ...props
  } = allProps;
  const [valueInput, setValueInput] = useState(value);
  const debounced = useDebouncedCallback((e) => {
    if (value !== valueInput) {
      onBlur(e);
    }
  }, 50);

  const handleInputBlur = () => {
    const result = parseValue(value, valueInput, isClear);
    if (result !== valueInput) {
      setValueInput(result);
    }
    if (value !== valueInput) {
      onBlur(result);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value[0] === '#') {
      setValueInput(value);
    } else {
      setValueInput(`#${value}`);
    }
  }

  const handlePicker = (e) => {
    setValueInput(e);
    debounced.callback(e);
  };

  useEffect(() => {
    if (value !== valueInput) {
      setValueInput(value !== undefined ? value : '');
    }
  }, [value]);

  const handleKeyDown = (e) => {
    switch (e.which) {
      case 27: // Escape
      {
        if (e.altKey) {
          return;
        }
        onBlur(value);
        break;
      }
      case 13: // Enter Key
      {
        if (e.altKey) {
          return;
        }
        onBlur(parseValue(value, valueInput, isClear));
        break;
      }
      //case 9:
      default:
        return;
    }
    e.preventDefault();
  };

  return (
    <InputGroup size="xs">
      <InputLeftElement
        pointerEvents="auto"
      >
        {isHexColor(valueInput) ? (
          <PopoverPicker
            color={isHexColor(valueInput) ? valueInput : '#ffffff'}
            onChange={(e) => handlePicker(e)}
            defaultIsOpen={value === '' || valueInput === ''}
          />
        ) : (
          <Box
            borderRadius={2}
            borderWidth={1}
            borderColor="gray.200"
            width="14px"
            height="14px"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
            backgroundSize="contain"
            backgroundImage={`url(${empty})`}
            onClick={() => setValueInput('#ffffff')}
          />
        )}
      </InputLeftElement>
      <ChakraInput
        ref={ref}
        borderColor="transparent"
        value={valueInput.slice(1)}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        onKeyDown={handleKeyDown}
        {...props}
      />
    </InputGroup>
  )
});
