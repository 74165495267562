export const nodeType = {
  container: 'container',
  body: 'body',
  raw: 'raw',
  module: 'module',
  section: 'section',
  group: 'group',
  column: 'column',
  rowCollection: 'rowCollection',
  row: 'row',
  text: 'text',
  image: 'image',
  divider: 'divider',
  spacer: 'spacer',
  socialCollection: 'socialCollection',
  social: 'social',
  button: 'button',
  hero: 'hero'
}