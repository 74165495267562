import React, { useEffect, useState } from 'react';
import { Modal } from '../../../components/modal';
import { Grid, Flex, IconButton, Box, useDisclosure } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useHistory, useParams } from 'react-router-dom';
import { getImages, getImagesCategories, resetImages, resetImagesCategories } from '../../../store/image.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { CategoryItem } from './categoryItem';
import { ImageItem } from './imageItem';
import { Loader } from '../../../components/loader/loader';
import { updateNode } from '../../../store/project.reducer';

export const ImageManager = () => {
  const dispatch = useDispatch();
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id } = useParams();
  const [category, setCategory] = useState('');
  const loading = useSelector(state => state.image.loading);
  const list = useSelector(state => state.image.categories);
  const images = useSelector(state => state.image.images);
  const selectedCategory = useSelector(state => state.image.selectedCategory);
  const node = projectNodes[id];

  const history = useHistory();

  const handleClose = async () => {
    await onClose();
    history.goBack();
  }

  const handleClickImage = (value) => {

    const { imageUrl, height, width, file } = value;

    let newNode = {...node};

    if (node.type === 'section') {
      newNode.attributes = {
        ...newNode.attributes,
        'background-url': imageUrl,
        'background-size': 'cover',
        'background-repeat': 'no-repeat'
      }
    } else if (node.type === 'image' ||
      node.type === 'social') {
      newNode.attributes = {
        ...newNode.attributes,
        'src': imageUrl
      }
    } else if (node.type === 'module') {
      newNode.attributes = {
        ...newNode.attributes,
        'background-url': imageUrl,
        'background-size': 'cover',
        'background-repeat': 'no-repeat'
      }
    } else if (node.type === 'hero') {
      newNode.attributes = {
        ...newNode.attributes,
        'background-url': imageUrl,
        'background-size': 'cover',
        'background-repeat': 'no-repeat'
      }
    }

    newNode.properties = {
      ...newNode.properties,
      imageFileName: file,
      imageHeight: height,
      imageWidth: width,
    }

    dispatch(updateNode(newNode));
    history.goBack();
  };

  useEffect(() => {
    onOpen();
    return () => {
      onClose();
    }
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getImagesCategories());
    }
    return () => {
      if (id) {
        dispatch(resetImagesCategories());
      }
    };
  }, [id])

  useEffect(() => {
    if (category !== '') {
      dispatch(getImages({
        category: [category]
      }));
    } else {
      dispatch(resetImages());
    }
  }, [category])

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => history.goBack()}
      maxWidth="620px"
      header={
        <Flex alignItems="center" minHeight="40px">
          {selectedCategory?.name && (
            <IconButton
              isRound
              mr={3}
              size="md"
              variant="ghost"
              aria-label="Back"
              icon={<ArrowBackIcon/>}
              onClick={() => setCategory('')}
            />
          )}
          <Box>
            {selectedCategory?.name ? selectedCategory.name : 'Изображения'}
          </Box>
        </Flex>
      }
    >
      <Loader isLoading={loading}>
        {category ? (
          <Grid
            templateColumns={`repeat(auto-fill, minmax(100px, 1fr))`}
            gap={6}
            mb={2}
          >
            {images && images.map(item => (
              <ImageItem
                key={item.id}
                item={item}
                onClick={() => handleClickImage(item)}
              />
            ))}
          </Grid>
        ) : (
          list && list.map(item => (
            <CategoryItem
              key={item.id}
              item={item}
              onClick={() => setCategory(item.category)}
            />
          ))
        )}
      </Loader>
    </Modal>
  );
};
