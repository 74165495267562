import React from 'react';
import { FormControl, FormLabel, Switch } from '@chakra-ui/react';

export const Checkbox = React.forwardRef((allProps, ref) => {
  const {
    label,
    name,
    value = false,
    onChange
  } = allProps;

  const handleChecked = (e) => {
    onChange && onChange(e.target.checked)
  }

  return (
    <>
      <FormControl
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding="2px 0"
      >
        <FormLabel htmlFor={name} mb="0" fontSize="0.75rem" cursor="pointer">
          {label}
        </FormLabel>
        <Switch id={name} isChecked={value} size="sm" onChange={handleChecked}/>
      </FormControl>
    </>
  )
});
