import React from 'react';
import { Flex, Grid } from '@chakra-ui/react';
import { BurgerMenu } from './burgerMenu';

export const ToolBar = ({ left, center, right }) => {
  return (
    <Flex
      backgroundColor="gray.800"
      height="40px"
      position="relative"
      zIndex={4}
      justifyContent="center"
      alignItems="center"
    >
      <Flex alignItems="center" position="absolute" left={0}>
        <BurgerMenu/>
        {left}
      </Flex>
      <Flex flex={1} color="white" alignItems="center" justifyContent="center">
        {center}
      </Flex>
      <Grid
        gridAutoFlow="column"
        gap="14px"
        alignItems="center"
        position="absolute"
        right={0}
      >
        {right}
      </Grid>
    </Flex>
  )
}