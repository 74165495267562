import React from 'react';
import { IconButton, Menu, MenuButton, MenuItem } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { MenuList } from '../menu';

export const ProjectMenu = ({ children, ...props }) => {

  return (
    <Menu {...props}>
      <MenuButton
        as={IconButton}
        aria-label="Опции"
        variant="ghost"
        isRound
        color="white"
        icon={<ChevronDownIcon/>}
        size="sm"
        ml="6px"
        _active={{
          opacity: 0.4
        }}
        _hover={{
          opacity: 0.8
        }}
        _focus={{
          boxShadow: 'none'
        }}
      />
      <MenuList marginLeft={3}>
        {children}
      </MenuList>
    </Menu>
  )
}