import React, { useContext } from 'react';
import { CustomizerPanel } from '../../../components/layout';
import { ProjectContext } from '../../../components/context/projectContext';
import { TreePanel } from '../../../components/customizer/tree/treePanel';
import { Scrollbars } from 'react-custom-scrollbars';
import { ToolbarCustomizer } from '../../../components/customizer/toolbarCustomizer/toolbarCustomizer';
import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { CustomizerModule } from '../../../components/customizer';

export const CustomizerMemo = () => {
  const { isReadOnly } = useContext(ProjectContext);
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const projectNodesType = useSelector(state => state.project.present.entityNodesType);
  const pathSelected = useSelector(state => state.selected.path);
  const levelSelected = pathSelected.length === 0 ? undefined : pathSelected.length - 1;
  const nodeIdActive = levelSelected !== undefined && pathSelected[levelSelected].id;
  const nodeBody = projectNodes[projectNodesType?.body?.id];
  const node = projectNodes[nodeIdActive];

  const handleMouseDown = (e) => {
    e.stopPropagation();
  }

  return (
    <CustomizerPanel onMouseDown={handleMouseDown}>
      <Scrollbars style={{ height: 'calc(100vh - 40px)' }}>
        <TreePanel/>
        {!isReadOnly && (
          levelSelected === undefined ? (
            <Box>
              <ToolbarCustomizer nodeId={nodeBody.id}/>
              <CustomizerModule nodeId={nodeBody.id}/>
            </Box>
          ) : (
            <Box>
              <ToolbarCustomizer nodeId={node.id}/>
              <CustomizerModule nodeId={node.id}/>
            </Box>
        ))}
      </Scrollbars>
    </CustomizerPanel>
  );
};

export const Customizer = React.memo(CustomizerMemo);
