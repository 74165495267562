import { v4 as uuidv4 } from 'uuid';

export const textPreset = () => {
  return {
    id: uuidv4(),
    type: 'text',
    attributes: {
      'padding-left': '0px',
      'padding-right': '0px',
      'padding-top': '0px',
      'padding-bottom': '0px',
      'font-size': '16px',
      'align': 'left',
      'line-height': '24px',
      'color': '#000000',
      'font-weight': 'normal',
      'font-style': 'normal'
    },
    content: ['Текст'],
    properties: {},
    children: []
  }
};
