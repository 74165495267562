import { Icon } from '@chakra-ui/react';

export const Font = (props) => {
  return (
    <Icon viewBox="0 0 8 9" {...props}>
      <g fill="currentColor">
        <path d='M1.51171875,9 L2.36132812,6.58007812 L5.72460938,6.58007812 L6.57421875,9 L7.68164062,9 L4.56445312,0.544921875 L3.52148438,0.544921875 L0.404296875,9 L1.51171875,9 Z M5.4140625,5.68359375 L2.671875,5.68359375 L3.99609375,1.91015625 L4.08984375,1.91015625 L5.4140625,5.68359375 Z' fillRule='nonzero'/>
      </g>
    </Icon>
  );
};
