import { getNodesByIds } from '../../../utils/dataObj/getNodesByIds';
import { getElementsHtml } from '../elementHtml';

export const rowCollectionHtml = (data, parentNode, node, preview) => {
  const children = getNodesByIds(data, node.children);
  return (`
    ${children.map(item => (
      getElementsHtml(data, node, item, preview)
    )).join('')}
  `);
};
