import React, { useEffect, useState } from 'react';
import { Editable as EditableChakra, EditableInput, EditablePreview } from '@chakra-ui/react';

export const Editable = ({ value, onBlur, ...props }) => {
  const [valueInput, setValueInput] = useState(value);

  const handleBlur = (e) => {
    onBlur && onBlur(valueInput)
  }

  useEffect(() => {
    setValueInput(value)
  }, [value])

  return (
    <EditableChakra
      value={valueInput}
      onChange={setValueInput}
      onBlur={handleBlur}
      {...props}
    >
      <EditablePreview />
      <EditableInput
        textAlign="center"
        _focus={{
          boxShadow: 'none'
        }}
      />
    </EditableChakra>
  );
};
