import React from 'react';
import { SelectedNodeButton } from './selectedNodeButton';
import { ChildrenNodeButton } from './childrenNodeButton';
import { useSelector } from 'react-redux';

export const TreePanelMemo = () => {
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const projectNodesType = useSelector(state => state.project.present.entityNodesType);
  const pathSelected = useSelector(state => state.selected.path);
  const levelSelected = pathSelected.length === 0 ? undefined : pathSelected.length - 1;
  const nodeIdActive = levelSelected !== undefined && pathSelected[levelSelected].id;
  const nodeBody = projectNodes[projectNodesType?.body?.id];
  const node = projectNodes[nodeIdActive];

  return (
    <>
      <SelectedNodeButton
        id={nodeBody.id}
        type={nodeBody.type}
        index={-1}
        length={pathSelected.length && pathSelected.length}
      />
      {Boolean(pathSelected.length) && pathSelected.map((item, index) => (
        <SelectedNodeButton
          key={item.id}
          id={item.id}
          type={item.type}
          index={index}
          length={pathSelected.length}
        />
      ))}
      {nodeIdActive ? (
        node && node.children.map(item => (
          <ChildrenNodeButton key={item} nodeId={item} />
        ))
      ) : (
        nodeBody && nodeBody.children.map(item => (
          <ChildrenNodeButton key={item} nodeId={item} />
        ))
      )}
    </>
  );
};

export const TreePanel = React.memo(TreePanelMemo);
