export const addNodeAction = (state, action) => {
  const entityNodes = {
    ...state.entityNodes,
    ...action.childrenNodes
  };
  const children = [...entityNodes[action.node.id].children];
  entityNodes[action.node.id] = {
    ...entityNodes[action.node.id],
    children: [
      ...children,
      action.startChildrenNodeId
    ]
  };
  return entityNodes;
};
