import React, { useEffect, useMemo } from 'react';
import { VStack } from '@chakra-ui/react';
import { FormInput } from '../../../../components/formInput';
import { useDispatch, useSelector } from 'react-redux';
import { createSettingMail, getSettingMailList, updateSettingMail } from '../../../../store/settingMail.reducer';
import { useForm } from 'react-hook-form';
import { getSettingFromList } from '../../../../utils/getSettingFromList';

const getLogin = (value) => {
  const indexSymbol = value.indexOf('@');
  if (indexSymbol !== -1) {
    return value.slice(0, indexSymbol);
  } else {
    return value;
  }
};

export const SendMailSettings = ({ ...props }) => {
  const dispatch = useDispatch();
  const userAuth = useSelector(state => state.authentication.user);
  const settingList = useSelector(state => state.settingMail.entities);
  const settings = useMemo(() => getSettingFromList(settingList, userAuth.id), [settingList, userAuth.id]);

  const defaultValues = {
    login: settings?.login ? settings.login : '1',
    password: settings?.password ? settings.password : '1'
  }

  const { register, errors, getValues, reset } = useForm({
    mode: 'onBlur',
    defaultValues
  });

  const handleBlur = () => {
    const dataRequest = {
      ...settings,
      ...getValues(),
      login: getLogin(getValues().login),
      name: userAuth.email,
      type: 'EWS',
      server: 'https://mail.sovcombank.group',
    };
    if (dataRequest?.id) {
      dispatch(updateSettingMail(dataRequest));
    } else {
      dispatch(createSettingMail(dataRequest));
    }
  }

  useEffect(() => {
    dispatch(getSettingMailList());
  }, []);

  useEffect(() => {
    reset(defaultValues);
  }, [settings])

  return (
    <VStack spacing="14px" {...props}>
      <FormInput
        name="login"
        label="Логин или почта"
        placeholder=""
        errors={errors}
        ref={register({ required: true })}
        onBlur={handleBlur}
      />
      <FormInput
        name="password"
        type="password"
        label="Пароль"
        placeholder=""
        errors={errors}
        ref={register({ required: true })}
        onBlur={handleBlur}
      />
    </VStack>
  );
};
