import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Images } from './images';
import { ImagesShow } from './imagesShow';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Login } from '../login/login';

const Routes = () => {
  const match = useRouteMatch();
  const userAuth = useSelector(state => state.authentication.user);
  const isAuthenticated = !isEmpty(userAuth);
  return (
    isAuthenticated ? (
    <Switch>
      <Route path={`${match.url}/:id`}>
        <ImagesShow/>
      </Route>
      <Route path={`${match.url}/`}>
        <Images/>
      </Route>
    </Switch>
    ) : (
      <Login/>
    )
  );
};

export default Routes;
