import { normalizeObj } from '../../utils/dataObj/normalizeObj';

export const duplicateEntityAction = (state, action) => {
  const [entityNodes, entityNodesType] = normalizeObj(action.payload.data.items[0]);
  const {
    id,
    name,
    imageUrl,
    minVersion,
    generator,
    createdAt,
    updatedAt,
    description,
    sources,
    states
  } = action.payload.data;
  const userDuplicate = action.payload.data.userDuplicate
  const entity = {
    id,
    name,
    imageUrl,
    minVersion,
    generator,
    createdAt,
    updatedAt,
    description,
    sources,
    states,
    user: {
      email: userDuplicate.email,
      hidden: userDuplicate.hidden,
      id: userDuplicate.id,
      name: userDuplicate.name
    }
  };
  if (generator === 'OWL') {
    return {
      entity,
      availableEntity: true,
      entityNodes,
      entityNodesType
    }
  }
  return {};
};
