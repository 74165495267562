import React from 'react';
import { StackControl } from '../stackControl';
import { GridControl } from '../gridControl';
import { WidthControl } from '../controls/width.control';
import { ColorControl } from '../controls/color.control';
import { EmbeddedImageControl } from '../controls/embeddedImage.control';
import { useSelector } from 'react-redux';
import { DesktopControl } from '../controls/desktop.control';

const BodyModuleMemo = ({ nodeId }) => {
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  return (
    <>
      <StackControl>
        <GridControl label="Ширина">
          <WidthControl nodeId={node.id} placeholder="600px"/>
        </GridControl>
      </StackControl>
      <StackControl>
        <GridControl label="Фоновый цвет">
          <ColorControl nodeId={node.id} isClear={false} attribute="background-color"/>
        </GridControl>
      </StackControl>
      <StackControl>
        <GridControl templateColumns="1fr">
          <DesktopControl nodeId={node.id}/>
          <EmbeddedImageControl nodeId={node.id}/>
        </GridControl>
      </StackControl>
    </>
  );
};

export const BodyModule = React.memo(BodyModuleMemo);
