import React from 'react';
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';

export const Modal = (
  {
    isOpen,
    onClose,
    children,
    maxWidth = '360px',
    footer = null,
    header = null,
    ...props
  }
) => {
  return (
    <ChakraModal isOpen={isOpen} onClose={onClose} {...props}>
      <ModalOverlay />
      <ModalContent pb={3} maxWidth={maxWidth}>
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton mt={1} />
        <ModalBody>
          {children}
        </ModalBody>
        {footer && (
          <ModalFooter>
            {footer}
          </ModalFooter>
        )}
      </ModalContent>
    </ChakraModal>
  );
};
