import React from 'react';
import { StackControl } from '../stackControl';
import { WrapperControl } from '../controls/wrapper.control';
import { PaddingTopControl } from '../controls/paddingTop.control';
import { PaddingRightControl } from '../controls/paddingRight.control';
import { PaddingBottomControl } from '../controls/paddingBottom.control';
import { PaddingLeftControl } from '../controls/paddingLeft.control';
import { BorderRadiusControl } from '../controls/borderRadius.control';
import { ColorControl } from '../controls/color.control';
import { GridControl } from '../gridControl';
import { ImageControl } from '../controls/image.control';
import { BorderControl } from '../controls/border.control';
import { useSelector } from 'react-redux';

export const ModuleModuleMemo = ({ nodeId }) => {
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  return (
    <>
      <StackControl>
        <GridControl templateColumns="1fr">
          <WrapperControl nodeId={node.id}/>
        </GridControl>
      </StackControl>
      {node?.properties?.wrapper && (
        <>
          <StackControl>
            <GridControl label="Отступы">
              <PaddingLeftControl nodeId={node.id} placeholder="0px"/>
              <PaddingRightControl nodeId={node.id} placeholder="0px"/>
              <PaddingTopControl nodeId={node.id} placeholder="20px"/>
              <PaddingBottomControl nodeId={node.id} placeholder="20px"/>
            </GridControl>
            <GridControl label="Скругление">
              <BorderRadiusControl nodeId={node.id} placeholder="0px"/>
            </GridControl>
          </StackControl>
          <StackControl>
            <GridControl  label="Фон">
              <ColorControl nodeId={node.id} attribute="background-color"/>
            </GridControl>
            <GridControl templateColumns="1fr" label="Фоновая картинка">
              <ImageControl nodeId={node.id} attribute="background-url" />
            </GridControl>
          </StackControl>
          <StackControl>
            <GridControl templateColumns="1fr" label="Рамка">
              <BorderControl nodeId={node.id}/>
            </GridControl>
          </StackControl>
        </>
      )}
    </>
  );
};

export const ModuleModule = React.memo(ModuleModuleMemo);
