import { Icon } from '@chakra-ui/react';

export const Border = (props) => {
  return (
    <Icon viewBox="0 0 13 13" {...props}>
      <g fill="currentColor">
        <path d="M9,0 C10.6568542,0 12,1.34314575 12,3 L12,9 C12,10.6568542 10.6568542,12 9,12 L3,12 C1.34314575,12 0,10.6568542 0,9 L0,3 C0,1.34314575 1.34314575,0 3,0 M9,1 L3,1 C1.9456382,1 1.08183488,1.81587779 1.00548574,2.85073766 L1,3 L1,9 C1,10.0543618 1.81587779,10.9181651 2.85073766,10.9945143 L3,11 L9,11 C10.0543618,11 10.9181651,10.1841222 10.9945143,9.14926234 L11,9 L11,3 C11,1.9456382 10.1841222,1.08183488 9.14926234,1.00548574" />
      </g>
    </Icon>
  );
};
