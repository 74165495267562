import { v4 as uuidv4 } from 'uuid';

export const content2Preset = () => {
  return {
    id: uuidv4(),
    type: 'module',
    attributes: {},
    children: [
      {
        id: uuidv4(),
        type: 'section',
        attributes: {
          'padding-left': '20px',
          'padding-right': '20px',
          'padding-top': '14px',
          'padding-bottom': '14px',
          'background-color': '#ffffff'
        },
        properties: {},
        children: [
          {
            id: uuidv4(),
            type: 'column',
            attributes: {
              'padding-left': '10px',
              'padding-right': '10px',
              'padding-top': '10px',
              'padding-bottom': '10px',
              'vertical-align': 'top',
            },
            properties: {
              'href': 'https://xwiki.sovcombank.group/xwiki/bin/view/Инструкции+по+работе+с+xwiki/external_access_xwiki'
            },
            children: [
              {
                id: uuidv4(),
                type: 'image',
                attributes: {
                  'padding-left': '10px',
                  'padding-right': '10px',
                  'padding-top': '10px',
                  'padding-bottom': '10px',
                  'width': '58px',
                  'padding': '0',
                  'align': 'center',
                  'src': '/images/bbf8e5ea6479acc4c345a08107656b0c.png',
                  'href': ''
                },
                properties: {
                  imageFileName: 'wiki.png',
                  imageHeight: 125,
                  imageWidth: 126
                },
                children: []
              },
              {
                id: uuidv4(),
                type: 'text',
                attributes: {
                  'padding-left': '0px',
                  'padding-right': '0px',
                  'padding-top': '0px',
                  'padding-bottom': '0px',
                  'align': 'center',
                  'color': '#4299E1',
                  'line-height': '24px',
                  'font-size': '14px',
                  'font-weight': 'bold',
                  'font-style': 'normal'
                },
                content: ['Wiki дома'],
                children: [],
                properties: {}
              }
            ]
          },
          {
            id: uuidv4(),
            type: 'column',
            attributes: {
              'padding-left': '10px',
              'padding-right': '10px',
              'padding-top': '10px',
              'padding-bottom': '10px',
              'vertical-align': 'top',
            },
            properties: {
              'href': 'https://xwiki.sovcombank.group/xwiki/bin/view/Департамент%20продаж/Доведение%20информации%20об%20утверждении%20нормативных%20документов.%20Январь%202021'
            },
            children: [
              {
                id: uuidv4(),
                type: 'image',
                attributes: {
                  'padding-left': '10px',
                  'padding-right': '10px',
                  'padding-top': '10px',
                  'padding-bottom': '10px',
                  'width': '58px',
                  'padding': '0',
                  'align': 'center',
                  'src': '/images/ed66cac7ee3043b514d9fd675bf8bdfa.png',
                  'href': ''
                },
                properties: {
                  imageFileName: 'doc.png',
                  imageHeight: 128,
                  imageWidth: 128
                },
                children: []
              },
              {
                id: uuidv4(),
                type: 'text',
                attributes: {
                  'padding-left': '0px',
                  'padding-right': '0px',
                  'padding-top': '0px',
                  'padding-bottom': '0px',
                  'align': 'center',
                  'color': '#4299E1',
                  'line-height': '24px',
                  'font-size': '14px',
                  'font-weight': 'bold',
                  'font-style': 'normal'
                },
                content: ['Документы'],
                children: [],
                properties: {}
              }
            ]
          },
          {
            id: uuidv4(),
            type: 'column',
            attributes: {
              'padding-left': '10px',
              'padding-right': '10px',
              'padding-top': '10px',
              'padding-bottom': '10px',
              'vertical-align': 'top'
            },
            properties: {
              'href': 'https://portal.sovcombank.ru/material/lesson/test/0689lj2nlyj217v4'
            },
            children: [
              {
                id: uuidv4(),
                type: 'image',
                attributes: {
                  'padding-left': '10px',
                  'padding-right': '10px',
                  'padding-top': '10px',
                  'padding-bottom': '10px',
                  'width': '58px',
                  'padding': '0',
                  'align': 'center',
                  'src': '/images/0c612d3c59a54feedc87fbf3f5f90653.png',
                  'href': ''
                },
                properties: {
                  imageFileName: 'AB.png',
                  imageHeight: 125,
                  imageWidth: 126
                },
                children: []
              },
              {
                id: uuidv4(),
                type: 'text',
                attributes: {
                  'padding-left': '0px',
                  'padding-right': '0px',
                  'padding-top': '0px',
                  'padding-bottom': '0px',
                  'align': 'center',
                  'color': '#4299E1',
                  'line-height': '24px',
                  'font-size': '14px',
                  'font-weight': 'bold',
                  'font-style': 'normal'
                },
                content: ['Пройти тест'],
                children: [],
                properties: {}
              }
            ]
          }
        ]
      }
    ]
  };
};
