import React, { useEffect } from 'react';
import { Box, Grid } from '@chakra-ui/react';
import { ToolBar } from '../../components/toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { getImagesCategories, resetImagesCategories } from '../../store/image.reducer';
import { CategoryItem } from './categoryItem';
import { CategoryAdd } from './categoryAdd';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Loader } from '../../components/loader/loader';

export const Images = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const loading = useSelector(state => state.image.loading);
  const list = useSelector(state => state.image.categories);

  useEffect(() => {
    dispatch(getImagesCategories());
    return () => {
      dispatch(resetImagesCategories());
    }
  }, []);

  return (
    <>
      <ToolBar
        center="Изображения"
        right={<CategoryAdd/>}
      />
      <Box
        padding="30px 30px"
        height="calc(100vh - 40px)"
      >
        <Loader isLoading={loading}>
          <Grid
            templateColumns={`repeat(auto-fill, minmax(200px, 1fr))`}
            gap={6}
          >
            {list && list.map(item => (
              <CategoryItem
                key={item.id}
                item={item}
                onClick={() => history.push(`${match.url}/${item.category}`)}
              />
            ))}
          </Grid>
        </Loader>
      </Box>
    </>
  );
};
