import { v4 as uuidv4 } from 'uuid';

export const rowCollectionPreset = () => {
  return {
    id: uuidv4(),
    type: 'rowCollection',
    attributes: {},
    properties: {},
    children: [
      {
        id: uuidv4(),
        type: 'row',
        attributes: {},
        properties: {},
        children: []
      }
    ]
  }
};
