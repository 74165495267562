export const getPaddingCortegeFromValue = (style = '') => {
  const arr = style && style.split(' ');
  switch (arr.length) {
    case 1: {
      return [arr[0], arr[0], arr[0], arr[0]]
    }
    case 2: {
      return [arr[0], arr[1], arr[0], arr[1]]
    }
    case 3: {
      return [arr[0], arr[1], arr[2], arr[1]]
    }
    case 4: {
      return [arr[0], arr[1], arr[2], arr[3]]
    }
    default: return ['', '', '', '']
  }
}
