import { normalizeObj } from '../../utils/dataObj/normalizeObj';

export const handleEntityAction = (state, action) => {
  const {
    id,
    name,
    imageUrl,
    minVersion,
    generator,
    createdAt,
    updatedAt,
    description,
    sources,
    states,
    user
  } = action.payload.data;
  const entity = {
    id,
    name,
    imageUrl,
    minVersion,
    generator,
    createdAt,
    updatedAt,
    description,
    sources,
    states,
    user: {
      ...user
    }
  };
  if (generator === 'OWL') {
    const [entityNodes, entityNodesType] = normalizeObj(action.payload.data.items[0]);
    return {
      entity,
      availableEntity: true,
      entityNodes,
      entityNodesType
    }
  }
  return {};
};
