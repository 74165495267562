import React, { useEffect } from 'react';
import { Box, Grid, useDisclosure } from '@chakra-ui/react';
import { ToolBar } from '../../components/toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { ToolbarButton } from '../../components/toolbar/toolbarButton';
import { UsersItem } from './usersItem';
import { UsersUpdate } from './usersUpdate';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getUser, getUserList, resetUser, resetUsersList } from '../../store/user.reducer';
import { AddIcon } from '@chakra-ui/icons';
import { Loader } from '../../components/loader/loader';
import { Container, ContentWrapper } from '../../components/layout';

export const Users = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const list = useSelector(state => state.user.entities);
  const loading = useSelector(state => state.user.loading);

  const handleSettingClick = async (id) => {
    id && await dispatch(getUser(id));
    onOpen();
  }

  const handleSettingClose = () => {
    dispatch(resetUser());
    onClose();
  }

  useEffect(() => {
    dispatch(getUserList());
    return () => {
      dispatch(resetUsersList());
    };
  }, []);

  return (
    <>
      <ToolBar
        center="Пользователи"
        right={
          <ToolbarButton
            label={<AddIcon color="white" boxSize="0.9rem"/>}
            onClick={() => handleSettingClick()}
          />
        }
      />
      <ContentWrapper>
        <Loader isLoading={loading}>
          <Container>
            <Grid
              templateColumns="42px 1fr 1fr 30px"
              alignItems="center"
              gap="8px"
              fontSize="0.8rem"
              pl="8px"
              pr="6px"
              borderBottomWidth={1}
              backgroundColor="gray.50"
              fontWeight="semibold"
              paddingTop="5px"
              paddingBottom="5px"
            >
              <Box/>
              <Box>Имя</Box>
              <Box>Email</Box>
              <Box/>
            </Grid>
            <Grid>
              {list && list.map(item => (
                <UsersItem
                  key={item.id}
                  item={item}
                  onClick={() => history.push(`${match.url}/${item.id}`)}
                  onSettingClick={handleSettingClick}
                />
              ))}
            </Grid>
          </Container>
        </Loader>
      </ContentWrapper>
      <UsersUpdate
        onClose={handleSettingClose}
        isOpen={isOpen}
      />
    </>
  );
};
