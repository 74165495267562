import React from 'react';
import { getPaddingCortegeFromValue } from '../../../utils/getPaddingCortegeFromValue';
import { getPaddingValueFromCortege } from '../../../utils/getPaddingValueFromCortege';
import { InputSize } from '../ui/inputSize';
import { ArrowBackIcon, ArrowDownIcon, ArrowForwardIcon, ArrowUpIcon } from '@chakra-ui/icons';
import { GridControl } from '../gridControl';
import { useDispatch, useSelector } from 'react-redux';
import { updateNode } from '../../../store/project.reducer';

export const IndentShortControlMemo = ({ nodeId, attribute = 'inner-padding' }) => {
  const dispatch = useDispatch();
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  const { attributes } = node;
  const [pt, pr, pb, pl] = getPaddingCortegeFromValue(attributes[attribute])
  const handleControl = (value, name) => {
    let style = '';
    if (name === 'pt') {
      style = getPaddingValueFromCortege([value, pr, pb, pl]);
    }
    if (name === 'pr') {
      style = getPaddingValueFromCortege([pt, value, pb, pl]);
    }
    if (name === 'pb') {
      style = getPaddingValueFromCortege([pt, pr, value, pl]);
    }
    if (name === 'pl') {
      style = getPaddingValueFromCortege([pt, pr, pb, value]);
    }

    const newNode = {
      ...node,
      attributes: {
        ...node.attributes,
        [attribute]: style
      }
    };
    dispatch(updateNode(newNode));
  };

  return (
    <GridControl>
      <InputSize
        value={pl}
        leftElement={<ArrowBackIcon color="gray.300"/>}
        onBlur={e => handleControl(e, 'pl')}
        placeholder="Авто"
      />
      <InputSize
        value={pr}
        leftElement={<ArrowForwardIcon color="gray.300"/>}
        onBlur={e => handleControl(e, 'pr')}
        placeholder="Авто"
      />
      <InputSize
        value={pt}
        leftElement={<ArrowUpIcon color="gray.300"/>}
        onBlur={e => handleControl(e, 'pt')}
        placeholder="Авто"
      />
      <InputSize
        value={pb}
        leftElement={<ArrowDownIcon color="gray.300"/>}
        onBlur={e => handleControl(e, 'pb')}
        placeholder="Авто"
      />
    </GridControl>
  );
};

export const IndentShortControl = React.memo(IndentShortControlMemo);