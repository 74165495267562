import { Icon } from '@chakra-ui/react';

export const VAlignTop = (props) => {
  return (
    <Icon viewBox="0 0 10 7" {...props}>
      <g fill="currentColor">
        <path d="M10,6 L10,7 L0,7 L0,6 L10,6 Z M10,0 L10,3 L0,3 L0,0 L10,0 Z" />
      </g>
    </Icon>
  );
};
