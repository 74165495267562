import React from 'react';
import { Box } from '@chakra-ui/react';
import { Element } from '../element';
import { SelectedNode } from '../../selectedNode';
import { getAttribute } from '../../../utils/getAttribute';
import { useSelector } from 'react-redux';

export const HeroMemo = ({ parentNodeId, nodeId }) => {
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  const { attributes, children } = node;
  return (
    <SelectedNode nodeId={node.id}>
      <Box
        paddingTop={getAttribute(attributes, 'padding-top', '0px')}
        paddingRight={getAttribute(attributes, 'padding-right', '0px')}
        paddingBottom={getAttribute(attributes, 'padding-bottom', '0px')}
        paddingLeft={getAttribute(attributes, 'padding-left', '0px')}
        backgroundColor={getAttribute(attributes, 'background-color')}
        backgroundPosition={getAttribute(attributes, 'background-position', 'center center')}
        backgroundImage={getAttribute(attributes, 'background-url')}
        borderRadius={getAttribute(attributes, 'border-radius')}
        height={getAttribute(attributes, 'height')}
        width={getAttribute(attributes, 'width')}
        verticalAlign={getAttribute(attributes, 'vertical-align', 'top')}
      >
        {children.map(item => (
          <Element
            key={item}
            parentNodeId={node.id}
            nodeId={item}
          />
        ))}
      </Box>
    </SelectedNode>
  )
};

export const Hero = React.memo(HeroMemo);
