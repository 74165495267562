import React from 'react';
import { Box } from "@chakra-ui/react"
import { TextEditor } from '../../textEditor';
import { SelectedNode } from '../../selectedNode';
import { getAttribute } from '../../../utils/getAttribute';
import { useDispatch, useSelector } from 'react-redux';
import { updateNode } from '../../../store/project.reducer';

export const TextMemo = ({ parentNodeId, nodeId }) => {
  const dispatch = useDispatch();
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  const { content, attributes, properties } = node;
  const handleTextEditor = (value) => {
    const newNode = {
      ...node,
      content: value
    };
    dispatch(updateNode(newNode));
  }

  if (!properties.hidden) {
    return (
      <SelectedNode
        nodeId={node.id}
      >
        <Box
          paddingTop={getAttribute(attributes, 'padding-top', '10px')}
          paddingRight={getAttribute(attributes, 'padding-right', '25px')}
          paddingBottom={getAttribute(attributes, 'padding-bottom', '10px')}
          paddingLeft={getAttribute(attributes, 'padding-left', '25px')}
          backgroundColor={getAttribute(attributes, 'container-background-color')}
          /*lineHeight={getAttribute(attributes, 'line-height', '20px')}
          fontSize={getAttribute(attributes, 'font-size', '13px')}
          fontWeight={getAttribute(attributes, 'font-weight')}
          fontStyle={getAttribute(attributes, 'font-style')}
          textAlign={getAttribute(attributes, 'align', 'left')}*/
        >
          <Box
            height={getAttribute(attributes, 'height')}
          >
            <TextEditor
              data={content}
              nodeId={node.id}
              attributes={attributes}
              onBlur={handleTextEditor}
            />
          </Box>
        </Box>
      </SelectedNode>
    );
  } else return null
};

export const Text = React.memo(TextMemo);
