import { getAttributes } from '../../../utils/getAttributes';
import { getNodesByIds } from '../../../utils/dataObj/getNodesByIds';
import isEmpty from 'lodash/isEmpty';
import { getElementsHtml } from '../elementHtml';

export const templateHtml = (data, node, preview) => {
  if (!isEmpty(data)) {
    const desktopAttribute = node?.properties?.desktop ? ` owa="desktop"` : '';
    const children = getNodesByIds(data, node.children);
    const { attributes } = node;
    return (`
    <mjml${getAttributes(attributes)}${desktopAttribute}>
      ${children.map(item => (
        getElementsHtml(data, node, item, preview)
      )).join('')}
    </mjml>
  `);
  } else return ''
};
