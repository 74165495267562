export const ELEMENTS_NAME = {
  body: 'Контейнер',
  raw: 'Произвольный код',
  module: 'Модуль',
  section: 'Секция',
  column: 'Колонка',
  group: 'Группа',
  rowCollection: 'Коллекция',
  row: 'Ячейка',
  spacer: 'Пробел',
  image: 'Изображение',
  text: 'Текст',
  button: 'Кнопка',
  hero: 'Герой',
  socialCollection: 'Соцсети',
  social: 'Ссылка',
  divider: 'Линия',
  element: 'Элемент'
}