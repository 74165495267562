import React from 'react';
import { RadioIconButtons } from '../ui/radioIconButtons';
import { AlignCenter, AlignLeft, AlignRight } from '../../icons';
import { useDispatch, useSelector } from 'react-redux';
import { updateNode } from '../../../store/project.reducer';

const TextAlignControlMemo = ({ nodeId, attribute = 'text-align' }) => {
  const dispatch = useDispatch();
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  const { attributes } = node;

  const handleControl = (value) => {
    const newNode = {
      ...node,
      attributes: {
        ...node.attributes,
        [attribute]: value
      }
    };
    dispatch(updateNode(newNode));
  };

  return (
    <RadioIconButtons
      options={['left', 'center', 'right']}
      value={attributes[attribute]}
      onChange={handleControl}
      icons={{
        'left': <AlignLeft boxSize={3} color="black"/>,
        'center': <AlignCenter boxSize={3} color="black"/>,
        'right': <AlignRight boxSize={3} color="black"/>
      }}
    />
  );
};

export const TextAlignControl = React.memo(TextAlignControlMemo);