import React from 'react';
import { Element } from '../element';
import { Box, Code } from '@chakra-ui/react';
import { SelectedNode } from '../../selectedNode';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { TextEditor } from '../../textEditor';
import { updateNode } from '../../../store/project.reducer';

export const RawMemo = ({ parentNodeId, nodeId }) => {
  const dispatch = useDispatch();
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  const { content } = node;
  const handleTextEditor = (value) => {
    const newNode = {
      ...node,
      content: value
    };
    dispatch(updateNode(newNode));
  }
  return (
    <SelectedNode
      nodeId={node.id}
    >
      <Code
        padding="10px"
        fontSize="0.78rem"
        colorScheme="gray"
        width="100%"
        fontFamily="SFMono-Regular, Menlo, Monaco, Consolas, monospace"
      >
        <TextEditor
          data={content}
          nodeId={node.id}
          //attributes={attributes}
          onBlur={handleTextEditor}
          isInnerHtml={false}
        />
      </Code>
    </SelectedNode>
  );
};

export const Raw = React.memo(RawMemo);
