import React from 'react';
import { Box } from '@chakra-ui/react';
import { SelectedNode } from '../../selectedNode';
import { getAttribute } from '../../../utils/getAttribute';
import { TextEditor } from '../../textEditor';
import { useDispatch, useSelector } from 'react-redux';
import { updateNode } from '../../../store/project.reducer';

const ButtonMemo = ({ parentNodeId, nodeId }) => {
  const dispatch = useDispatch();
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  const { attributes, properties, content } = node;

  const handleButtonEditor = (value) => {
    const newNode = {
      ...node,
      content: value
    };
    dispatch(updateNode(newNode));
  }

  if (!properties.hidden) {
    return (
      <SelectedNode
        nodeId={node.id}
      >
        <Box
          backgroundColor={getAttribute(attributes, 'container-background-color')}
          paddingTop={getAttribute(attributes, 'padding-top', '10px')}
          paddingRight={getAttribute(attributes, 'padding-right', '25px')}
          paddingBottom={getAttribute(attributes, 'padding-bottom', '10px')}
          paddingLeft={getAttribute(attributes, 'padding-left', '25px')}
          textAlign={getAttribute(attributes, 'align', 'center')}
        >
          <Box
            display="inline-block"
            cursor="default"
            backgroundColor={getAttribute(attributes, 'background-color', '#414141')}
            borderRadius={getAttribute(attributes, 'border-radius', '3px')}
            border={getAttribute(attributes, 'border')}
            color={getAttribute(attributes, 'color', '#ffffff')}
            fontSize={getAttribute(attributes, 'font-size', '13px')}
            fontStyle={getAttribute(attributes, 'font-style')}
            fontWeight={getAttribute(attributes, 'font-weight', 'normal')}
            lineHeight={getAttribute(attributes, 'line-height', '120%')}
            height={getAttribute(attributes, 'height')}
            verticalAlign={getAttribute(attributes, 'vertical-align', 'middle')}
            width={getAttribute(attributes, 'width')}
            padding={getAttribute(attributes, 'inner-padding', '10px 25px')}
          >
            <TextEditor
              data={content}
              nodeId={node.id}
              attributes={attributes}
              onBlur={handleButtonEditor}
            />
          </Box>
        </Box>
      </SelectedNode>
    );
  } else return null
};

export const Button = React.memo(ButtonMemo);
