import { header1Preset } from '../../../presets/templates/header1.preset';
import { v4 as uuidv4 } from 'uuid';

export const templates = [
  {
    id: uuidv4(),
    title: 'Шапка',
    children: [
      {
        id: uuidv4(),
        title: 'Шапка 1',
        src: 'images/0afa293acd3a2e4dfcb2736f170a20ab.png',
        type: 'header1'
      }
    ]
  },
  {
    id: uuidv4(),
    title: 'Приветствие',
    children: [
      {
        id: uuidv4(),
        title: 'Слайд',
        src: 'images/a90f61140cd370fa27912ca1ce2909ff.png',
        type: 'intro1'
      }
    ]
  },
  {
    id: uuidv4(),
    title: 'Контент',
    children: [
      {
        id: uuidv4(),
        title: 'Корпоративный',
        src: 'images/be758d2717103459629d36740128138e.png',
        type: 'content1'
      },
      {
        id: uuidv4(),
        title: 'Преимущества',
        src: 'images/f5984a70d951e844e1c34345ded1fa6c.png',
        type: 'content2'
      }
    ]
  },
  {
    id: uuidv4(),
    title: 'Подвал',
    children: [
      {
        id: uuidv4(),
        title: 'Стандартный',
        src: 'images/be2914e483ecb7b062a8f0ad63325338.png',
        type: 'footer1'
      }
    ]
  },
]