import { getAttributes } from '../../../utils/getAttributes';
import { getNodesByIds } from '../../../utils/dataObj/getNodesByIds';
import { getElementsHtml } from '../elementHtml';

export const bodyHtml = (data, parentNode, node, preview) => {
  const children = getNodesByIds(data, node.children);
  const { attributes } = node;
  return (`
    <mj-body${getAttributes(attributes)}>
      ${children.map(item => (
        getElementsHtml(data, node, item, preview)
      )).join('')}
    </mj-body>
  `);
};
