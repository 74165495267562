export const deleteNodeAction = (state, action) => {
  const entityNodes = Object.entries(state.entityNodes).reduce((previousValue, [id, value]) => {
    if (action.idsChildrenNodes.includes(id)) {
      return { ...previousValue }
    } else {
      return {
        ...previousValue,
        [id]: { ...value }
      }
    }
  }, {});
  const children = [...entityNodes[action.node.id].children.filter(item => item !== action.childrenNodeId)];
  entityNodes[action.node.id] = {
    ...entityNodes[action.node.id],
    children: [
      ...children
    ]
  }
  return entityNodes;
};
