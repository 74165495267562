import React from 'react';
import { Button, Flex, Text} from '@chakra-ui/react';

export const ToolbarButton = React.forwardRef((allProps, ref) => {
  const { type = 'ghost', label, children, isDisabled, isActive, onClick, ...props } = allProps;

  const handleClick = () => {
    onClick && !isDisabled && onClick();
  }

  if (type === 'ghost') {
    return (
      <Flex
        ref={ref}
        height="40px"
        transition="0.2s"
        alignItems="center"
        justifyContent="center"
        opacity={!isDisabled ? 1 : '0.6'}
        {...label && { width: '40px' }}
        {...isActive && { backgroundColor: 'blue.500' }}
        {...!isDisabled && { cursor: 'pointer' }}
        _hover={{
          ...!isDisabled && { backgroundColor: 'blue.500' }
        }}
        _active={{
          ...!isDisabled && { backgroundColor: 'blue.600' }
        }}
        onClick={handleClick}
        {...props}
      >
        {label ? label : (
          <Text
            pl="14px"
            pr="14px"
            color="white"
            fontSize="0.76rem"
            fontWeight="semibold"
          >
            {children}
          </Text>
        )}
      </Flex>
    )
  } else {
   return (
    <Button
      colorScheme="blue"
      borderRadius={4}
      height="1.8rem"
      size="xs"
      _focus={{
        boxShadow: 'none'
      }}
      onClick={handleClick}
      {...props}
    >
      {children}
    </Button>
   )
  }
});
