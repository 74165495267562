import React from 'react';
import { Box } from '@chakra-ui/react';

export const HeadingControl = ({ children }) => {
  return (
      <Box
        fontSize="xs"
        fontWeight="semibold"
        //mb={1}
      >
        {children}
      </Box>
  );
};
