import { ChakraProvider, ColorModeScript, theme } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { HashRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore, bindActionCreators } from 'redux';
import { rootReducer } from './store';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { logger } from 'redux-logger';
import promise from 'redux-promise-middleware';
import setupAxiosInterceptors from './axios';
import { resetAuth } from './store/authentication.reducer';

export const store = createStore(rootReducer, composeWithDevTools(
  applyMiddleware(thunk, promise)
));

// applyMiddleware(thunk, logger, promise)

const actions = bindActionCreators({ resetAuth }, store.dispatch);

setupAxiosInterceptors(() => actions.resetAuth());

ReactDOM.render(
  <StrictMode>
    <ColorModeScript />
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <Router>
          <App />
        </Router>
      </ChakraProvider>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
