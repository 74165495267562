import { getNodesByIds } from '../../../utils/dataObj/getNodesByIds';
import { getElementsHtml } from '../elementHtml';
import { getAttributes } from '../../../utils/getAttributes';

export const headTextHtml = (data, parentNode, node, preview) => {
  const children = getNodesByIds(data, node.children);
  const { attributes } = node;
  return (`
    <mj-text${getAttributes(attributes)}>
      ${children.map(item => (
        getElementsHtml(data, node, item, preview)
      )).join('')}
    </mj-text>
  `);
};
