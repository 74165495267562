import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ToolBar } from '../../../components/layout';
import { Flex } from '@chakra-ui/react';
import { ProjectContext } from '../../../components/context/projectContext';
import { templateHtml } from '../../../components/elements/template';
import { useDispatch, useSelector } from 'react-redux';
import { SendMail } from './sendMail';
import { getHtml } from '../../../store/html.reducer';
import isEmpty from 'lodash/isEmpty';
import { ToolbarButton } from '../../../components/toolbar/toolbarButton';
import { AiOutlineDesktop, AiOutlineMobile } from 'react-icons/ai';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Loader } from '../../../components/loader/loader';
import { PreviewExport } from './previewExport';
import { PreviewHtml } from './previewHtml';

const tabContent = (tabActive) => {
  switch (tabActive) {
    case 'html': return <PreviewExport/>
    case 'send': return <SendMail/>
    default:
      return <PreviewHtml tabActive={tabActive}/>
  }
}

export const Preview = ({ id }) => {
  const { goBack } = useContext(ProjectContext);
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const projectNodesType = useSelector(state => state.project.present.entityNodesType);
  const nodeContainer = projectNodes[projectNodesType?.container?.id];
  const dispatch = useDispatch();
  const loadingHtml = useSelector(state => state.html.loading);
  const loadingProject = useSelector(state => state.project.present.loading);
  const [tabActive, setTabActive] = useState('desktop');
  const html = useMemo(() => templateHtml(projectNodes, nodeContainer, false), [projectNodes]);
  const htmlPreview = useMemo(() => templateHtml(projectNodes, nodeContainer, true), [projectNodes]);

  useEffect(() => {
    if (!isEmpty(projectNodes)) {
      dispatch(getHtml(html, htmlPreview));
    }
  }, [projectNodes]);

  return (
    <>
      <ToolBar
        left={
          <ToolbarButton
            label={<ArrowBackIcon color="white"/>}
            onClick={() => goBack(id)}
          />
        }
        right={
          <Flex alignItems="center">
            <ToolbarButton
              onClick={() => setTabActive('html')}
              isActive={tabActive === 'html'}
            >
              Сохранить HTML
            </ToolbarButton>
            <ToolbarButton
              onClick={() => setTabActive('send')}
              isActive={tabActive === 'send'}
            >
              Отправить
            </ToolbarButton>
          </Flex>
        }
        center={
          <Flex>
            <ToolbarButton
              label={<AiOutlineDesktop color="white"/>}
              onClick={() => setTabActive('desktop')}
              isActive={tabActive === 'desktop'}
            />
            <ToolbarButton
              label={<AiOutlineMobile color="white"/>}
              onClick={() => setTabActive('mobile')}
              isActive={tabActive === 'mobile'}
            />
          </Flex>
        }
      />

      <Loader isLoading={loadingProject || loadingHtml}>
        {!isEmpty(projectNodes) && (
          tabContent(tabActive)
        )}
      </Loader>
    </>
  );
};
