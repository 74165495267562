import React from 'react';
import { Column } from './column';
import { Image } from './image';
import { Text } from './text';
import { SocialCollection} from './socialCollection';
import { Social} from './social';
import { Body } from './body';
import { Section } from './section';
import { Module } from './module';
import { RowCollection } from './rowCollection';
import { Row } from './row';
import { Spacer } from './spacer';
import { Divider } from './divider';
import { nodeType } from '../../models';
import { Button } from './button';
import { Hero } from './hero';
import { Group } from './group';
import { useSelector } from 'react-redux';
import { Raw } from './raw';

export const ElementMemo = ({ parentNodeId, nodeId }) => {
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  if (!node?.properties?.hidden) {
    switch (node.type) {
      case nodeType.body: return <Body parentNodeId={parentNodeId} nodeId={nodeId} />
      case nodeType.raw: return <Raw parentNodeId={parentNodeId} nodeId={nodeId} />
      case nodeType.module: return <Module parentNodeId={parentNodeId} nodeId={nodeId} />
      case nodeType.section: return <Section parentNodeId={parentNodeId} nodeId={nodeId} />
      case nodeType.group: return <Group parentNodeId={parentNodeId} nodeId={nodeId} />
      case nodeType.column: return <Column parentNodeId={parentNodeId} nodeId={nodeId} />
      case nodeType.rowCollection: return <RowCollection parentNodeId={parentNodeId} nodeId={nodeId} />
      case nodeType.row: return <Row parentNodeId={parentNodeId} nodeId={nodeId} />
      case nodeType.image: return <Image parentNodeId={parentNodeId} nodeId={nodeId} />
      case nodeType.text: return <Text parentNodeId={parentNodeId} nodeId={nodeId} />
      case nodeType.spacer: return <Spacer parentNodeId={parentNodeId} nodeId={nodeId} />
      case nodeType.divider: return <Divider parentNodeId={parentNodeId} nodeId={nodeId} />
      case nodeType.socialCollection: return <SocialCollection parentNodeId={parentNodeId} nodeId={nodeId} />
      case nodeType.social: return <Social parentNodeId={parentNodeId} nodeId={nodeId} />
      case nodeType.button : return <Button parentNodeId={parentNodeId} nodeId={nodeId} />
      case nodeType.hero : return <Hero parentNodeId={parentNodeId} nodeId={nodeId} />
      default:
        return null
    }
  } else return null;
};

export const Element = React.memo(ElementMemo);
