
export const rawHtml = (data, parentNode, node) => {
  const { content } = node;
  const text = content.length && content.join('\n');
  return (`
    <mj-raw>
      ${text}
    </mj-raw>
  `);
};
