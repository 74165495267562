import React from 'react';
import { StackControl } from '../stackControl';
import { GridControl } from '../gridControl';
import { PaddingTopControl } from '../controls/paddingTop.control';
import { PaddingRightControl } from '../controls/paddingRight.control';
import { PaddingBottomControl } from '../controls/paddingBottom.control';
import { PaddingLeftControl } from '../controls/paddingLeft.control';
import { FontSizeControl } from '../controls/fontSize.control';
import { LineHeightControl } from '../controls/lineHeight.control';
import { ColorControl } from '../controls/color.control';
import { FontStyleControl } from '../controls/fontStyle.control';
import { TextAlignControl } from '../controls/textAlign.control';
import { HeightControl } from '../controls/height.control';
import { useSelector } from 'react-redux';
import { LinkParentControl } from '../controls/linkParent.control';

export const TextModuleMemo = ({ nodeId }) => {
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  return (
    <>
      <StackControl>
        <GridControl label="Отступы">
          <PaddingLeftControl nodeId={node.id} placeholder="25px"/>
          <PaddingRightControl nodeId={node.id} placeholder="25px"/>
          <PaddingTopControl nodeId={node.id} placeholder="10px"/>
          <PaddingBottomControl nodeId={node.id} placeholder="10px"/>
        </GridControl>
      </StackControl>
      <StackControl>
        <GridControl label="Шрифт">
          <FontSizeControl nodeId={node.id}  placeholder="13px"/>
          <LineHeightControl nodeId={node.id} placeholder="20px"/>
          <TextAlignControl nodeId={node.id} attribute="align"/>
          <FontStyleControl nodeId={node.id}/>
        </GridControl>
      </StackControl>
      <StackControl>
        <GridControl label="Цвет текста">
          <ColorControl nodeId={node.id} attribute="color" isClear={false} placeholder="#000000"/>
        </GridControl>
      </StackControl>
      <StackControl>
        <GridControl label="Фон">
          <ColorControl nodeId={node.id} attribute="container-background-color" />
        </GridControl>
      </StackControl>
      <StackControl>
        <GridControl label="Высота">
          <HeightControl nodeId={node.id}/>
        </GridControl>
      </StackControl>
    </>
  );
};

export const TextModule = React.memo(TextModuleMemo);
