import { FAILURE, REQUEST, SUCCESS } from './actions-type';
import axios from 'axios';

export const ACTION_TYPES = {
    FETCH_USER_LIST: 'user/FETCH_USER_LIST',
    FETCH_USER_LIST_ADMIN: 'user/FETCH_USER_LIST_ADMIN',
    RESET_USER_LIST: 'user/RESET_TEMPLATE_LIST',
    CREATE_USER: 'user/CREATE_USER',
    FETCH_USER: 'user/FETCH_USER',
    DELETE_USER: 'user/DELETE_USER',
    UPDATE_USER: 'user/UPDATE_USER',
    RESET_USER: 'user/RESET_USER'
};

const initialState = {
    entities: [],
    entity: {},
    loading: false,
    loadingUser: false,
    errorMessage: ''
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.UPDATE_USER):
        case REQUEST(ACTION_TYPES.CREATE_USER):
        case REQUEST(ACTION_TYPES.FETCH_USER): {
            return {
                ...state,
                loadingUser: true
            }
        }
        case REQUEST(ACTION_TYPES.FETCH_USER_LIST_ADMIN):
        case REQUEST(ACTION_TYPES.FETCH_USER_LIST): {
            return {
                ...state,
                loading: true
            }
        }
        case FAILURE(ACTION_TYPES.UPDATE_USER):
        case FAILURE(ACTION_TYPES.CREATE_USER):
        case FAILURE(ACTION_TYPES.FETCH_USER): {
            return {
                ...state,
                loadingUser: false
            }
        }
        case FAILURE(ACTION_TYPES.FETCH_USER_LIST_ADMIN):
        case FAILURE(ACTION_TYPES.FETCH_USER_LIST): {
            return {
                ...state,
                loading: false,
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_USER_LIST): {
            return {
                ...state,
                loading: false,
                entities: action.payload.data.items
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_USER_LIST_ADMIN): {
            const newEntities = action.payload.data.items;
            return {
                ...state,
                loading: false,
                entities: newEntities
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_USER): {
            return {
                ...state,
                loadingUser: false,
                entity: action.payload.data
            }
        }
        case SUCCESS(ACTION_TYPES.CREATE_USER): {
            const newEntities = state.entities.concat([action.payload.data])
            return {
                ...state,
                loadingUser: false,
                entities: newEntities
            }
        }
        case SUCCESS(ACTION_TYPES.DELETE_USER): {
            const newEntities = state.entities.filter(item => item.id !== action.payload.data.id);
            return {
                ...state,
                entities: newEntities
            }
        }
        case SUCCESS(ACTION_TYPES.UPDATE_USER): {
            const index = state.entities.findIndex(item => item.id === action.payload.data.id);
            const newEntities = [...state.entities];
            newEntities[index] = action.payload.data;
            return {
                ...state,
                entities: newEntities,
                loadingUser: false
            }
        }
        case ACTION_TYPES.RESET_USER_LIST: {
            return {
                ...state,
                entities: []
            }
        }
        case ACTION_TYPES.RESET_USER: {
            return {
                ...state,
                entity: {}
            }
        }
        default: return state;
    }
};

const API_URL = '/api/users';

export const getUserList = () => {
    const requestUrl = `${API_URL}`;
    return {
        type: ACTION_TYPES.FETCH_USER_LIST,
        payload: axios.get(requestUrl)
    };
};

export const getUserListAdmin = () => {
    const requestUrl = `${API_URL}`;
    return {
        type: ACTION_TYPES.FETCH_USER_LIST_ADMIN,
        payload: axios.get(requestUrl)
    };
};

export const resetUsersList = () => {
    return {
        type: ACTION_TYPES.RESET_USER_LIST
    };
};

export const resetUser = () => {
    return {
        type: ACTION_TYPES.RESET_USER
    };
};

export const createUser = (dataRequest) => {
    const requestUrl = `${API_URL}`;
    return {
        type: ACTION_TYPES.CREATE_USER,
        payload: axios.post(requestUrl, {
            ...dataRequest
        })
    };
};

export const getUser = (id) => {
    const requestUrl = `${API_URL}/${id}`;
    return {
        type: ACTION_TYPES.FETCH_USER,
        payload: axios.get(requestUrl)
    };
};

export const deleteUser = (id) => {
    const requestUrl = `${API_URL}/${id}`;
    return {
        type: ACTION_TYPES.DELETE_USER,
        payload: axios.delete(requestUrl)
    };
};

export const updateUser = (dataRequest) => {
    const requestUrl = `${API_URL}`;
    return {
        type: ACTION_TYPES.UPDATE_USER,
        payload: axios.put(requestUrl, {
            ...dataRequest
        })
    };
};
