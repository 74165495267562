import { v4 as uuidv4 } from 'uuid';

export const spacerPreset = () => {
  return {
    id: uuidv4(),
    type: 'spacer',
    attributes: {},
    properties: {},
    children: []
  }
};
