import React from 'react';
import { RadioIconButtons } from '../ui/radioIconButtons';
import { Bold, Italic, Normal } from '../../icons';
import { useDispatch, useSelector } from 'react-redux';
import { updateNode } from '../../../store/project.reducer';

const FontStyleControlMemo = ({ nodeId }) => {
  const dispatch = useDispatch();
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  const { attributes } = node;

  const handleControl = (value) => {
    const newNode = {
      ...node,
      attributes: {
        ...node.attributes,
        'font-weight': value === 'bold' ? 'bold' : 'normal',
        'font-style': value === 'italic' ? 'italic' : 'normal',
      }
    };
    dispatch(updateNode(newNode));
  };

  return (
    <RadioIconButtons
      options={['normal', 'bold', 'italic']}
      value={attributes['font-weight'] === 'bold' ? 'bold' : attributes['font-style'] === 'italic' ? 'italic' : 'normal'}
      onChange={handleControl}
      icons={{
        'normal': <Normal boxSize="0.6rem" color="black"/>,
        'bold': <Bold boxSize="0.6rem" color="black"/>,
        'italic': <Italic boxSize="0.6rem" color="black"/>
      }}
    />
  );
};

export const FontStyleControl = React.memo(FontStyleControlMemo);