import React from 'react';
import { ToolbarButton } from './toolbarButton';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuDivider
} from '@chakra-ui/react';

import { IoImages, IoPeople, IoFileTrayFull, IoColorWandSharp } from 'react-icons/io5';
import { Burger } from '../icons';
import { useHistory } from 'react-router';
import { MenuList } from '../menu';
import { Private } from '../private';
import { useSelector } from 'react-redux';

export const BurgerMenu = () => {
  const history = useHistory();
  const user = useSelector(state => state.authentication.user);
  const handleClick = (category) => {
    history.push(category)
  }
  return (
    <Menu>
      <MenuButton
        as={ToolbarButton}
        label={<Burger color="white"/>}
      />
      <MenuList
        marginLeft={3}
      >
        <MenuItem icon={<IoFileTrayFull fontSize="0.92rem"/>} onClick={() => handleClick('/projects')}>Мои проекты</MenuItem>
        <MenuItem icon={<IoColorWandSharp fontSize="0.92rem"/>} onClick={() => handleClick('/templates')}>Шаблоны</MenuItem>
        <Private
          role={user.authorities}
          hasAnyRole={['ROLE_ADMIN']}
        >
          <MenuItem icon={<IoPeople fontSize="0.92rem"/>} onClick={() => handleClick('/users')}>Пользователи</MenuItem>
        </Private>
        <MenuItem icon={<IoImages fontSize="0.9rem"/>} onClick={() => handleClick('/images')}>Картинки</MenuItem>
        <MenuDivider
          marginBottom="0.4rem"
          marginTop="0.4rem"
        />
        <MenuItem onClick={() => history.push(`/logout`)}>Выход</MenuItem>
      </MenuList>
    </Menu>
  );
};
