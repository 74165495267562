import React from 'react';
import { Box, Flex, Grid, useRadio, useRadioGroup } from '@chakra-ui/react';

const Item = (props) => {
  const { icons, children } = props;
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box
      as="label"
      width="100%"
    >
      <input {...input} />
      <Flex
        {...checkbox}
        width="1.5rem"
        height="1.5rem"
        cursor="pointer"
        alignItems="center"
        justifyContent="center"
        borderRadius={2}
        _checked={{
          bg: 'gray.100',
          color: 'white',
          borderColor: 'teal.600',
        }}
      >
        {icons[children]}
      </Flex>
    </Box>
  )
}

export const RadioIconButtons = ({ value = '', onChange, options, icons = {} }) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'textAlign',
    value: value !== undefined ? value : '',
    onChange: onChange,
  });

  const group = getRootProps();

  return (
    <Grid
      templateColumns="repeat(3, 1.5rem)"
      borderRadius="0.125rem"
      width="fit-content"
      tabIndex={0}
      outline="none"
      transition="all 0.2s"
      borderColor="transparent"
      borderWidth={1}
      _hover={{
        borderColor: 'gray.300'
      }}
      {...group}
    >
      {options.map((value) => {
        const radio = getRadioProps({ value })
        return (
          <Item key={value} icons={icons} {...radio}>
            {value}
          </Item>
        )
      })}
    </Grid>
  );
};
