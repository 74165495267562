export const ACTION_TYPES = {
    UPDATE_HOVER: 'selected/UPDATE_HOVER',
    SET_LEVEL: 'selected/SET_LEVEL',
    SET_LEVEL_HOVER: 'selected/SET_LEVEL_HOVER',
    UPDATE_PATH: 'selected/UPDATE_PATH',
    UPDATE_TEXT_EDITING: 'selected/UPDATE_TEXT_EDITING',
    RESET_TEXT_EDITING: 'selected/RESET_TEXT_EDITING',
    RESET_TEXT_EDITING_HOVER: 'selected/RESET_TEXT_EDITING_HOVER',
    RESET_HOVER: 'selected/RESET_HOVER',
    RESET: 'selected/RESET'
};

const initialState = {
    hover: '',
    textEditing: '',
    hoverPath: [],
    path: []
};

export const selectedReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.UPDATE_HOVER: {
            return {
                ...state,
                hover: action.nodeId,
                hoverPath: [
                  ...action.nodePath,
                    {
                        id: action.nodeId,
                        type: action.nodeType
                    }
                ]
            }
        }
        case ACTION_TYPES.SET_LEVEL: {
            return {
                ...state,
                path: [...state.hoverPath],
                textEditing: '',
                hoverPath: [],
                hover: ''
            }
        }
        case ACTION_TYPES.SET_LEVEL_HOVER: {
            return {
                ...state,
                path: [...state.hoverPath],
                textEditing: '',
                hover: action.nodeId,
                hoverPath: [
                    ...action.nodePath,
                    {
                        id: action.nodeId,
                        type: action.nodeType
                    }
                ]
            }
        }
        case ACTION_TYPES.UPDATE_TEXT_EDITING: {
            return {
                ...state,
                path: [
                    ...action.nodePath,
                    {
                        id: action.nodeId,
                        type: action.nodeType
                    }
                ],
                hoverPath: [],
                textEditing: action.nodeId,
                hover: ''
            }
        }
        case ACTION_TYPES.UPDATE_PATH: {
            return {
                ...state,
                path: [
                    ...action.nodePath,
                    {
                        id: action.nodeId,
                        type: action.nodeType
                    }
                ],
                hoverPath: [],
                textEditing: '',
                hover: ''
            }
        }
        case ACTION_TYPES.RESET_HOVER: {
            return {
                ...state,
                hover: '',
                hoverPath: []
            }
        }
        case ACTION_TYPES.RESET_TEXT_EDITING: {
            return {
                ...state,
                textEditing: '',
            }
        }
        case ACTION_TYPES.RESET_TEXT_EDITING_HOVER: {
            return {
                ...state,
                hover: action.nodeId,
                hoverPath: [
                    ...action.nodePath,
                    {
                        id: action.nodeId,
                        type: action.nodeType
                    }
                ],
                textEditing: '',
            }
        }
        case ACTION_TYPES.RESET: {
            return {
                ...initialState
            }
        }
        default: return state;
    }
};

export const setLevel = () => {
    return {
        type: ACTION_TYPES.SET_LEVEL,
    }
}

export const setLevelHover = (nodeId, nodeType, nodePath) => {
    const pathSplice = [ ...nodePath ];
    pathSplice.splice(0, 2);
    return {
        type: ACTION_TYPES.SET_LEVEL_HOVER,
        nodePath: pathSplice,
        nodeId,
        nodeType
    }
}

export const updateHover = (nodeId, nodeType, nodePath) => {
    const pathSplice = [ ...nodePath ];
    pathSplice.splice(0, 2);
    return {
        type: ACTION_TYPES.UPDATE_HOVER,
        nodePath: pathSplice,
        nodeId,
        nodeType
    };
};

export const updateTextEditing = (nodeId, nodeType, nodePath) => {
    const pathSplice = [ ...nodePath ];
    pathSplice.splice(0, 2);
    return {
        type: ACTION_TYPES.UPDATE_TEXT_EDITING,
        nodePath: pathSplice,
        nodeId,
        nodeType
    };
};


export const updatePath = (nodeId, nodeType, nodePath) => {
    const pathSplice = [ ...nodePath ];
    pathSplice.splice(0, 2);
    return {
        type: ACTION_TYPES.UPDATE_PATH,
        nodePath: pathSplice,
        nodeId,
        nodeType
    };
};

export const resetTextEditing = () => {
    return {
        type: ACTION_TYPES.RESET_TEXT_EDITING
    };
};

export const resetTextEditingHover = (nodeId, nodeType, nodePath) => {
    const pathSplice = [ ...nodePath ];
    pathSplice.splice(0, 2);
    return {
        type: ACTION_TYPES.RESET_TEXT_EDITING_HOVER,
        nodePath: pathSplice,
        nodeId,
        nodeType
    };
};

export const resetHover = () => {
    return {
        type: ACTION_TYPES.RESET_HOVER
    };
};

export const reset = () => {
    return {
        type: ACTION_TYPES.RESET
    };
};
