import { v4 as uuidv4 } from 'uuid';

export const columnPreset = () => {
  return {
    id: uuidv4(),
    type: 'column',
    attributes: {
      'padding-top': '10px',
      'padding-bottom': '10px'
    },
    properties: {},
    children: []
  }
};
