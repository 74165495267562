import { getNodesByIds } from '../../../utils/dataObj/getNodesByIds';
import { getElementsHtml } from '../element';
import { getAttributes } from '../../../utils/getAttributes';

export const headClassHtml = (data, parentNode, node) => {
  const children = getNodesByIds(data, node.children);
  const { attributes } = node;
  return (`
    <mj-class${getAttributes(attributes)} />
  `);
};
