import { v4 as uuidv4 } from 'uuid';
import { nodeType } from '../models';

export const heroPreset = () => {
  return {
    id: uuidv4(),
    type: nodeType.hero,
    attributes: {
      'mode': 'fluid-height',
      'background-width': '560px',
      'background-height': '340px',
      'background-url': '/images/30bf48d7a4495ea85d30bff9c11a2b36.jpg',
      'padding-left': '20px',
      'padding-right': '20px',
      'padding-top': '20px',
      'padding-bottom': '20px',
      'width': '100%'
    },
    properties: {
      imageFileName: '',
      imageHeight: 340,
      imageWidth: 560,
    },
    children: [
      {
        id: uuidv4(),
        type: nodeType.text,
        attributes: {
          'font-size': '14px',
          'align': 'left',
          'line-height': '24px',
          'color': '#ffffff',
          'font-weight': 'normal',
          'font-style': 'normal'
        },
        content: ['Текст'],
        properties: {},
        children: []
      }
    ]
  }
};
