import { Icon } from '@chakra-ui/react';

export const Bold = (props) => {
  return (
    <Icon viewBox="0 0 8 8" {...props}>
      <g fill="currentColor">
        <path d="M4.42041016,8 C6.07470703,8 7.10058594,7.140625 7.10058594,5.78173828 C7.10058594,4.78271484 6.35400391,4.00927734 5.328125,3.93408203 L5.328125,3.83740234 C6.12304688,3.71923828 6.72998047,3.00488281 6.72998047,2.18310547 C6.72998047,0.985351562 5.83300781,0.249511719 4.32910156,0.249511719 L0.939941406,0.249511719 L0.939941406,8 L4.42041016,8 Z M3.78125,3.46142578 L2.56201172,3.46142578 L2.56201172,1.49023438 L3.91552734,1.49023438 C4.68896484,1.49023438 5.13476562,1.86083984 5.13476562,2.47851562 C5.13476562,3.1015625 4.65673828,3.46142578 3.78125,3.46142578 Z M4.00683594,6.75927734 L2.56201172,6.75927734 L2.56201172,4.55712891 L3.96923828,4.55712891 C4.92529297,4.55712891 5.44628906,4.93310547 5.44628906,5.64208984 C5.44628906,6.3671875 4.94140625,6.75927734 4.00683594,6.75927734 Z" fillRule="nonzero"/>
      </g>
    </Icon>
  );
};
