import { v4 as uuidv4 } from 'uuid';

export const intro1Preset = () => {
  return {
    id: uuidv4(),
    type: 'module',
    attributes: {},
    children: [
      {
        id: uuidv4(),
        type: 'section',
        attributes: {
          'padding-left': '0px',
          'padding-right': '0px',
          'padding-top': '0px',
          'padding-bottom': '0px',
          'background-color': '#ffffff'
        },
        properties: {},
        children: [
          {
            id: uuidv4(),
            type: 'column',
            attributes: {
              'padding': '0px',
              'padding-left': '0px',
              'padding-right': '0px',
              'padding-top': '0px',
              'padding-bottom': '0px',
              'vertical-align': 'middle'
            },
            properties: {},
            children: [
              {
                id: uuidv4(),
                type: 'image',
                attributes: {
                  'padding-left': '0px',
                  'padding-right': '0px',
                  'padding-top': '0px',
                  'padding-bottom': '0px',
                  'align': 'center',
                  'src': '/images/3966bb3feb7520dee354ce547d7a6ec0.jpg'
                },
                properties: {
                  imageFileName: 'image002.jpg',
                  imageHeight: 425,
                  imageWidth: 960
                },
                children: []
              }
            ]
          }
        ]
      }
    ]
  };
};
