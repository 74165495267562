import { Icon } from '@chakra-ui/react';

export const Width = (props) => {
  return (
    <Icon viewBox="0 0 11 8" {...props}>
      <g fill="currentColor">
        <polygon fillRule="nonzero" points="3.50732422 8 5.29052734 1.88232422 5.35498047 1.88232422 7.13818359 8 8.05126953 8 10.1621094 0.249511719 9.15234375 0.249511719 7.61083984 6.54980469 7.54638672 6.54980469 5.81152344 0.249511719 4.83398438 0.249511719 3.09912109 6.54980469 3.03466797 6.54980469 1.49316406 0.249511719 0.483398438 0.249511719 2.59423828 8"/>
      </g>
    </Icon>
  );
};
