import React, { useContext } from 'react';
import { Box } from '@chakra-ui/react';
import { ProjectContext } from '../context/projectContext';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateHover,
  setLevel,
  resetTextEditing,
  updateTextEditing, updatePath, setLevelHover, resetTextEditingHover,
} from '../../store/selected.reducer';
import '../../style.css';

const getColor = (type) => {
  const TYPE_COLOR = {
    module: 'orange.400',
    hero: 'purple.400',
    section: 'purple.400',
    group: 'purple.400',
    column: 'green.400',
    rowCollection: 'pink.400',
    row: 'pink.400'
  }
  if (TYPE_COLOR[type]) {
    return TYPE_COLOR[type]
  } else {
    return 'purple.400'
  }
}

export const SelectedNodeMemo = (props) => {
  const { nodeId, direction, children, ...propsAll } = props;
  const dispatch = useDispatch();
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const hoverNodeId = useSelector(state => state.selected.hover);
  const textEditing = useSelector(state => state.selected.textEditing);
  const pathSelected = useSelector(state => state.selected.path);
  //const levelSelected = pathSelected.length === 0 ? -1 : pathSelected.length - 1;
  //const nodeIdSelectedLevel = levelSelected !== undefined && pathSelected[levelSelected].id;
  const node = projectNodes[nodeId];
  const nodeLevel = node.level - 2;
  const parentNodeId = !isEmpty(node) && node?.path[node.path.length - 1].id;

  const { isReadOnly } = useContext(ProjectContext);

  const setHoverNode = () => {
    if (hoverNodeId !== node.id) {
      dispatch(updateHover(node.id, node.type, node.path))
    }
  }

  const setLevelNode = (hover) => {
    if (hoverNodeId !== '') {
      if (hover) {
        dispatch(setLevelHover(node.id, node.type, node.path));
      } else {
        dispatch(setLevel());
      }
    } else if (textEditing !== '') {
      if (hover) {
        dispatch(resetTextEditingHover(node.id, node.type, node.path));
      } else {
        dispatch(resetTextEditing());
      }
    }
  }

  const handleMouseUp = (e) => {
    if (textEditing !== '') {
      if (pathSelected[nodeLevel - 1] && pathSelected[nodeLevel - 1].id === parentNodeId) {
        e.stopPropagation();
        setLevelNode(true);
      } else if (nodeLevel === 0) {
        e.stopPropagation();
        setLevelNode(true);
      }
    } else {
      if (hoverNodeId === parentNodeId) {
        e.stopPropagation();
        setLevelNode(true);
      } else if (nodeLevel === 0) {
        setLevelNode();
      }
    }
  }

  const handleMouseMove = (e) => {
    if (textEditing === '') {
      if (pathSelected[nodeLevel - 1] && pathSelected[nodeLevel - 1].id === parentNodeId) {
        e.stopPropagation();
        setHoverNode()
      } else if (nodeLevel === 0) {
        setHoverNode()
      }
    }
  }

  const handleMouseDown = (e) => {
    e.stopPropagation();
  }

  const handleDoubleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (node.type === 'text' || node.type === 'button' || node.type === 'raw') {
      if (!isReadOnly) {
        if (textEditing === '') {
          dispatch(updateTextEditing(node.id, node.type, node.path))
        } else {
          dispatch(resetTextEditing());
        }
      }
    } else {
      dispatch(updatePath(node.id, node.type, node.path))
    }
  }

  return (
    <Box
      className="select unselectable"
      onDoubleClick={handleDoubleClick}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      onMouseDown={handleMouseDown}
      position="relative"
      {...propsAll}
      style={{
        direction
      }}
    >
      {!isEmpty(pathSelected) && pathSelected[pathSelected.length - 1].id === node.id  && textEditing === '' && (
        <Box
          pointerEvents="none"
          width="100%"
          height="100%"
          left={0}
          right={0}
          top={0}
          bottom={0}
          position="absolute"
          borderWidth={1}
          borderColor={getColor(node.type)}
          zIndex={2}
        />
      )}
      {hoverNodeId === node.id && (
        <Box
          pointerEvents="none"
          width="100%"
          height="100%"
          left={0}
          right={0}
          top={0}
          bottom={0}
          position="absolute"
          borderWidth={2}
          borderColor={getColor(node.type)}
          zIndex={2}
        />
      )}
      {children}
    </Box>
  );
};

export const SelectedNode = React.memo(SelectedNodeMemo);
