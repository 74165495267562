import React, { useEffect } from 'react';
import { Grid } from '@chakra-ui/react';
import { ToolBar } from '../../components/toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectList, resetProjectList } from '../../store/projectList.reducer';
import { GridItem } from './gridItem';
import { ToolbarButton } from '../../components/toolbar/toolbarButton';
import { AddIcon } from '@chakra-ui/icons';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Loader } from '../../components/loader/loader';
import { createProject } from '../../store/project.reducer';
import { ContentWrapper } from '../../components/layout';
import { getAccess } from '../../utils/getAccess';

export const Templates = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();

  const loading = useSelector(state => state.projectList.loading);
  const user = useSelector(state => state.authentication.user);
  const list = useSelector(state => state.projectList.entities);

  const handleCreateProject = () => {
    const dataRequest = {
      sources: 'SYSTEM',
      userId: user.id,
    }
    const promise = dispatch(createProject(dataRequest));
    promise.then(result => {
      history.push(`${match.url}/system/${result.value.data.id}`);
    });
  };

  useEffect(() => {
    dispatch(getProjectList({ sources: 'SYSTEM' }))
    return () => {
      dispatch(resetProjectList());
    }
  }, []);

  return (
    <>
      <ToolBar
        center="Шаблоны"
        right={
          getAccess(user.authorities, ['ROLE_ADMIN']) && (
            <>
              <ToolbarButton
                label={<AddIcon color="white" boxSize="0.9rem"/>}
                onClick={handleCreateProject}
              />
            </>
          )
        }
      />
      <ContentWrapper>
        <Loader isLoading={loading} message={!list.length && 'Пока ничего нет'}>
          <Grid
            templateColumns={`repeat(auto-fill, minmax(200px, 1fr))`}
            gap={6}
          >
            {list && list.map(item => (
              <GridItem
                key={item.id}
                item={item}
                onClick={() => history.push(`${match.url}/system/${item.id}`)}
              />
            ))}
          </Grid>
        </Loader>
      </ContentWrapper>
    </>
  );
};
