import { Icon } from '@chakra-ui/react';

export const Italic = (props) => {
  return (
    <Icon viewBox="0 0 6 8" {...props}>
      <g fill="currentColor">
        <polygon fillRule="nonzero" points="4.44091797 8 4.5859375 7.15673828 3.01220703 7.15673828 4.09716797 1.09277344 5.67089844 1.09277344 5.80517578 0.249511719 1.72314453 0.249511719 1.58349609 1.09277344 3.15185547 1.09277344 2.06689453 7.15673828 0.498535156 7.15673828 0.358886719 8"/>
      </g>
    </Icon>
  );
};
