import React, { useEffect, useState } from 'react';
import { ToolBar } from '../../components/toolbar';
import { CategoryAdd } from './categoryAdd';
import { Box, Grid } from '@chakra-ui/react';
import { CategoryItem } from './categoryItem';
import { useDispatch, useSelector } from 'react-redux';
import { getImages, resetImages } from '../../store/image.reducer';
import { useHistory, useParams } from 'react-router-dom';
import { DropZone } from '../../components/dropzone';
import { ImagesItem } from './imagesItem';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { ToolbarButton } from '../../components/toolbar/toolbarButton';
import { Loader } from '../../components/loader/loader';

export const ImagesShow = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const loading = useSelector(state => state.image.loading);
  const images = useSelector(state => state.image.images);
  const selectedCategory = useSelector(state => state.image.selectedCategory);
  const [fullSize, setFullSize] = useState(false);

  useEffect(() => {
    dispatch(getImages({
      category: [id]
    }));
    return () => {
      dispatch(resetImages());
    };
  }, [dispatch, id]);

  return (
    <>
      <ToolBar
        left={<ToolbarButton label={<ArrowBackIcon color="white"/>} onClick={() => history.push('/images')}/>}
        center={selectedCategory && selectedCategory.name}
      />
      <Box
        padding="30px 30px"
        height="calc(100vh - 40px)"
      >
        <div
          onDragEnter={() => setFullSize(true)}
          // onDragLeave={() => setFullSize(false)}
        >
          <Loader isLoading={loading}>
            <DropZone
              fullSize={fullSize}
              setFullSize={setFullSize}
              selectedCategory={selectedCategory.category}
            />
            <Grid
              templateColumns={`repeat(auto-fill, minmax(200px, 1fr))`}
              gap={6}
            >
              {images && images.map(item => (
                <ImagesItem key={item.id} item={item}/>
              ))}
            </Grid>
          </Loader>
        </div>
      </Box>
    </>
  );
};
