import React from 'react';
import { StackControl } from '../stackControl';
import { PaddingTopControl } from '../controls/paddingTop.control';
import { PaddingRightControl } from '../controls/paddingRight.control';
import { PaddingBottomControl } from '../controls/paddingBottom.control';
import { PaddingLeftControl } from '../controls/paddingLeft.control';
import { GridControl } from '../gridControl';
import { ColorControl } from '../controls/color.control';
import { HeightControl } from '../controls/height.control';
import { useSelector } from 'react-redux';

const SpacerModuleMemo = ({ nodeId }) => {
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  return (
    <StackControl>
      <GridControl label="Отступы">
        <PaddingLeftControl nodeId={node.id} placeholder="0px"/>
        <PaddingRightControl nodeId={node.id} placeholder="0px"/>
        <PaddingTopControl nodeId={node.id} placeholder="0px"/>
        <PaddingBottomControl nodeId={node.id} placeholder="0px"/>
      </GridControl>
      <GridControl label="Высота">
        <HeightControl nodeId={node.id} placeholder="20px"/>
      </GridControl>
      <GridControl label="Фон">
        <ColorControl nodeId={node.id} attribute="container-background-color"/>
      </GridControl>
    </StackControl>
  );
};

export const SpacerModule = React.memo(SpacerModuleMemo);
