export const getAttributes = (obj, parentObj, properties, preview = true) => {
  return Object.entries(obj).reduce((previousValue, item) => {
    const [property, value] = item;
      if (property === 'href') {
        if (parentObj && parentObj.href) {
          return `${previousValue} ${property}="${parentObj.href}"`
        }
      }
      if (!preview) {
        if (property === 'src') {
          return `${previousValue} ${property}="cid:${properties?.imageFileName}"`
        }
      }
    return `${previousValue} ${property}="${value}"`
  }, '');
};
