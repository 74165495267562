import { Icon } from '@chakra-ui/react';

export const Burger = (props) => {
  return (
    <Icon viewBox="0 0 23 16" {...props}>
      <g fill="currentColor">
        <path d="M23,14 L23,16 L0,16 L0,14 L23,14 Z M23,7 L23,9 L0,9 L0,7 L23,7 Z M23,0 L23,2 L0,2 L0,0 L23,0 Z"/>
      </g>
    </Icon>
  );
};
