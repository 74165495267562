import React from 'react';
import { StackControl } from '../stackControl';
import { GridControl } from '../gridControl';
import { BorderRadiusControl } from '../controls/borderRadius.control';
import { ColorControl } from '../controls/color.control';
import { ImageControl } from '../controls/image.control';
import { LinkControl } from '../controls/link.control';
import { useSelector } from 'react-redux';

export const SocialModuleMemo = ({ nodeId }) => {
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  return (
    <>
      <StackControl>
        <GridControl label="Скругление">
          <BorderRadiusControl nodeId={node.id} placeholder="0px"/>
        </GridControl>
      </StackControl>
      <StackControl>
        <GridControl label="Фон">
          <ColorControl nodeId={node.id} attribute="background-color"/>
        </GridControl>
        <GridControl templateColumns="1fr" label="Картинка">
          <ImageControl nodeId={node.id} attribute="src" />
        </GridControl>
      </StackControl>
      <StackControl>
        <GridControl templateColumns="1fr" label="Ссылка">
          <LinkControl nodeId={node.id}/>
        </GridControl>
      </StackControl>
    </>
  );
};

export const SocialModule = React.memo(SocialModuleMemo);
