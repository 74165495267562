import isEmpty from 'lodash/isEmpty';
import { v4 as uuidv4 } from 'uuid';

const allowTypes = ['container', 'body', 'head'];

export const normalizeObj = (data, startLevel = 0, startPath = [], isNewId) => {
  let collection = {};
  let collectionType = {};
  let firstChildrenNodeId = '';
  const newGenerateId = uuidv4();
  const setItem = (node, levelParent, pathCollection, pathParent, newId) => {
    let level = levelParent + 1;
    const newChildrenIds = node.children.map(() => uuidv4());
    const childrenIds = node.children.map(item => (item.id));
    const id = isNewId ? newId: node.id;
    const path = [
      ...pathCollection,
      ...pathParent
    ]
    if (node.id) {
      if (firstChildrenNodeId === '') {
        firstChildrenNodeId = isNewId ? newId : node.id;
      }
      collection = {
        ...collection,
        [id]: {
          ...node,
          id,
          level: levelParent,
          path,
          children: isNewId ? [...newChildrenIds] : [...childrenIds]
        }
      }
      if (allowTypes.includes(node.type)) {
        collectionType = {
          ...collectionType,
          [node.type]: {
            id: node.id,
            type: node.type
          }
        }
      }
    }
    if (!isEmpty(node.children)) {
      if (isNewId) {
        node.children.forEach((item, index) => {
          setItem(item, level, path, [{
            id: newId,
            type: node.type
          }], newChildrenIds[index])
        })
      } else {
        node.children.forEach(item => {
          setItem(item, level, path, [{
            id: node.id,
            type: node.type
          }])
        })
      }
    } else {
      level = 0;
    }
  }
  setItem(data, startLevel, [], startPath, newGenerateId)
  return [collection, collectionType, firstChildrenNodeId];
}
