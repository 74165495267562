import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Box } from '@chakra-ui/react';
import { Element } from '../element';
import { useSelector } from 'react-redux';

export const TemplateMemo = () => {
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const projectNodesType = useSelector(state => state.project.present.entityNodesType);
  const node = projectNodes[projectNodesType?.container?.id];
  const { children } = node;
  return (
    <Box>
      {!isEmpty(children) && children.map(item => (
        <Element
          key={item}
          parentNodeId={node.id}
          nodeId={item}
        />
      ))}
    </Box>
  );
};

export const Template = React.memo(TemplateMemo);