import React from 'react';
import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import Empty from '../../../images/empty-image.svg';

export const PreviewHtml = ({ tabActive }) => {
  const projectHtmlPreview = useSelector(state => state.html.entityPreview);
  return (
    <Box backgroundImage={`url(${Empty})`}>
      <Box
        transition="0.3s"
        width={tabActive === 'mobile' ? '414px' : '100%'}
        height={'calc(100vh - 40px)'}
        ml="auto"
        mr="auto"
        backgroundColor="white"
      >
        <iframe
          width="100%"
          height="100%"
          title="ks"
          sandbox="allow-same-origin"
          referrerPolicy="no-referrer"
          srcDoc={projectHtmlPreview.html}
        />
      </Box>
    </Box>
  )
}
