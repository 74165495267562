import { v4 as uuidv4 } from 'uuid';

export const content1Preset = () => {
  return {
    id: uuidv4(),
    type: 'module',
    attributes: {},
    children: [
      {
        id: uuidv4(),
        type: 'section',
        attributes: {
          'padding-left': '20px',
          'padding-right': '20px',
          'padding-top': '20px',
          'padding-bottom': '4px',
          'background-color': '#ffffff'
        },
        properties: {},
        children: [
          {
            id: uuidv4(),
            type: 'column',
            attributes: {
              'padding-left': '0px',
              'padding-right': '0px',
              'padding-top': '0px',
              'padding-bottom': '0px',
              'width': '18%',
              'vertical-align': 'middle'
            },
            properties: {},
            children: [
              {
                id: uuidv4(),
                type: 'image',
                attributes: {
                  'padding-left': '0px',
                  'padding-right': '0px',
                  'padding-top': '0px',
                  'padding-bottom': '0px',
                  'width': '60px',
                  'padding': '0',
                  'align': 'center',
                  'src': '/images/79c8180106e6008108410ee1471c5050.png',
                },
                properties: {
                  imageFileName: 'icon@3x.png',
                  imageHeight: 120,
                  imageWidth: 120
                },
                children: []
              }
            ]
          },
          {
            id: uuidv4(),
            type: 'column',
            attributes: {
              'padding-left': '0px',
              'padding-right': '0px',
              'padding-top': '0px',
              'padding-bottom': '0px',
              'width': '82%',
              'vertical-align': 'middle'
            },
            properties: {},
            children: [
              {
                id: uuidv4(),
                type: 'text',
                attributes: {
                  'padding-left': '0px',
                  'padding-right': '0px',
                  'padding-top': '0px',
                  'padding-bottom': '0px',
                  'font-size': '20px',
                  'align': 'left',
                  'line-height': '24px',
                  'color': '#000000',
                  'font-weight': 'bold',
                  'font-style': 'normal'
                },
                content: ['Главные новости'],
                properties: {},
                children: []
              }
            ]
          }
        ]
      },
      {
        id: uuidv4(),
        type: 'section',
        attributes: {
          'padding-left': '20px',
          'padding-right': '20px',
          'padding-top': '0px',
          'padding-bottom': '0px',
          'background-color': '#ffffff'
        },
        properties: {},
        children: [
          {
            id: uuidv4(),
            type: 'column',
            attributes: {
              'padding-left': '10px',
              'padding-right': '10px',
              'padding-top': '10px',
              'padding-bottom': '10px',
              'vertical-align': 'top',
              'width': '18%'
            },
            properties: {},
            children: []
          },
          {
            id: uuidv4(),
            type: 'column',
            attributes: {
              'padding-left': '0px',
              'padding-right': '0px',
              'padding-top': '0px',
              'padding-bottom': '0px',
              'width': '82%',
              'vertical-align': 'middle'
            },
            properties: {},
            children: [
              {
                id: uuidv4(),
                type: 'rowCollection',
                attributes: {},
                properties: {},
                children: [
                  {
                    id: uuidv4(),
                    type: 'row',
                    attributes: {},
                    properties: {},
                    children: [
                      {
                        id: uuidv4(),
                        type: 'text',
                        attributes: {
                          'padding-left': '0px',
                          'padding-right': '0px',
                          'padding-top': '0px',
                          'padding-bottom': '8px',
                          'font-size': '16px',
                          'align': 'left',
                          'line-height': '24px',
                          'color': '#000000',
                          'font-weight': 'bold',
                          'font-style': 'normal'
                        },
                        content: ['Изменения в штрафной политике с 17 января'],
                        properties: {},
                        children: []
                      },
                      {
                        id: uuidv4(),
                        type: 'text',
                        attributes: {
                          'padding-left': '0px',
                          'padding-right': '0px',
                          'padding-top': '0px',
                          'padding-bottom': '0px',
                          'font-size': '14px',
                          'align': 'left',
                          'line-height': '20px',
                          'color': '#000000',
                          'font-weight': 'normal',
                          'font-style': 'normal'
                        },
                        content: ['По Ипотеке (Вторичка, Новостройка, Рефинансирование) [при выходе на просрочку](https://portal.sovcombank.ru/news/nyp51o0350olzeq8) больше не начисляется комиссия - 590 рублей.'],
                        properties: {},
                        children: []
                      },
                      {
                        id: uuidv4(),
                        type: 'spacer',
                        attributes: {
                          'padding-left': '0px',
                          'padding-right': '0px',
                          'padding-top': '0px',
                          'padding-bottom': '0px',
                          'height': '20px'
                        },
                        properties: {},
                        children: []
                      }
                    ]
                  },
                  {
                    id: uuidv4(),
                    type: 'row',
                    attributes: {},
                    properties: {},
                    children: [
                      {
                        id: uuidv4(),
                        type: 'text',
                        attributes: {
                          'padding-left': '0px',
                          'padding-right': '0px',
                          'padding-top': '0px',
                          'padding-bottom': '8px',
                          'font-size': '16px',
                          'align': 'left',
                          'line-height': '24px',
                          'color': '#000000',
                          'font-weight': 'bold',
                          'font-style': 'normal'
                        },
                        content: ['Используй Webex Teams вместо Lync'],
                        properties: {},
                        children: []
                      },
                      {
                        id: uuidv4(),
                        type: 'text',
                        attributes: {
                          'padding-left': '0px',
                          'padding-right': '0px',
                          'padding-top': '0px',
                          'padding-bottom': '0px',
                          'font-size': '14px',
                          'align': 'left',
                          'line-height': '20px',
                          'color': '#000000',
                          'font-weight': 'normal',
                          'font-style': 'normal'
                        },
                        content: ['Мы завершаем переход на Webex Teams и [по графику](https://portal.sovcombank.ru/news/zrmdwj90w2j37v1k) отключаем Lync в Дирекциях и УК.'],
                        properties: {},
                        children: []
                      },
                      {
                        id: uuidv4(),
                        type: 'spacer',
                        attributes: {
                          'padding-left': '0px',
                          'padding-right': '0px',
                          'padding-top': '0px',
                          'padding-bottom': '0px',
                          'height': '20px'
                        },
                        properties: {},
                        children: []
                      }
                    ]
                  }
                ]
              },
              {
                id: uuidv4(),
                type: 'rowCollection',
                attributes: {},
                properties: {},
                children: [
                  {
                    id: uuidv4(),
                    type: 'row',
                    attributes: {},
                    properties: {},
                    children: [
                      {
                        id: uuidv4(),
                        type: 'text',
                        attributes: {
                          'padding-left': '0px',
                          'padding-right': '0px',
                          'padding-top': '0px',
                          'padding-bottom': '8px',
                          'font-size': '16px',
                          'align': 'left',
                          'line-height': '24px',
                          'color': '#000000',
                          'font-weight': 'bold',
                          'font-style': 'normal'
                        },
                        content: ['Изменения в штрафной политике с 17 января'],
                        properties: {},
                        children: []
                      },
                      {
                        id: uuidv4(),
                        type: 'text',
                        attributes: {
                          'padding-left': '0px',
                          'padding-right': '0px',
                          'padding-top': '0px',
                          'padding-bottom': '0px',
                          'font-size': '14px',
                          'align': 'left',
                          'line-height': '20px',
                          'color': '#000000',
                          'font-weight': 'normal',
                          'font-style': 'normal'
                        },
                        content: ['По Ипотеке (Вторичка, Новостройка, Рефинансирование) [при выходе на просрочку](https://portal.sovcombank.ru/news/nyp51o0350olzeq8) больше не начисляется комиссия - 590 рублей.'],
                        properties: {},
                        children: []
                      },
                      {
                        id: uuidv4(),
                        type: 'spacer',
                        attributes: {
                          'padding-left': '0px',
                          'padding-right': '0px',
                          'padding-top': '0px',
                          'padding-bottom': '0px',
                          'height': '20px'
                        },
                        properties: {},
                        children: []
                      }
                    ]
                  },
                  {
                    id: uuidv4(),
                    type: 'row',
                    attributes: {},
                    properties: {},
                    children: [
                      {
                        id: uuidv4(),
                        type: 'text',
                        attributes: {
                          'padding-left': '0px',
                          'padding-right': '0px',
                          'padding-top': '0px',
                          'padding-bottom': '8px',
                          'font-size': '16px',
                          'align': 'left',
                          'line-height': '24px',
                          'color': '#000000',
                          'font-weight': 'bold',
                          'font-style': 'normal'
                        },
                        content: ['Используй Webex Teams вместо Lync'],
                        properties: {},
                        children: []
                      },
                      {
                        id: uuidv4(),
                        type: 'text',
                        attributes: {
                          'padding-left': '0px',
                          'padding-right': '0px',
                          'padding-top': '0px',
                          'padding-bottom': '0px',
                          'font-size': '14px',
                          'align': 'left',
                          'line-height': '20px',
                          'color': '#000000',
                          'font-weight': 'normal',
                          'font-style': 'normal'
                        },
                        content: ['Мы завершаем переход на Webex Teams и [по графику](https://portal.sovcombank.ru/news/zrmdwj90w2j37v1k) отключаем Lync в Дирекциях и УК.'],
                        properties: {},
                        children: []
                      },
                      {
                        id: uuidv4(),
                        type: 'spacer',
                        attributes: {
                          'padding-left': '0px',
                          'padding-right': '0px',
                          'padding-top': '0px',
                          'padding-bottom': '0px',
                          'height': '20px'
                        },
                        properties: {},
                        children: []
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        id: uuidv4(),
        type: 'section',
        attributes: {
          'padding-left': '0px',
          'padding-right': '0px',
          'padding-top': '0px',
          'padding-bottom': '0px',
          'background-color': '#ffffff'
        },
        properties: {},
        children: [
          {
            id: uuidv4(),
            type: 'column',
            attributes: {
              'padding-left': '0px',
              'padding-right': '0px',
              'padding-top': '0px',
              'padding-bottom': '0px',
              'vertical-align': 'top'
            },
            properties: {},
            children: [
              {
                id: uuidv4(),
                type: 'divider',
                attributes: {
                  'padding-left': '20px',
                  'padding-right': '20px',
                  'padding-top': '20px',
                  'padding-bottom': '0px',
                  'border-width': '1px',
                  'border-color': '#E2E8F0'
                },
                properties: {},
                children: []
              },
            ]
          }
        ]
      }
    ]
  };
};
