import { FAILURE, REQUEST, SUCCESS } from './actions-type';
import axios from 'axios';
import {
  addNodeAction, addNodeFromBufferAction,
  copyNodeAction,
  deleteNodeAction,
  downNodeAction,
  updateNodeAction,
  uploadImageAction,
  upNodeAction,
} from './actionUtils';
import { getIdsChildrenNodes } from '../utils/dataObj/getIdsChildrenNodes';
import { getPreset } from '../presets';
import { handleEntityAction } from './actionUtils/handleEntityAction';
import { duplicateEntityAction } from './actionUtils/duplicateEntityAction';
import { normalizeObj } from '../utils/dataObj/normalizeObj';
import { getDuplicateChildrenNodes } from '../utils/dataObj/getDuplicateChildrenNodes';
import { denormalizeObj } from '../utils/dataObj/denormalizeObj';

export const ACTION_TYPES = {
  CREATE_ENTITY: 'project/CREATE_ENTITY',
  FETCH_ENTITY: 'project/FETCH_ENTITY',
  DUPLICATE_ENTITY: 'project/DUPLICATE_ENTITY',
  UPDATE_ENTITY: 'project/UPDATE_ENTITY',
  DELETE_ENTITY: 'project/DELETE_ENTITY',
  RESET_ENTITY: 'project/RESET_ENTITY',
  UPDATE_NODE: 'project/UPDATE_NODE',
  ADD_NODE: 'project/ADD_NODE',
  ADD_NODE_FROM_BUFFER: 'project/ADD_NODE_FROM_BUFFER',
  DELETE_NODE: 'project/DELETE_NODE',
  COPY_NODE: 'project/COPY_NODE',
  UP_NODE: 'project/UP_NODE',
  DOWN_NODE: 'project/DOWN_NODE',
  UPLOAD_IMAGE: 'project/UPLOAD_IMAGE',
}

const initialState = {
  entity: {},
  entityNodes: {},
  entityNodesType: {},
  availableEntity: false,
  loading: false,
  loadingUploadImage: false,
  errorMessage: ''
};

export const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ENTITY):
    case REQUEST(ACTION_TYPES.DUPLICATE_ENTITY):
    case REQUEST(ACTION_TYPES.DELETE_ENTITY):
    case REQUEST(ACTION_TYPES.CREATE_ENTITY): {
      return {
        ...state,
        availableEntity: false,
        loading: true
      }
    }
    case REQUEST(ACTION_TYPES.UPLOAD_IMAGE):{
      return {
        ...state,
        loadingUploadImage: true
      }
    }
    case FAILURE(ACTION_TYPES.FETCH_ENTITY):
    case FAILURE(ACTION_TYPES.DUPLICATE_ENTITY):
    case FAILURE(ACTION_TYPES.DELETE_ENTITY):
    case FAILURE(ACTION_TYPES.CREATE_ENTITY): {
      return {
        ...state,
        availableEntity: false,
        loading: false
      }
    }
    case FAILURE(ACTION_TYPES.UPLOAD_IMAGE):{
      return {
        ...state,
        loadingUploadImage: false
      }
    }
    case SUCCESS(ACTION_TYPES.FETCH_ENTITY):
    case SUCCESS(ACTION_TYPES.CREATE_ENTITY): {
      const entityData = handleEntityAction(state, action);
      return {
        ...state,
        loading: false,
        ...entityData
      }
    }
    case SUCCESS(ACTION_TYPES.DUPLICATE_ENTITY): {
      const entityData = duplicateEntityAction(state, action);
      return {
        ...state,
        loading: false,
        ...entityData
      }
    }
    case SUCCESS(ACTION_TYPES.UPLOAD_IMAGE): {
      const entityNodes = uploadImageAction(state, action);
      return {
        ...state,
        loadingUploadImage: false,
        entityNodes
      }
    }
    case ACTION_TYPES.UPDATE_ENTITY: {
      return {
        ...state,
        entity: {
          ...state.entity,
          ...action.data
        }
      }
    }
    case SUCCESS(ACTION_TYPES.DELETE_ENTITY): {
      return {
        ...state,
        entity: {},
        entityNodes: {},
        entityNodesType: {},
        loading: false,
        availableEntity: false,
      }
    }
    case ACTION_TYPES.UPDATE_NODE: {
     const entityNodes = updateNodeAction(state, action);
      return {
        ...state,
        entityNodes
      }
    }
    case ACTION_TYPES.ADD_NODE: {
      const entityNodes = addNodeAction(state, action);
      return {
        ...state,
        entityNodes
      }
    }
    case ACTION_TYPES.ADD_NODE_FROM_BUFFER: {
      const entityNodes = addNodeFromBufferAction(state, action);
      return {
        ...state,
        entityNodes
      }
    }
    case ACTION_TYPES.COPY_NODE: {
      const entityNodes = copyNodeAction(state, action);
      return {
        ...state,
        entityNodes
      }
    }
    case ACTION_TYPES.DELETE_NODE: {
      const entityNodes = deleteNodeAction(state, action);
      return {
        ...state,
       entityNodes
      }
    }
    case ACTION_TYPES.UP_NODE: {
      const entityNodes = upNodeAction(state, action);
      return {
        ...state,
        entityNodes
      }
    }
    case ACTION_TYPES.DOWN_NODE: {
      const entityNodes = downNodeAction(state, action);
      return {
        ...state,
        entityNodes
      }
    }
    case ACTION_TYPES.RESET_ENTITY: {
      return {
        ...state,
        entity: {},
        entityNodes: {},
        entityNodesType: {},
        loading: false,
        availableEntity: false,
      }
    }
    default:
      return state;
  }
};

const API_URL = `/api`;

export const getProject = (id) => {
  const requestUrl = `${API_URL}/templates/${id}`;
  return {
    type: ACTION_TYPES.FETCH_ENTITY,
    payload: axios.get(requestUrl)
  };
};

export const createProject = (dataRequest) => async dispatch => {
  const requestUrl = `${API_URL}/templates`;
  const dataNewProjectRequest = {
    sources: 'USER',
    name: 'Новый проект',
    generator: 'OWL',
    states: 'IN_WORK',
    minVersion: 1,
    items: [getPreset('project')]
  }
  return await dispatch({
    type: ACTION_TYPES.CREATE_ENTITY,
    payload: axios.post(requestUrl, {
      ...dataNewProjectRequest,
      ...dataRequest
    })
  });
};

export const duplicateProject = ( dataRequest ) => async (dispatch, getState) => {
  const requestUrl = `${API_URL}/templates`;
  const { entity, entityNodes, entityNodesType } = getState().project.present;
  const nodeContainer = entityNodes[entityNodesType?.container?.id];
  return await dispatch({
    type: ACTION_TYPES.DUPLICATE_ENTITY,
    payload: axios.post(requestUrl, {
      ...entity,
      name: `${entity.name} копия`,
      ...dataRequest,
      items: [
        denormalizeObj(entityNodes, nodeContainer)
      ]
    })
  });
}

export const deleteProject = (id) => async (dispatch) => {
  const requestUrl = `${API_URL}/templates/${id}`;
  return await dispatch({
    type: ACTION_TYPES.DELETE_ENTITY,
    payload: axios.delete(requestUrl)
  });
};


export const updateProject = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_ENTITY,
    data
  };
};

export const copyNode = (node, copyChildrenId) => (dispatch, getState) => {
  const { entityNodes } = getState().project.present;
  const [ childrenNodes, firstChildrenNodeId ] = getDuplicateChildrenNodes(entityNodes, copyChildrenId);
  dispatch ({
    type: ACTION_TYPES.COPY_NODE,
    node,
    copyChildrenId,
    childrenNodes,
    firstChildrenNodeId
  });
}

export const deleteNode = (node, childrenNodeId) => (dispatch, getState) => {
  const { entityNodes } = getState().project.present;
  const idsChildrenNodes = getIdsChildrenNodes(entityNodes, childrenNodeId);
  dispatch ({
    type: ACTION_TYPES.DELETE_NODE,
    node,
    childrenNodeId,
    idsChildrenNodes
  });
}


export const updateNode = (node) => {
  return {
    type: ACTION_TYPES.UPDATE_NODE,
    node
  };
}

export const addNode = (node, typePreset) => {
  const preset = getPreset(typePreset);
  const [childrenNodes, _ , startChildrenNodeId] = normalizeObj(preset, node.level + 1, [
    ...node.path,
    { id: node.id, type: node.type }
  ]);
  return {
    type: ACTION_TYPES.ADD_NODE,
    node,
    childrenNodes,
    startChildrenNodeId
  };
}

export const addNodeFromBuffer = (node, childrenNodes, firstChildrenNodeId) => {
  return {
    type: ACTION_TYPES.ADD_NODE_FROM_BUFFER,
    node,
    childrenNodes,
    firstChildrenNodeId
  };
}

export const upNode = (node, childrenNodeId) => {
  return {
    type: ACTION_TYPES.UP_NODE,
    node,
    childrenNodeId
  };
}

export const downNode = (node, childrenNodeId) => {
  return {
    type: ACTION_TYPES.DOWN_NODE,
    node,
    childrenNodeId
  };
}

export const uploadImage = (dataRequest) => async (dispatch) => {
  const requestUrl = `${API_URL}/images`;
  const { category, image, name, file, link } = dataRequest;
  return await dispatch( {
    type: ACTION_TYPES.UPLOAD_IMAGE,
    payload: axios.post(requestUrl, {
      category, image, name, file, link
    })
  });
};


export const resetProject = () => {
  return {
    type: ACTION_TYPES.RESET_ENTITY
  };
};