import { clearObject } from '../../utils/clearObject';

export const updateNodeAction = (state, action) => {
  const entityNodes = { ...state.entityNodes };
  const clearAttributes = clearObject(action.node.attributes);
  entityNodes[action.node.id] = {
    ...action.node,
    attributes: {
      ...clearAttributes
    }
  }
  return entityNodes;
};
