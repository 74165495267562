import React from 'react';
import { LinkIcon } from '@chakra-ui/icons';
import { Input } from '../ui/input';
import { useDispatch, useSelector } from 'react-redux';
import { updateNode } from '../../../store/project.reducer';

const LinkParentControlMemo = ({ nodeId, parent = true, ...props }) => {
  const dispatch = useDispatch();
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  const parentId = node?.path[node.path.length - 1].id;
  const parentNode = projectNodes[parentId];
  const { attributes, properties } = node;

  const handleAttributes = (value) => {
    const newNode = {
      ...node,
      attributes: {
        ...node.attributes,
        href: value
      }
    };
    dispatch(updateNode(newNode));
  };

  const handleProperties = (value) => {
    const newNode = {
      ...node,
      properties: {
        ...node.properties,
        href: value
      }
    };
    dispatch(updateNode(newNode));
  };

  if (parent) {
    return (
      <Input
        placeholder="Нет ссылки"
        value={properties.href || ''}
        leftElement={<LinkIcon color="gray.300" boxSize="0.8rem"/>}
        onBlur={handleProperties}
        {...props}
      />
    );
  } else {
    return (
      <Input
        placeholder={parentNode?.properties?.href ? parentNode.properties.href : 'Нет ссылки'}
        value={attributes?.href ? attributes.href : ''}
        leftElement={<LinkIcon color="gray.300" boxSize="0.8rem"/>}
        onBlur={handleAttributes}
        {...props}
      />
    );
  }
};

export const LinkParentControl = React.memo(LinkParentControlMemo);