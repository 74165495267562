import React from 'react';
import { Flex, Spinner, Box } from '@chakra-ui/react';

export const LoaderMemo = ({ isLoading = false, message, children }) => {
  if (isLoading) {
    return (
      <Flex height="100%" align="center" justify="center" padding="30px">
        <Spinner color="blue.500" />
      </Flex>
    )
  } else {
    if (message) {
      return (
        <Flex height="100%" align="center" justify="center">
          <Box>
            {message}
          </Box>
        </Flex>
      )
    } else {
      return <>{children}</>
    }
  }
};

export const Loader = React.memo(LoaderMemo);
