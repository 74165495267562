import { getAttributes } from '../../../utils/getAttributes';
import { getAttributesFromNode } from '../../../utils/dataObj/getAttributesFromNode';
import { parseMd } from '../../../utils/parseMd';

export const buttonHtml = (data, parentNode, node, preview) => {
  const { attributes, content } = node;
  const text = content.length && content.join('\n');
  const html = parseMd(text);
  return (`
    <mj-button${getAttributesFromNode(node, parentNode, preview)}>${text}</mj-button>
  `)
};
