import React from 'react';
import { StackControl } from '../stackControl';
import { GridControl } from '../gridControl';
import { PaddingTopControl } from '../controls/paddingTop.control';
import { PaddingRightControl } from '../controls/paddingRight.control';
import { PaddingBottomControl } from '../controls/paddingBottom.control';
import { PaddingLeftControl } from '../controls/paddingLeft.control';
import { BorderRadiusControl } from '../controls/borderRadius.control';
import { ColorControl } from '../controls/color.control';
import { TextAlignControl } from '../controls/textAlign.control';
import { SizeControl } from '../controls/size.control';
import { PaddingInner } from '../controls/paddingInner.control';
import { PaddingOuter } from '../controls/paddingOuter.control';
import { useSelector } from 'react-redux';

const SocialCollectionModuleMemo = ({ nodeId }) => {
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  return (
    <>
      <StackControl>
        <GridControl label="Отступы">
          <PaddingLeftControl nodeId={node.id} placeholder="25px"/>
          <PaddingRightControl nodeId={node.id} placeholder="25px"/>
          <PaddingTopControl nodeId={node.id} placeholder="10px"/>
          <PaddingBottomControl nodeId={node.id} placeholder="10px"/>
        </GridControl>
      </StackControl>
      <StackControl>
        <GridControl label="Размер и скругление">
          <SizeControl nodeId={node.id} attribute="icon-size" placeholder="20px"/>
          <BorderRadiusControl nodeId={node.id} placeholder="3px"/>
        </GridControl>
        <GridControl label="Отступы внутри и снаружи">
          <PaddingInner nodeId={node.id} attribute="inner-padding"/>
          <PaddingOuter nodeId={node.id} attribute="icon-padding"/>
        </GridControl>
      </StackControl>
      <StackControl>
        <GridControl label="Выравнивание">
          <TextAlignControl nodeId={node.id} attribute="align"/>
        </GridControl>
      </StackControl>
      <StackControl>
        <GridControl label="Фон">
          <ColorControl nodeId={node.id} attribute="container-background-color" />
        </GridControl>
      </StackControl>
    </>
  );
};

export const SocialCollectionModule = React.memo(SocialCollectionModuleMemo);
