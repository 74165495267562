import { v4 as uuidv4 } from 'uuid';

export const projectPreset = () => {
  return {
    id: uuidv4(),
    type: 'container',
    attributes: {},
    properties: {},
    children: [
      {
        id: uuidv4(),
        type: 'head',
        attributes: {},
        children: [
          {
            id: uuidv4(),
            type: 'headStyle',
            attributes: {
              'color': '#4299E1',
              'text-decoration': 'none !important'
            },
            children: [],
            properties: {
              className: 'link'
            },
          },
          {
            id: uuidv4(),
            type: 'headAttributes',
            attributes: {},
            children: [
              {
                id: uuidv4(),
                type: 'headClass',
                attributes: {
                  'name': 'link',
                  'color': `#4299E1`
                },
                children: []
              },
              {
                id: uuidv4(),
                type: 'headAll',
                attributes: {
                  'font-family': `'Helvetica Neue', Helvetica, Arial, sans-serif`
                },
                children: []
              },
              {
                id: uuidv4(),
                type: 'headAll',
                attributes: {
                  'font-family': `'Helvetica Neue', Helvetica, Arial, sans-serif`
                },
                children: []
              },
              {
                id: uuidv4(),
                type: 'headText',
                attributes: {
                  'font-weight': '400',
                  'font-size': '16px',
                  'color': '#000000',
                  'line-height': '24px',
                  'font-family': `'Helvetica Neue', Helvetica, Arial, sans-serif`
                },
                children: []
              }
            ]
          }
        ]
      },
      {
        id: uuidv4(),
        type: 'body',
        attributes: {
          'background-color': '#F7FAFC'
        },
        children: [],
        properties: {}
      }
    ]
  }
}