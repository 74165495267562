import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Element } from '../element';
import { useSelector } from 'react-redux';

const BodyMemo = ({ parentNodeId, nodeId }) => {
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  const { children } = node;
  return (
    <>
      {!isEmpty(children) && children.map(item => (
        <Element
          key={item}
          parentNodeId={node.id}
          nodeId={item}
        />
      ))}
    </>
  );
};

export const Body = React.memo(BodyMemo);
