import React from 'react';
import { IconButton, Portal, Menu, MenuButton } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { MenuList } from '../../menu';
import { ItemButton } from './itemButton';

export const ItemMenu = ({ children, ...props}) => {
  return (
    <Menu
      placement="bottom-end"
      {...props}
    >
      <MenuButton
        as={ItemButton}
        icon={<ChevronDownIcon/>}
      />
      <Portal>
        <MenuList marginLeft={3}  zIndex={4}>
          {children}
        </MenuList>
      </Portal>
    </Menu>
  )
}