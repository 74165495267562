import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { SelectedNode } from '../../selectedNode';
import { getAttribute } from '../../../utils/getAttribute';
import { useSelector } from 'react-redux';

const DividerMemo = ({ parentNodeId, nodeId }) => {
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  const { attributes } = node;
  return (
    <SelectedNode nodeId={node.id}>
      <Flex
        justifyContent="center"
        width="100%"
        textAlign={getAttribute(attributes, 'align', 'center')}
        backgroundColor={getAttribute(attributes, 'container-background-color')}
        paddingTop={getAttribute(attributes, 'padding-top', '10px')}
        paddingRight={getAttribute(attributes, 'padding-right', '25px')}
        paddingBottom={getAttribute(attributes, 'padding-bottom', '10px')}
        paddingLeft={getAttribute(attributes, 'padding-left', '25px')}
      >
        <Box
          display="inline-block"
          width={getAttribute(attributes, 'width', '100%')}
          backgroundColor={getAttribute(attributes, 'border-color', '#000000')}
          height={getAttribute(attributes, 'border-width', '4px')}
        />
      </Flex>
    </SelectedNode>
  );
};

export const Divider = React.memo(DividerMemo);
