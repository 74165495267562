import React, { useMemo } from 'react';
import { Box, Grid, Image, Icon, Text, Badge } from '@chakra-ui/react';
import { Container, ContentWrapper } from '../../../components/layout';
import { Html } from '../../../components/icons';
import { downloadFile } from '../../../utils/downloadFile';
import { getImagesFromNodes } from '../../../utils/dataObj/getImagesFromNodes';
import { useSelector } from 'react-redux';
import download from 'downloadjs';
import uniqBy from 'lodash/uniqBy';

const GridItem = ({ image, icon, name, subName, colorScheme = 'purple', ...props }) => {
  return (
    <Grid
      templateColumns="40px 1fr 1fr"
      alignItems="center"
      role="group"
      borderBottomWidth="1px"
      transition="0.2s"
      gap="12px"
      cursor="pointer"
      height="58px"
      fontSize="0.9rem"
      pl="8px"
      pr="6px"
      _hover={{
        backgroundColor: 'gray.50'
      }}
      _focus={{
        outline: 'none',
      }}
      _active={{
        backgroundColor: 'gray.50',
        boxShadow: '0 0 0 3px rgba(66,153,225,0.6)',
        borderRadius: '4px'
      }}
      {...props}
    >
      {icon ? <Icon as={icon} boxSize="38px"/> : <Image src={image}/>}
      <Box>
        {name}
      </Box>
      {subName && (
        <Box textAlign="right">
          <Badge ml={2} flex="none" fontSize="0.6rem" colorScheme={colorScheme}>{subName}</Badge>
        </Box>
      )}
    </Grid>
  )
}

export const PreviewExport = () => {
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const projectNodesType = useSelector(state => state.project.present.entityNodesType);
  const nodeContainer = projectNodes[projectNodesType?.container?.id];
  //const isEmbeddedImageAttribute = nodeContainer?.properties?.embeddedImage;
  const projectHtmlPreview = useSelector(state => state.html.entityPreview);
  const projectHtml = useSelector(state => state.html.entity);
  const images = useMemo(() => uniqBy(getImagesFromNodes(projectNodes), 'name'), [projectNodes]);
  return (
    <ContentWrapper>
      <Container maxWidth="600px">
        <Box mb="20px">
          <Text fontSize="1.3rem" fontWeight="semibold" mb="10px">Экспорт ресурсов</Text>
          <Text fontSize="0.9rem">
            Две версии письма, одна с внешними ссылками на картинки, другая с внутренними.
            Для внутренней версии скачайте все картинки и разместите их рядом с письмом.
          </Text>
        </Box>
        <Box borderTopWidth={1} pb="80px">
          <GridItem
            icon={Html}
            name="mail-external-image.html"
            subName="внешние картинки"
            colorScheme="green"
            onClick={() => downloadFile(projectHtmlPreview.html, 'mail-external-image')}
          />
          <GridItem
            icon={Html}
            name="mail.html"
            subName="встроенные картинки"
            onClick={() => downloadFile(projectHtml.html, 'mail')}
          />
          {images.map(item => (
            <GridItem key={item.name} image={item.url} name={item.name} onClick={() => download(item.url)}/>
          ))}
        </Box>
      </Container>
    </ContentWrapper>
  )
}
