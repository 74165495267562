import React from 'react';
import { Box } from '@chakra-ui/react';
import { Element } from '../element';
import { SelectedNode } from '../../selectedNode';
import { getAttribute } from '../../../utils/getAttribute';
import { useSelector } from 'react-redux';

export const ModuleMemo = ({ parentNodeId, nodeId }) => {
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  const { attributes, children } = node;
  if (node?.properties?.wrapper) {
    return (
      <SelectedNode nodeId={node.id}>
        <Box
          className="module"
          backgroundColor={getAttribute(attributes, 'background-color')}
          backgroundRepeat={getAttribute(attributes, 'background-repeat', 'repeat')}
          backgroundSize={getAttribute(attributes, 'background-size', 'auto')}
          backgroundImage={getAttribute(attributes, 'background-url')}
          border={getAttribute(attributes, 'border')}
          borderRadius={getAttribute(attributes, 'border-radius')}
          paddingTop={getAttribute(attributes, 'padding-top', '20px')}
          paddingRight={getAttribute(attributes, 'padding-right')}
          paddingBottom={getAttribute(attributes, 'padding-bottom', '20px')}
          paddingLeft={getAttribute(attributes, 'padding-left')}
          textAlign={getAttribute(attributes, 'text-align', 'center')}
        >
        {children.map(item => (
          <Element
            key={item}
            parentNodeId={node.id}
            nodeId={item}
          />
        ))}
        </Box>
      </SelectedNode>
    )
  } else {
    return (
      <SelectedNode nodeId={node.id}>
        {children.map(item => (
          <Element
            key={item}
            parentNodeId={node.id}
            nodeId={item}
          />
        ))}
      </SelectedNode>
    )
  }
};

export const Module = React.memo(ModuleMemo);
