import { getNodesByIds } from '../../../utils/dataObj/getNodesByIds';
import { getElementsHtml } from '../elementHtml';

export const headHtml = (data, parentNode, node, preview) => {
  const children = getNodesByIds(data, node.children);
  return (`
    <mj-head>
      <mj-raw>
        <meta name="color-scheme" content="light">
      </mj-raw>
      ${children.map(item => (
        getElementsHtml(data, node, item, preview)
  )).join('')}
    </mj-head>
  `);
};