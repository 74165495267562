export const getPaddingValueFromCortege = (arr = []) => {
  const [pt, pr, pb, pl] = arr;
  if (pt !== '' || pr !== '' || pb !== '' || pl !== '') {
    if ((pt === '0px' || pt === '') && (pr === '0px' || pr === '') && (pb === '0px' || pb ===  '') && (pl === '0px' || pl === '')) {
      return ``
    } else {
      return `${pt === '' ? '0px' : pt} ${pr === '' ? '0px' : pr} ${pb === '' ? '0px' : pb} ${pl === '' ? '0px' : pl}`
    }
  } else {
    return '';
  }
}
