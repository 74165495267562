import React from 'react';
import { Grid } from '@chakra-ui/react';
import { HeadingControl } from './headingControl';
export const GridControl = ({ label, children, ...props }) => {
  return (
    <>
      {label && (
        <HeadingControl>{label}</HeadingControl>
      )}
      <Grid templateColumns="repeat(2, 1fr)" gap="10px" {...props}>
        {children}
      </Grid>
    </>
  );
};
