import { modulePreset } from './module.preset';
import { columnPreset } from './column.preset';
import { sectionPreset } from './section.preset';
import { textPreset } from './text.preset';
import { imagePreset } from './image.preset';
import { rowCollectionPreset } from './rowCollection.preset';
import { dividerPreset } from './divider.preset';
import { spacerPreset } from './spacer.preset';
import { socialCollectionPreset } from './socialCollection.preset';
import { rowPreset } from './row.preset';
import { header1Preset } from './templates/header1.preset';
import { intro1Preset } from './templates/intro1.preset';
import { content1Preset } from './templates/content1.preset';
import { content2Preset } from './templates/content2.preset';
import { footer1Preset } from './templates/footer1.preset';
import { projectPreset } from './project.preset';
import { buttonPreset } from './button.preset';
import { heroPreset } from './hero.preset';
import { groupPreset } from './group.preset';
import { nodeType } from '../models';
import { rawPreset } from './raw.preset';
import { socialPreset } from './social.preset';

export const getPreset = (type) => {
  switch (type) {
    case 'project': return projectPreset();
    case nodeType.raw: return rawPreset();
    case nodeType.module: return modulePreset();
    case nodeType.section: return sectionPreset();
    case nodeType.group: return groupPreset();
    case nodeType.column: return columnPreset();
    case nodeType.text: return textPreset();
    case nodeType.image: return imagePreset();
    case nodeType.divider: return dividerPreset();
    case nodeType.spacer: return spacerPreset();
    case nodeType.rowCollection: return rowCollectionPreset();
    case nodeType.row : return rowPreset();
    case nodeType.socialCollection: return socialCollectionPreset();
    case nodeType.social: return socialPreset();
    case nodeType.button : return buttonPreset();
    case nodeType.hero : return heroPreset();
    case 'header1': return header1Preset();
    case 'intro1': return intro1Preset();
    case 'content1': return content1Preset();
    case 'content2': return content2Preset();
    case 'footer1': return footer1Preset();
    default: {}
  }
}