import React from 'react';
import { Box } from '@chakra-ui/react';

export const CustomizerPanel = ({ children, ...props }) => {
  const ref = React.useRef(null)
  // const mouse = useMouse();
  return (
    <Box
      ref={ref}
      width="240px"
      height="100%"
      //position="absolute"
      right={0}
      zIndex={2}
      top={0}
      borderLeftWidth={1}
      backgroundColor="white"
      // pt="40px"
      {...props}
    >
      {children}
    </Box>
  );
};
