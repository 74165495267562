import React from 'react';
import { nodeType } from '../../../models';
import { IconButton, Menu, MenuButton, MenuItem } from '@chakra-ui/react';
import { MenuList } from '../../menu';
import { AddIcon } from '@chakra-ui/icons';
//import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { addNode } from '../../../store/project.reducer';

const allowedTypesForMenu = [nodeType.body, nodeType.module, nodeType.section, nodeType.column, nodeType.row];

export const AddNodeButtonMemo = ({ nodeId, ...props }) => {
  const dispatch = useDispatch();
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  //const projectNodesType = useSelector(state => state.project.present.entityNodesType);
  const node = projectNodes[nodeId];
  //const nodeBody = projectNodes[projectNodesType?.body?.id];
  const handleAdd = (e, type) => {
    e.stopPropagation();
    if (type) {
      dispatch(addNode(node, type))
    } else {
      if (node.type === nodeType.body) {
        dispatch(addNode(node, nodeType.module))
      } else if (node.type === nodeType.module) {
        dispatch(addNode(node, nodeType.section))
      } else if (node.type === nodeType.group) {
        dispatch(addNode(node, nodeType.column))
      } else if (node.type === nodeType.rowCollection) {
        dispatch(addNode(node, nodeType.row))
      } else if (node.type === nodeType.socialCollection) {
        dispatch(addNode(node, nodeType.social))
      }
    }
  }
  return (
    <>
      {node && (allowedTypesForMenu.includes(node.type) )? (
        <Menu placement="bottom-end">
          <MenuButton
            as={IconButton}
            height="1.2rem"
            minWidth="1.2rem"
            fontSize="0.52rem"
            aria-label="Add"
            variant="solid"
            colorScheme="gray"
            isRound
            icon={<AddIcon />}
            onClick={e => e.stopPropagation()}
            {...props}
          />
          <MenuList minWidth="207px" zIndex={4}>
            {node.type === nodeType.body && (
              <>
                <MenuItem onClick={e => handleAdd(e, nodeType.module)}>Модуль</MenuItem>
                <MenuItem onClick={e => handleAdd(e, nodeType.raw)}>Произвольный код</MenuItem>
              </>
            )}
            {node.type === nodeType.module && (
              <>
                <MenuItem onClick={e => handleAdd(e, nodeType.section)}>Секция</MenuItem>
                <MenuItem isDisabled onClick={e => handleAdd(e, nodeType.hero)}>Промо секция</MenuItem>
              </>
            )}
            {node.type === nodeType.section && (
              <>
                <MenuItem onClick={e => handleAdd(e, nodeType.column)}>Колонка</MenuItem>
                <MenuItem onClick={e => handleAdd(e, nodeType.group)}>Группа колонок</MenuItem>
              </>
            )}
            {node.type === nodeType.column && (
              <>
                <MenuItem onClick={e => handleAdd(e, nodeType.text)}>Текст</MenuItem>
                <MenuItem onClick={e => handleAdd(e, nodeType.image)}>Картинка</MenuItem>
                <MenuItem onClick={e => handleAdd(e, nodeType.divider)}>Разделитель</MenuItem>
                <MenuItem onClick={e => handleAdd(e, nodeType.spacer)}>Пробел</MenuItem>
                <MenuItem onClick={e => handleAdd(e, nodeType.rowCollection)}>Коллекция</MenuItem>
                <MenuItem onClick={e => handleAdd(e, nodeType.socialCollection)}>Социальные сети</MenuItem>
                <MenuItem onClick={e => handleAdd(e, nodeType.button)}>Кнопка</MenuItem>
              </>
            )}
            {node.type === nodeType.row && (
              <>
                <MenuItem onClick={e => handleAdd(e, nodeType.text)}>Текст</MenuItem>
                <MenuItem onClick={e => handleAdd(e, nodeType.image)}>Картинка</MenuItem>
                <MenuItem onClick={e => handleAdd(e, nodeType.divider)}>Разделитель</MenuItem>
                <MenuItem onClick={e => handleAdd(e, nodeType.spacer)}>Пробел</MenuItem>
                <MenuItem onClick={e => handleAdd(e, nodeType.socialCollection)}>Социальные сети</MenuItem>
                <MenuItem onClick={e => handleAdd(e, nodeType.button)}>Кнопка</MenuItem>
              </>
            )}
          </MenuList>
        </Menu>
      ) : (
        <IconButton
          height="1.2rem"
          minWidth="1.2rem"
          fontSize="0.52rem"
          aria-label="Add"
          variant="solid"
          colorScheme="gray"
          isRound
          onClick={e => handleAdd(e)}
          icon={<AddIcon />}
          {...props}
        />
      )}
    </>
  )
};

export const AddNodeButton = React.memo(AddNodeButtonMemo);
