import React from 'react';
import { getAccess } from '../../utils/getAccess';

export const Private = ({ role, hasAnyRole, children }) => {
  const access = getAccess(role, hasAnyRole);
  return (
    <>
      {access ? children : null}
    </>
  )
};
