import React, { useEffect, useRef } from 'react';
import {
  Input,
  Box,
  Image,
  Heading,
  Text,
  Flex,
  VStack,
  Button
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import logo from './logo.svg';
import { loginAuth } from '../../store/authentication.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@chakra-ui/react';

export const Login = () => {
  const dispatch = useDispatch();
  const firstInputRef = useRef();
  const loading = useSelector(state => state.authentication.loading);
  const errorMessage = useSelector(state => state.authentication.errorMessage);
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      login: '',
      password: ''
    }
  });

  const onSubmit = async (data) => {
    await dispatch(loginAuth(data));
  };

  useEffect(() => {
    reset({
      login: '',
      password: ''
    });
    firstInputRef.current.focus();
  }, [errorMessage]);

  return (
    <Box
      width="100%"
      height="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      backgroundColor="gray.50"
    >
      <Box
        width="370px"
        backgroundColor="#ffffff"
        borderWidth={1}
        p="40px"
        borderRadius={8}
      >
        <Box>
          <Flex flexDirection="column" alignItems="center" textAlign="center" mb="20px">
            <Image src={logo} mb={4}/>
            <Heading as="h3" size="md" mb={2}>
              Конструктор писем
            </Heading>
            <Text mb={3}>
              Создавайте красивые письма,<br/> легко и быстро.
            </Text>
          </Flex>
          {errorMessage && (
            <Alert mb={4} status="error" borderRadius={6} textAlign="center">
              Ошибка авторизации
            </Alert>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack spacing="14px">
              <Input
                name="login"
                placeholder="Логин"
                ref={(e) => {
                  register(e, { required: true });
                  firstInputRef.current = e;
                }}
              />
              <Input
                type="password"
                name="password"
                placeholder="Пароль"
                ref={register({ required: true })}
              />
              <Box pt={3} width="100%">
                <Button
                  type="submit"
                  colorScheme="blue"
                  width="100%"
                  isLoading={loading}
                >
                  Войти
                </Button>
              </Box>
            </VStack>
          </form>
        </Box>
      </Box>
    </Box>
  );
};
