import React from 'react';
import { Box } from '@chakra-ui/react';

export const Heading = ({ children }) => {
  return (
      <Box
        fontSize="xs"
        fontWeight="semibold"
        mb={2}
      >
        {children}
      </Box>
  );
};
