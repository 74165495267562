import React, { useCallback, useEffect, useMemo } from 'react';
import { Flex, HStack, MenuItem, Icon, MenuDivider, useClipboard, useToast } from '@chakra-ui/react';
import { ArrowUpIcon, ArrowDownIcon } from '@chakra-ui/icons';
import { ItemButton } from './itemButton';
import { ItemMenu } from './itemMenu';
import { BiTrash, BiShow, BiCopy } from 'react-icons/bi';
import isEmpty from 'lodash/isEmpty';
import { GlobalHotKeys } from 'react-hotkeys';
import { nodeType } from '../../../models';
import { deleteNode, updateNode, downNode, upNode, copyNode, addNodeFromBuffer } from '../../../store/project.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { denormalizeObj } from '../../../utils/dataObj/denormalizeObj';
import { useLocalStorage } from '../../hook/useLocalStorage';
import { normalizeObj } from '../../../utils/dataObj/normalizeObj';
import { reset } from '../../../store/selected.reducer';

export const ToolbarCustomizerMemo = ({ nodeId }) => {
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const [__, setNodeBuffer, getNodeBuffer] = useLocalStorage('nodeBuffer', {});
  const [_, setPropertiesBuffer, getPropertiesBuffer] = useLocalStorage('propertiesBuffer', {});
  const toast = useToast();
  const dispatch = useDispatch();
  const node = projectNodes[nodeId];
  const { properties, attributes } = node;
  const parentId = !isEmpty(node) && node?.path[node.path.length - 1].id;
  const parentNode = projectNodes[parentId];
  const JSONNode = useMemo(() => JSON.stringify(denormalizeObj(projectNodes, node, true), null, 2), [node]);
  const isNodeBody = node.type === nodeType.body;

  const { hasCopied, onCopy } = useClipboard(JSONNode);

  useEffect(() => {
    if (hasCopied) {
      toast({
        title: 'Готово',
        description: 'Код блока скопирован в буфер обмена',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
    }
  }, [hasCopied]);

  const handleUpClick = useCallback(() => {
    dispatch(upNode(parentNode, node.id))
  }, [parentNode, node.id])

  const handleDownClick = useCallback(() => {
    dispatch(downNode(parentNode, node.id))
  }, [parentNode, node.id]);

  const handleCopyClick = useCallback( () => {
    dispatch(copyNode(parentNode, node.id));
  }, [parentNode, node.id]);

  const handleDeleteClick = useCallback( () => {
    dispatch(reset());
    dispatch(deleteNode(parentNode, node.id));
  }, [parentNode, node.id]);

  const handleCopyInBuffer = useCallback( () => {
    setPropertiesBuffer({
        nodeType: node.type,
        attributes,
        properties
      })
  }, [node.type, attributes, properties]);

  const handlePastInBuffer = () => {
    const propertiesBuffer = getPropertiesBuffer();
    if (propertiesBuffer.nodeType === node.type) {
      const newNode = {
        ...node,
        attributes: {
          ...node.attributes,
          ...propertiesBuffer.attributes
        },
        properties: {
          ...node.properties,
          ...propertiesBuffer.properties
        }
      };
      dispatch(updateNode(newNode));
    }
  }

  const handleCopyInBufferNode = useCallback( () => {
    const dataSelectNode = denormalizeObj(projectNodes, node, true);
    setNodeBuffer({...dataSelectNode})
  }, [projectNodes, node]);

  const handleCutInBufferNode = useCallback( () => {
    const dataSelectNode = denormalizeObj(projectNodes, node, true);
    if (!isNodeBody) {
      setNodeBuffer({...dataSelectNode});
      dispatch(reset());
      dispatch(deleteNode(parentNode, node.id));
    }
  }, [node, projectNodes]);

  const handlePastInBufferNode = useCallback(() => {

    const nodeBuffer = getNodeBuffer();

    const addNode = () => {
      const [ childrenNodes, _ , firstChildrenNodeId] = normalizeObj(nodeBuffer, node.level + 1, [
        ...node.path,
        { id: node.id, type: node.type }
      ], true);
      dispatch(addNodeFromBuffer(node, childrenNodes, firstChildrenNodeId))
    }

    const addParentNode = () => {
      const [ childrenNodes, _ , firstChildrenNodeId ] = normalizeObj(nodeBuffer, node.level, node.path, true);
      dispatch(addNodeFromBuffer(parentNode, childrenNodes, firstChildrenNodeId))
    }

    if (!isEmpty(nodeBuffer)) {
      if (node.type === nodeBuffer?.type) {
        addParentNode();
      } else {
        if (nodeBuffer.type === nodeType.module && node.type === nodeType.body ) {
          addNode();
        } else if (nodeBuffer.type === nodeType.section && node.type === nodeType.module ) {
          addNode();
        } else if (nodeBuffer.type === nodeType.group && node.type === nodeType.section ) {
          addNode();
        } else if (nodeBuffer.type === nodeType.column && (node.type === nodeType.section || node.type === nodeType.group) ) {
          addNode();
        } else if (nodeBuffer.type === nodeType.rowCollection && node.type === nodeType.column ) {
          addNode();
        } else if (nodeBuffer.type === nodeType.row && node.type === nodeType.rowCollection ) {
          addNode();
        } else if (nodeBuffer.type === nodeType.socialCollection && (node.type === nodeType.column || node.type === nodeType.row) ) {
          addNode();
        } else if (nodeBuffer.type === nodeType.social && node.type === nodeType.socialCollection ) {
          addNode();
        } else if (node.type === nodeType.row || node.type === nodeType.column) {
          addNode();
        }  else if (parentNode.type === nodeType.row || parentNode.type === nodeType.column) {
          addParentNode();
        }
      }
    }
  }, [node, parentNode]);

  const handleHidden = useCallback(() => {
    const newNode = {
      ...node,
      properties: {
        ...node.properties,
        hidden: !properties?.hidden
      }
    };
    dispatch(updateNode(newNode));
  }, [node, properties?.hidden]);

  const keyMap = {
    DELETE: ['del', 'backspace'],
    UP: 'up',
    DOWN: 'down',
    COPY_NODE: ['command+c', 'control+c'],
    CUT_NODE: ['command+x', 'control+x'],
    PAST_NODE: ['command+v', 'control+v']
  };

  const handlers = {
    DELETE: handleDeleteClick,
    UP: handleUpClick,
    DOWN: handleDownClick,
    COPY_NODE: handleCopyInBufferNode,
    PAST_NODE: handlePastInBufferNode,
    CUT_NODE: handleCutInBufferNode
  };

  return (
    <GlobalHotKeys handlers={handlers} keyMap={keyMap} allowChanges>
      <Flex
        height="37px"
        paddingLeft="1rem"
        paddingRight="1rem"
        alignItems="center"
        justifyContent="center"
        borderTopWidth={1}
      >
        <HStack spacing={2}>
          <ItemButton isDisabled={isNodeBody} icon={<ArrowUpIcon color="black"/>} onClick={handleUpClick}/>
          <ItemButton isDisabled={isNodeBody} icon={<ArrowDownIcon color="black"/>} onClick={handleDownClick}/>
          <ItemButton isDisabled={isNodeBody} icon={<Icon as={BiCopy} color="black"/>} onClick={handleCopyClick}/>
          <ItemButton isDisabled={isNodeBody} icon={<Icon as={BiShow} color={node?.properties?.hidden ? 'red.500' : 'black'}/>} onClick={handleHidden}/>
          <ItemButton isDisabled={isNodeBody} icon={<Icon as={BiTrash} color="black"/>} onClick={handleDeleteClick}/>
          <ItemMenu>
            <MenuItem onClick={handleCopyInBufferNode}>Копировать блок</MenuItem>
            <MenuItem onClick={handleCutInBufferNode}>Вырезать блок</MenuItem>
            <MenuItem
              //isDisabled={isEmpty(nodeBuffer)}
              onClick={handlePastInBufferNode}
            >
              Вставить блок
            </MenuItem>
            <MenuDivider />
            <MenuItem onClick={handleCopyInBuffer}>Копировать настройки</MenuItem>
            <MenuItem
              //isDisabled={stateBuffer.nodeType !== node.type}
              onClick={handlePastInBuffer}
            >
              Вставить настройки
            </MenuItem>
            <MenuDivider />
            <MenuItem onClick={onCopy}>Копировать код</MenuItem>
          </ItemMenu>
        </HStack>
      </Flex>
    </GlobalHotKeys>
  );
};

export const ToolbarCustomizer = React.memo(ToolbarCustomizerMemo);
