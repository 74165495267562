import React from 'react';
import { InputColor } from '../ui/inputColor';
import { getBorderCortegeFromValue } from '../../../utils/getBorderСortegeFromValue';
import { InputSize } from '../ui/inputSize';
import { Border } from '../../icons';
import { GridControl } from '../gridControl';
import { useDispatch, useSelector } from 'react-redux';
import { updateNode } from '../../../store/project.reducer';

const BorderControlMemo = ({ nodeId, attribute = 'border' }) => {
  const dispatch = useDispatch();
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  const { attributes } = node;
  const [borderSize, borderStyle, borderColor] = getBorderCortegeFromValue(attributes[attribute]);

  const handleControl = (value, name) => {
    let style = '';
    if (name === 'borderSize') {
      style = `${value === '' ? '0px' : value} ${borderStyle} ${borderColor}`
    }

    if (name === 'borderStyle') {
      style = `${borderSize} ${value === '' ? 'solid' : value} ${borderColor}`
    }

    if (name === 'borderColor') {
      style = `${borderSize} ${borderStyle} ${value === '' ? '#000000' : value}`
    }

    const newNode = {
      ...node,
      attributes: {
        ...node.attributes,
        [attribute]: style
      }
    };
    dispatch(updateNode(newNode));
  };

  return (
    <GridControl>
      <InputSize
        //isClear={false}
        units={['px']}
        value={borderSize}
        leftElement={<Border boxSize="0.8rem" color="gray.300"/>}
        placeholder="Нет"
        onBlur={e => handleControl(e, 'borderSize')}
      />
      <InputColor
        isClear={false}
        value={borderColor}
        placeholder="Нет"
        onBlur={e => handleControl(e, 'borderColor')}
      />
    </GridControl>
  );
};

export const BorderControl = React.memo(BorderControlMemo);