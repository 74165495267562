export const uploadImageAction = (state, action) => {
  const entityNodes = { ...state.entityNodes };
  const node = {...entityNodes[action.payload.data.link]}

  const image = action.payload.data.imageUrl;
  const imageFileName = action.payload.data.file;
  const imageHeight = action.payload.data.height;
  const imageWidth =  action.payload.data.width;

  if (node.type === 'section') {
    node.attributes = {
      ...node.attributes,
      'background-url': image,
      'background-size': 'cover',
      'background-repeat': 'no-repeat'
    }
  } else if (node.type === 'image' ||
    node.type === 'social') {
    node.attributes = {
      ...node.attributes,
      'src': image
    }
  } else if (node.type === 'module') {
    node.attributes = {
      ...node.attributes,
      'background-url': image,
      'background-size': 'cover',
      'background-repeat': 'no-repeat'
    }
  } else if (node.type === 'hero') {
    node.attributes = {
      ...node.attributes,
      'background-url': image,
      'background-size': 'cover',
      'background-repeat': 'no-repeat'
    }
  }

  node.properties = {
    ...node.properties,
    imageFileName,
    imageHeight,
    imageWidth,
  }

  return {
    ...entityNodes,
    [action.payload.data.link]: { ...node }
  };
}