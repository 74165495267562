import React from 'react';
import { Box } from '@chakra-ui/react';
import { Element } from '../element';
import { SelectedNode } from '../../selectedNode';
import { getAttribute } from '../../../utils/getAttribute';
import { useSelector } from 'react-redux';

export const SectionMemo = ({ parentNodeId, nodeId }) => {
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  const { attributes, children } = node;
  return (
    <SelectedNode
      nodeId={node.id}
      lineHeight={1}
      direction={getAttribute(attributes, 'direction', 'ltr')}
    >
      <Box
        border={getAttribute(attributes, 'border')}
        {...attributes['full-width'] === 'full-width' && {'_before' : {
          content: `""`,
          position: 'absolute',
          display: 'block',
          backgroundColor: 'white',
          width: '980px',
          left: '-20px'
        }}}
      >
        <Box
          backgroundColor={getAttribute(attributes, 'background-color')}
          backgroundPosition={getAttribute(attributes, 'background-position', 'top center')}
          backgroundRepeat={getAttribute(attributes, 'background-repeat', 'repeat')}
          backgroundSize={getAttribute(attributes, 'background-size', 'auto')}
          backgroundImage={getAttribute(attributes, 'background-url')}
          borderRadius={getAttribute(attributes, 'border-radius')}
          paddingTop={getAttribute(attributes, 'padding-top', '20px')}
          paddingRight={getAttribute(attributes, 'padding-right')}
          paddingBottom={getAttribute(attributes, 'padding-bottom', '20px')}
          paddingLeft={getAttribute(attributes, 'padding-left')}
          textAlign={getAttribute(attributes, 'text-align', 'left')}
        >
          {children.map(item => (
            <Element
              key={item}
              parentNodeId={node.id}
              nodeId={item}
            />
          ))}
        </Box>
      </Box>
    </SelectedNode>
  )
};

export const Section = React.memo(SectionMemo);
