import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from '../components/privateRoute';
import Users from './users';
import Projects from './projects';
import Images from './images';
import { Logout } from './logout';
import { useSelector } from 'react-redux';
import TemplateList from './templates';
import Share from './share';

const Routes = () => {
  const user = useSelector(state => state.authentication.user);
  return (
    <Switch>
      <Route path={`/templates`} component={TemplateList} />
      <Route path={`/projects`} component={Projects} />
      <Route path={`/i`} component={Share} />
      <Route path="/images" component={Images} />
      <PrivateRoute
        path="/users"
        role={user.authorities}
        hasAnyRole={['ROLE_ADMIN']}
        component={Users}
      />
      <Route path="/logout" component={Logout}/>
      <Route path="/">
        <Redirect to="/projects" />
      </Route>
    </Switch>
  );
};

export default Routes;
