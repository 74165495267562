import isEmpty from 'lodash/isEmpty';

export const getIdsChildrenNodes = (data = {}, id) => {
  let collection = [];
  const foo = (node) => {
    if (node.id) {
      collection.push(node.id)
    }
    if (!isEmpty(node.children)) {
      node.children.forEach(item => {
        foo(data[item])
      })
    }
  }
  foo(data[id]);
  return collection;
};
