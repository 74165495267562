import React, { useMemo, useState } from 'react';
import { Button, VStack, useToast } from '@chakra-ui/react';
import { FormInput } from '../../../../components/formInput';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { ModalFooter } from '../../../../components/modal';
import { getSettingFromList } from '../../../../utils/getSettingFromList';
import axios from 'axios';
import { getImagesFromNodes } from '../../../../utils/dataObj/getImagesFromNodes';

const getEmails = (data) => {
  const arr = data.split(',');
  return arr.map(item => item.replace(/\s+/g, ' ').trim());
}

export const SendMailForm = () => {
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const projectNodesType = useSelector(state => state.project.present.entityNodesType);
  const nodeContainer = projectNodes[projectNodesType?.container?.id];
  const toast = useToast();
  const userAuth = useSelector(state => state.authentication.user);
  const settingList = useSelector(state => state.settingMail.entities);
  const isEmbeddedImageAttribute = nodeContainer?.properties?.embeddedImage;
  const projectHtml = useSelector(state => state.html.entity);
  const settings = useMemo(() => getSettingFromList(settingList, userAuth.id), [settingList, userAuth.id]);
  const [loading, setLoading] = useState(false);

  const { register, errors, handleSubmit } = useForm({
    defaultValues: {
      login: settings?.login ? settings.login : '',
      password: settings?.password ? settings.password : ''
    }
  });

  const onSubmit = async (data) => {
    const dataRequest = {
      subject: data.subject,
      smtpId: settings.id,
      emails: getEmails(data.email),
      body: projectHtml.html,
      ...isEmbeddedImageAttribute && { images: getImagesFromNodes(projectNodes) }
    }
    setLoading(true);
    await axios.post('/api/send', {
      ...dataRequest
    });
    setLoading(false);
    toast({
      title: 'Готово',
      description: `Письмо отправлено`,
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="14px">
        <FormInput
          name="email"
          label="Получатель"
          placeholder="user@sovcombank.ru"
          errors={errors}
          ref={register({ required: true })}
        />
        <FormInput
          name="subject"
          label="Тема"
          placeholder=""
          errors={errors}
          ref={register({ required: true })}
        />
      </VStack>
      <ModalFooter>
        <Button
          type="submit"
          colorScheme="blue"
          width="100%"
          isLoading={loading}
        >
          Отправить
        </Button>
      </ModalFooter>
    </form>
  );
};
