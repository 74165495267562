import { Icon } from '@chakra-ui/react';

export const LineHeight = (props) => {
  return (
    <Icon viewBox="0 0 12 13" {...props}>
      <g fill="currentColor">
        <path d="M12,12 L12,13 L0,13 L0,12 L12,12 Z M6.18408203,2.24951172 L9.04150391,10 L8.02636719,10 L7.24755859,7.78173828 L4.16455078,7.78173828 L3.38574219,10 L2.37060547,10 L5.22802734,2.24951172 L6.18408203,2.24951172 Z M5.74902344,3.50097656 L5.66308594,3.50097656 L4.44921875,6.95996094 L6.96289062,6.95996094 L5.74902344,3.50097656 Z M12,0 L12,1 L0,1 L0,0 L12,0 Z" fillRule="nonzero" />
      </g>
    </Icon>
  );
};
