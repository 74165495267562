import React from 'react';
import { RadioIconButtons } from '../ui/radioIconButtons';
import { VAlignBottom, VAlignCenter, VAlignTop } from '../../icons';
import { useDispatch, useSelector } from 'react-redux';
import { updateNode } from '../../../store/project.reducer';

const VerticalAlignControlMemo = ({ nodeId, attribute = 'vertical-align' }) => {
  const dispatch = useDispatch();
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  const { attributes } = node;

  const handleControl = (value) => {
    const newNode = {
      ...node,
      attributes: {
        ...node.attributes,
        [attribute]: value
      }
    };
    dispatch(updateNode(newNode));
  };

  return (
    <RadioIconButtons
      options={['top', 'middle', 'bottom']}
      value={attributes[attribute]}
      onChange={handleControl}
      icons={{
        'top': <VAlignTop boxSize={3} color="black"/>,
        'middle': <VAlignCenter boxSize={3} color="black"/>,
        'bottom': <VAlignBottom boxSize={3} color="black"/>
      }}
    />
  );
};

export const VerticalAlignControl = React.memo(VerticalAlignControlMemo);