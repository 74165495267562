import { v4 as uuidv4 } from 'uuid';

export const denormalizeObj = (data, startNode, isGenerateId) => {
  const foo = (data, node) => {
    return {
      id: isGenerateId ? uuidv4() : node.id,
      type: node.type,
      attributes: node.attributes ? node.attributes : {},
      properties: node.properties ? node.properties : {},
      content: node.content ? node.content : [],
      children: node.children.map(id => foo(data, data[id]))
    }
  }
  return foo(data, startNode);
};
