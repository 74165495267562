import { headHtml } from './head';
import { bodyHtml } from './body';
import { headAttributesHtml } from './headAttributes';
import { headAllHtml } from './headAll';
import { headTextHtml } from './headText';
import { headClassHtml } from './headClass';
import { headStyleHtml } from './headStyle';
import { moduleHtml } from './module';
import { sectionHtml } from './section';
import { groupHtml } from './group';
import { columnHtml } from './column';
import { rowHtml } from './row';
import { rowCollectionHtml } from './rowCollection';
import { imageHtml } from './image';
import { spacerHtml } from './spacer';
import { dividerHtml } from './divider';
import { textHtml } from './text';
import { socialCollectionHtml } from './socialCollection';
import { socialHtml } from './social';
import { nodeType } from '../../models';
import { buttonHtml } from './button';
import { heroHtml } from './hero';
import { rawHtml } from './raw';

export const getElementsHtml = (data, parentNode, node, preview) => {
  const { type } = node;
  if (!node?.properties?.hidden) {
    switch (type) {
      case 'head':
        return headHtml(data, parentNode, node, preview)
      case 'body':
        return bodyHtml(data, parentNode, node, preview)
      case 'raw':
        return rawHtml(data, parentNode, node, preview)
      case 'headAttributes':
        return headAttributesHtml(data, parentNode, node, preview)
      case 'headAll':
        return headAllHtml(data, parentNode, node, preview)
      case 'headText':
        return headTextHtml(data, parentNode, node, preview)
      case 'headClass':
        return headClassHtml(data, parentNode, node)
      case 'headStyle':
        return headStyleHtml(data,parentNode, node, preview)
      case 'module':
        return moduleHtml(data, parentNode, node, preview)
      case 'section':
        return sectionHtml(data, parentNode, node, preview)
      case 'group':
        return groupHtml(data, parentNode, node, preview)
      case 'column':
        return columnHtml(data, parentNode, node, preview)
      case 'row':
        return rowHtml(data, parentNode, node, preview)
      case 'rowCollection':
        return rowCollectionHtml(data, parentNode, node, preview)
      case 'image':
        return imageHtml(data, parentNode, node, preview)
      case 'spacer':
        return spacerHtml(data, parentNode, node, preview)
      case 'divider':
        return dividerHtml(data, parentNode, node, preview)
      case 'text':
        return textHtml(data, parentNode, node, preview)
      case 'socialCollection':
        return socialCollectionHtml(data, parentNode, node, preview)
      case 'social':
        return socialHtml(data, parentNode, node, preview)
      case nodeType.button:
        return buttonHtml(data, parentNode, node, preview)
      case nodeType.hero:
        return heroHtml(data, parentNode, node, preview)
      default:
        return ''
    }
  } else return null;
};