import React from 'react';
import { Image as ImageChakra, Box } from '@chakra-ui/react';
import { SelectedNode } from '../../selectedNode';
import { getAttribute } from '../../../utils/getAttribute';
import { getAttributeFromParent } from '../../../utils/getAttributeFromParent';
import { useSelector } from 'react-redux';
import empty from '../../../images/empty-image.svg';

export const Social = ({ parentNodeId, nodeId }) => {
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  const parentNode = projectNodes[parentNodeId];
  const { attributes } = node;
  const attributesParent = parentNode.attributes;
  const padding = attributes.padding ? attributes.padding : getAttribute(attributesParent, 'inner-padding', '4px');
  return (
    <SelectedNode
      nodeId={node.id}
      display="inline-block"
      padding={padding}
      verticalAlign="middle"
    >
      <Box
        textAlign={getAttribute(attributes, 'align', 'center')}
        backgroundColor={getAttribute(attributes, 'background-color')}
        borderRadius={getAttributeFromParent(attributes, attributesParent, 'border-radius', '3px')}
      >
        <Box
          padding={getAttributeFromParent(attributes, attributesParent, 'icon-padding', '0px')}
        >
          {attributes.src ? (
            <ImageChakra
              width={getAttributeFromParent(attributes, attributesParent, 'icon-size', '20px')}
              height={getAttributeFromParent(attributes, attributesParent, 'icon-size', '20px')}
              src={getAttribute(attributes, 'src')}
            />
          ) : (
            <Box
              width={getAttributeFromParent(attributes, attributesParent, 'icon-size', '20px')}
              height={getAttributeFromParent(attributes, attributesParent, 'icon-size', '20px')}
              backgroundImage={`url(${empty})`}
            />
          )}
        </Box>
      </Box>
    </SelectedNode>
  );
};
