import { v4 as uuidv4 } from 'uuid';

export const socialPreset = () => {
  return {
    id: uuidv4(),
    type: 'social',
    attributes: {},
    properties: {
      imageFileName: 'inst.png',
      imageHeight: 64,
      imageWidth: 64
    },
    children: []
  }
};
