import { getNodesByIds } from '../../../utils/dataObj/getNodesByIds';
import { getElementsHtml } from '../elementHtml';
import { getAttributesFromNode } from '../../../utils/dataObj/getAttributesFromNode';

export const moduleHtml = (data, parentNode, node, preview) => {
  const children = getNodesByIds(data, node.children);
  if (node?.properties?.wrapper) {
    return (`
      <mj-wrapper${getAttributesFromNode(node, parentNode, preview)}>
        ${children.map(item => (
          getElementsHtml(data, node, item, preview)
        )).join('')}
      </mj-wrapper>
    `)
  } else {
    return (`
      ${children.map(item => (
      getElementsHtml(data, node, item, preview)
    )).join('')}
  `);
  }
};
