import { Icon } from '@chakra-ui/react';

export const VAlignCenter = (props) => {
  return (
    <Icon viewBox="0 0 10 3" {...props}>
      <g fill="currentColor">
        <rect x="0" y="0" width="10" height="3"/>
      </g>
    </Icon>
  );
};
