import { v4 as uuidv4 } from 'uuid';

export const socialCollectionPreset = () => {
  return {
    id: uuidv4(),
    type: 'socialCollection',
    attributes: {
      'padding-left': '10px',
      'padding-right': '10px',
      'padding-top': '10px',
      'padding-bottom': '10px',
      'icon-size': '28px'
    },
    properties: {},
    children: [
      {
        id: uuidv4(),
        type: 'social',
        attributes: {
          'href': 'https://www.facebook.com/misovcombank/',
          'src':'/images/1c9fd2ed6501f7857e868a83869a8b03.png'
        },
        properties: {
          imageFileName: 'fb.png',
          imageHeight: 64,
          imageWidth: 64
        },
        children: []
      },
      {
        id: uuidv4(),
        type: 'social',
        attributes: {
          'href': 'https://www.instagram.com/sovcombank_people/',
          'src': '/images/99d257b35474d7a4b15b7a8b9d1227f2.png'
        },
        properties: {
          imageFileName: 'inst.png',
          imageHeight: 64,
          imageWidth: 64
        },
        children: []
      },
      {
        id: uuidv4(),
        type: 'social',
        attributes: {
          'href': 'https://ok.ru/misovcombank',
          'src':'/images/50fda614c7b581bfad97e633af803e2d.png'
        },
        properties: {
          imageFileName: 'ok.png',
          imageHeight: 64,
          imageWidth: 64
        },
        children: []
      },
      {
        id: uuidv4(),
        type: 'social',
        attributes: {
          'href': 'https://vk.com/misovcombank',
          'src': '/images/1f49e73730ba7ed8c4b1943af63fe81b.png'
        },
        properties: {
          imageFileName: 'vk.png',
          imageHeight: 64,
          imageWidth: 64
        },
        children: []
      }
    ]
  }
};
