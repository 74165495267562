export const copyNodeAction = (state, action) => {
  const entityNodes = {
    ...state.entityNodes,
    ...action.childrenNodes
  };
  const children = [...entityNodes[action.node.id].children];
  const childrenIndex = children.findIndex(item => item === action.copyChildrenId);
  children.splice(childrenIndex, 0, action.firstChildrenNodeId)
  entityNodes[action.node.id] = {
    ...entityNodes[action.node.id],
    children
  };
  return entityNodes;
};
