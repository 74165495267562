import React, { useEffect, useState } from 'react';
import { Input as ChakraInput, InputGroup, InputLeftElement } from '@chakra-ui/react';

export const Input = React.forwardRef((allProps, ref) => {
  const {
    leftElement,
    value = '',
    onBlur,
    ...props
  } = allProps;

  const [valueInput, setValueInput] = useState(value);
  const handleInputBlur = () => {
    if (value !== valueInput) {
      onBlur && onBlur(valueInput);
    }
  };

  const handleKeyDown = (e) => {
    switch (e.which) {
      case 13: // Enter Key
      {
        onBlur(valueInput);
        break;
      }
      default:
        return;
    }
    e.preventDefault();
  };

  useEffect(() => {
    setValueInput(value === undefined ? '' : value);
  }, [value])

  return (
    <>
      <InputGroup size="xs">
        <InputLeftElement
          pointerEvents="none"
          children={leftElement}
        />
        <ChakraInput
          ref={ref}
          borderColor="transparent"
          value={valueInput}
          onChange={e => setValueInput(`${e.target.value}`)}
          onBlur={handleInputBlur}
          onKeyDown={handleKeyDown}
          {...props}
        />
      </InputGroup>
    </>

  )
});
