import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { checkAuth } from './store/authentication.reducer';
import Pages from './pages';

function App() {
  const dispatch = useDispatch();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(checkAuth());
  }, []);

  return (
    <Box>
      <Pages/>
    </Box>
  );
}

export default App;
