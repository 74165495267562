import React from 'react';
import { StackControl } from '../stackControl';
import { GridControl } from '../gridControl';
import { PaddingTopControl } from '../controls/paddingTop.control';
import { PaddingRightControl } from '../controls/paddingRight.control';
import { PaddingBottomControl } from '../controls/paddingBottom.control';
import { PaddingLeftControl } from '../controls/paddingLeft.control';
import { BorderRadiusControl } from '../controls/borderRadius.control';
import { IndentShortControl } from '../controls/IndentShort.control';
import { TextAlignControl } from '../controls/textAlign.control';
import { VerticalAlignControl } from '../controls/verticalAlign.control';
import { WidthControl } from '../controls/width.control';
import { HeightControl } from '../controls/height.control';
import { FontSizeControl } from '../controls/fontSize.control';
import { LineHeightControl } from '../controls/lineHeight.control';
import { FontStyleControl } from '../controls/fontStyle.control';
import { ColorControl } from '../controls/color.control';
import { LinkControl } from '../controls/link.control';
import { BorderControl } from '../controls/border.control';
import { useSelector } from 'react-redux';

export const ButtonModuleMemo = ({ nodeId }) => {
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];
  return (
    <>
      <StackControl>
        <GridControl label="Отступы">
          <PaddingLeftControl nodeId={node.id} placeholder="25px"/>
          <PaddingRightControl nodeId={node.id} placeholder="25px"/>
          <PaddingTopControl nodeId={node.id} placeholder="10px"/>
          <PaddingBottomControl nodeId={node.id} placeholder="10px"/>
        </GridControl>
        <GridControl label="Скругление">
          <BorderRadiusControl nodeId={node.id} placeholder="3px"/>
        </GridControl>
      </StackControl>
      <StackControl>
        <GridControl templateColumns="1fr" label="Отступы внутри">
          <IndentShortControl nodeId={node.id} />
        </GridControl>
      </StackControl>
      <StackControl>
        <GridControl label="Фон">
          <ColorControl nodeId={node.id} attribute="background-color"/>
          <ColorControl nodeId={node.id} attribute="container-background-color" />
        </GridControl>
      </StackControl>
      <StackControl>
        <GridControl templateColumns="1fr" label="Рамка">
          <BorderControl nodeId={node.id}/>
        </GridControl>
      </StackControl>
      <StackControl>
        <GridControl label="Размеры">
          <WidthControl nodeId={node.id} placeholder="0px"/>
          <HeightControl nodeId={node.id} placeholder="0px"/>
        </GridControl>
      </StackControl>
      <StackControl>
        <GridControl label="Шрифт">
          <FontSizeControl nodeId={node.id}/>
          <LineHeightControl nodeId={node.id}/>
          <ColorControl nodeId={node.id} attribute="color" isClear={false}/>
          <FontStyleControl nodeId={node.id}/>
        </GridControl>
      </StackControl>
      <StackControl>
        <GridControl templateColumns="1fr" label="Ссылка">
          <LinkControl nodeId={node.id}/>
        </GridControl>
      </StackControl>
      <StackControl>
        <GridControl label="Выравнивание">
          <TextAlignControl nodeId={node.id} attribute="align"/>
          <VerticalAlignControl nodeId={node.id}/>
        </GridControl>
      </StackControl>
    </>
  );
};

export const ButtonModule = React.memo(ButtonModuleMemo);
