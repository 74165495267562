import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { SendMailSettings } from './sendMailSettings';
import { SendMailForm } from './sendMailForm';
import { Container, ContentWrapper } from '../../../../components/layout';

export const SendMail = () => {
  return (
   <>
     <ContentWrapper>
       <Container maxWidth="600px">
         <Box mb="20px">
           <Text fontSize="1.3rem" fontWeight="semibold" mb="10px">Рассылка</Text>
         </Box>
         <Box pb="80px">
           <Text mb={3} fontWeight="semibold">Учетная запись Совкомбанк</Text>
           <SendMailSettings mb="14px"/>
           <Text mt={6} mb={3} fontWeight="semibold">Настройка рассылки</Text>
           <SendMailForm/>
         </Box>
       </Container>
     </ContentWrapper>
   </>
  );
};
