import { FAILURE, REQUEST, SUCCESS } from './actions-type';
import axios from 'axios';

export const ACTION_TYPES = {
  FETCH_ENTITIES_LIST: 'settingMail/FETCH_ENTITIES_LIST',
  RESET_ENTITIES_LIST: 'settingMail/RESET_ENTITIES_LIST',
  CREATE_ENTITY: 'settingMail/CREATE_ENTITY',
  FETCH_ENTITY: 'settingMail/FETCH_ENTITY',
  UPDATE_ENTITY: 'settingMail/UPDATE_SMTP',
  DELETE_ENTITY: 'settingMail/DELETE_ENTITY',
  RESET_ENTITY: 'settingMail/RESET_SMTP',
  RESET: 'settingMail/RESET'
};

const initialState = {
  entities: [],
  entity: {},
  loading: false,
  errorMessage: ''
};

export const settingMailReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ENTITIES_LIST):
    case REQUEST(ACTION_TYPES.CREATE_ENTITY):
    case REQUEST(ACTION_TYPES.FETCH_ENTITY):
    case REQUEST(ACTION_TYPES.UPDATE_ENTITY):
    case REQUEST(ACTION_TYPES.DELETE_ENTITY):{
      return {
        ...state,
        loading: true,
        errorMessage: ''
      }
    }
    case FAILURE(ACTION_TYPES.FETCH_ENTITIES_LIST):
    case FAILURE(ACTION_TYPES.CREATE_ENTITY):
    case FAILURE(ACTION_TYPES.FETCH_ENTITY):
    case FAILURE(ACTION_TYPES.UPDATE_ENTITY):
    case FAILURE(ACTION_TYPES.DELETE_ENTITY):{
      return {
        ...state,
        loading: false,
        errorMessage: 'error'
      }
    }
    case SUCCESS(ACTION_TYPES.FETCH_ENTITIES_LIST): {
      return {
        ...state,
        loading: false,
        errorMessage: '',
        entities: action.payload.data.items
      }
    }
    case SUCCESS(ACTION_TYPES.FETCH_ENTITY): {
      return {
        ...state,
        loading: false,
        errorMessage: '',
        entity: action.payload.data
      }
    }
    case SUCCESS(ACTION_TYPES.UPDATE_ENTITY): {
      const index = state.entities.findIndex(item => item.id === action.payload.data.id);
      const entities = [...state.entities];
      entities[index] = action.payload.data;
      return {
        ...state,
        loading: false,
        errorMessage: '',
        entities: entities
      }
    }
    case SUCCESS(ACTION_TYPES.CREATE_ENTITY): {
      return {
        ...state,
        loading: false,
        errorMessage: '',
        entities: [
          ...state.entities,
          action.payload.data
        ]
      }
    }
    case SUCCESS(ACTION_TYPES.DELETE_ENTITY): {
      const items = state.entities.filter(item => item.id !== action.payload.data.id);
      return {
        ...state,
        items,
        smtp: {},
        loading: false,
        errorMessage: ''
      }
    }
    case ACTION_TYPES.RESET_ENTITIES_LIST: {
      return {
        ...state,
        images: []
      }
    }
    case ACTION_TYPES.RESET_ENTITY: {
      return {
        ...state,
        smtp: {}
      }
    }
    case ACTION_TYPES.RESET: {
      return {
        ...state,
        items: [],
        smtp: {},
        loading: false
      }
    }
    default: return state;
  }
};

const API_URL = '/api/smtp';


export const getSettingMailList = () => {
  const requestUrl = `/api/user/smtp`;
  return {
    type: ACTION_TYPES.FETCH_ENTITIES_LIST,
    payload: axios.get(requestUrl)
  };
};

export const createSettingMail = (dataRequest) => {
  const requestUrl = `${API_URL}`;
  return {
    type: ACTION_TYPES.CREATE_ENTITY,
    payload: axios.post(requestUrl, {
      ...dataRequest
    })
  }
};

export const updateSettingMail = (dataRequest) => {
  const requestUrl = `${API_URL}`;
  return {
    type: ACTION_TYPES.UPDATE_ENTITY,
    payload: axios.put(requestUrl, {
      ...dataRequest
    })
  };
};

export const deleteSettingMail = (id) => {
  const requestUrl = `${API_URL}/${id}`;
  return {
    type: ACTION_TYPES.DELETE_ENTITY,
    payload: axios.delete(requestUrl)
  };
};

export const getSettingMail = (id) => {
  const requestUrl = `/api/user/smtp`;
  return {
    type: ACTION_TYPES.FETCH_ENTITY,
    payload: axios.get(requestUrl)
  };
};

export const resetSettingMail = () => {
  return {
    type: ACTION_TYPES.RESET_ENTITY
  };
};

export const reset = () => {
  return {
    type: ACTION_TYPES.RESET
  };
};
