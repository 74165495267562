import React, { useEffect, useState } from 'react';
import { Box, Grid, Avatar, IconButton, Flex } from '@chakra-ui/react';
import { SettingsIcon } from '@chakra-ui/icons';
import { useSelector } from 'react-redux';

export const UsersItem = (props) => {
  const { onClick, onSettingClick, item } = props;
  const { id, name, email } = item;
  const loadingUser = useSelector(state => state.user.loadingUser);
  const [isLoadingSetting, setIsLoadingSetting] = useState(false);

  const handleSettingClick = (e) => {
    e.stopPropagation();
    setIsLoadingSetting(true)
    onSettingClick && onSettingClick(id);
  };

  useEffect(() => {
    if (!loadingUser) {
      setIsLoadingSetting(false)
    }
  }, [loadingUser])

  return (
    <>
      <Grid
        templateColumns="42px 1fr 1fr 30px"
        alignItems="center"
        role="group"
        borderBottomWidth="1px"
        transition="0.2s"
        gap="8px"
        cursor="pointer"
        height="58px"
        fontSize="0.9rem"
        pl="8px"
        pr="6px"
        _hover={{
          backgroundColor: 'gray.50'
        }}
        _focus={{
          outline: 'none',
        }}
        _active={{
          backgroundColor: 'gray.50',
          boxShadow: '0 0 0 3px rgba(66,153,225,0.6)',
          borderRadius: '4px'
        }}
        onClick={onClick}
      >
        <Avatar name={email} width="32px" height="32px" fontSize="0.6rem" />
        <Box
          transition="0.2s"
          wordBreak="break-all"
          lineHeight="18px"
          _groupHover={{
            color: 'blue.400'
          }}
        >
          {name}
        </Box>
        <Box color="gray.400">{email}</Box>
        <Flex justifyContent="flex-end" onClick={e => e.stopPropagation()}>
          <IconButton
            aria-label="Опции"
            variant="ghost"
            isRound
            color="gray.300"
            icon={<SettingsIcon/>}
            size="sm"
            isLoading={isLoadingSetting}
            opacity="0"
            _active={{
              color: 'blue.500'
            }}
            _hover={{
              color: 'blue.500'
            }}
            _focus={{
              boxShadow: 'none'
            }}
            _groupHover={{
              opacity: '1'
            }}
            onClick={handleSettingClick}
          />
        </Flex>
      </Grid>
    </>
  );
};
