import React from 'react';
import { Checkbox } from '../ui/checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { updateNode } from '../../../store/project.reducer';

const FullWidthControlMemo = ({ nodeId, attribute = 'full-width', ...props }) => {
  const dispatch = useDispatch();
  const projectNodes = useSelector(state => state.project.present.entityNodes);
  const node = projectNodes[nodeId];

  const handleControl = (value) => {
    const newNode = {
      ...node,
      attributes: {
        ...node.attributes,
        [attribute]: value ? 'full-width' : 'false'
      }
    };
    dispatch(updateNode(newNode));
  }

  return (
    <Checkbox
      name="full-width"
      label="Полная ширина"
      onChange={handleControl}
      value={node?.attributes[attribute] === 'full-width'}
      {...props}
    />
  );
};

export const FullWidthControl = React.memo(FullWidthControlMemo);
