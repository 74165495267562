import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Redirect } from 'react-router-dom';
import { Box, Spinner, Text } from '@chakra-ui/react';
import { logoutAuth } from '../../store/authentication.reducer';

export const Logout = () => {
    const dispatch = useDispatch();
    const userAuth = useSelector(state => state.authentication.user);
    const isAuthenticated = !isEmpty(userAuth);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        dispatch(logoutAuth());
    }, [userAuth]);

    return isAuthenticated ? (
        <>
            <Box
                width="100%"
                height="100%"
                display="flex"
                flexDirection="column"
                alignContent="center"
                alignItems="center"
                padding="60px"
            >
                <Text>
                    Загрузка...
                </Text>
                <Spinner color="blue.500" size="md" marginTop="20px" />
            </Box>
        </>
    ) : <Redirect to="/" />
}
