import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { TextArea } from '../textArea';
import isEqual from 'lodash/isEqual';
import { parseMdConstructor } from '../../utils/parseMd';
import { getAttribute } from '../../utils/getAttribute';
import { useSelector } from 'react-redux';

export const TextEditor = (props) => {
  const { data = [], isReadOnly, nodeId, attributes, onBlur, isInnerHtml = true } = props;
  const textEditing = useSelector(state => state.selected.textEditing);
  const [value, setValue] = useState(data);

  const text = value.length && value.join('\n');
  const html = parseMdConstructor(text);

  const handleTextArea = (e) => {
    const newValue = e.split('\n');
    setValue(newValue);
    if (!isEqual(newValue, data)) {
      onBlur(newValue);
    }
  }

  const handleDoubleClick = (e) => {
    if (textEditing !== '') {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  useEffect(() => {
    setValue(data);
  }, [data]);

  return (
    <Box
      position="relative"
      role="group"
      onDoubleClick={handleDoubleClick}
    >
      <Box
        opacity={textEditing === nodeId ? '0' : '1'}
        visibility={textEditing === nodeId ? 'hidden' : 'visible'}
        position={textEditing === nodeId ? 'relative' : 'relative'}
        top="0"
        left="0"
        width="100%"
        height="100%"
        pointerEvents="none"
        style={{
          direction: 'ltr'
        }}
      >
        <Box
          textAlign={getAttribute(attributes, 'align')}
          fontSize={getAttribute(attributes, 'font-size', '13px')}
          lineHeight={getAttribute(attributes, 'line-height')}
          color={getAttribute(attributes, 'color')}
          fontWeight={getAttribute(attributes, 'font-weight')}
          fontStyle={getAttribute(attributes, 'font-style')}
          fontFamily={`'Helvetica Neue', Helvetica, Arial, sans-serif`}
          {...isInnerHtml ? {dangerouslySetInnerHTML: {__html: html}} : {children: text}}
        />
      </Box>
      {!isReadOnly && (
        <TextArea
          value={value.length ? value.join('\n') : ''}
          onChange={handleTextArea}
          onMouseUp={handleDoubleClick}
          isEditing={textEditing === nodeId}
          style={{
            'lineHeight': getAttribute(attributes, 'line-height', '20px'),
            'fontSize': getAttribute(attributes, 'font-size', '13px'),
            'fontWeight': getAttribute(attributes, 'font-weight'),
            'fontStyle': getAttribute(attributes, 'font-style'),
            'color': getAttribute(attributes, 'color', '#000000'),
            'textAlign': getAttribute(attributes, 'align', 'left'),
            'fontFamily': `'Helvetica Neue', Helvetica, Arial, sans-serif`
          }}
        />
      )}
    </Box>
  );
};
