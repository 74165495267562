import { Icon } from '@chakra-ui/react';

export const Height = (props) => {
  return (
    <Icon viewBox="0 0 8 8" {...props}>
      <g fill="currentColor">
        <polygon fillRule="nonzero" points="1.95507812 8 1.95507812 4.47119141 6.20898438 4.47119141 6.20898438 8 7.17578125 8 7.17578125 0.249511719 6.20898438 0.249511719 6.20898438 3.60107422 1.95507812 3.60107422 1.95507812 0.249511719 0.98828125 0.249511719 0.98828125 8"/>
      </g>
    </Icon>
  );
};
