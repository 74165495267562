import { Icon } from '@chakra-ui/react';

export const Radius = (props) => {
  return (
    <Icon viewBox="0 0 10 10" {...props}>
      <g fill="currentColor">
        <path d="M6,0.5 C7.8685634,0.5 9.39512645,1.9642776 9.49482114,3.8079648 L9.5,4 L9.5,10 L8.5,10 L8.5,4 C8.5,2.6745166 7.46846406,1.58996133 6.16437569,1.50531768 L6,1.5 L0,1.5 L0,0.5 L6,0.5 Z"/>
      </g>
    </Icon>
  );
};
