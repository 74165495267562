import React from 'react';
import { Box } from '@chakra-ui/react';


export const ModulesPanel = ({ children, ...prop }) => {
  return (
    <Box
      width="240px"
      height="100%"
      //position="absolute"
      zIndex={2}
      top={0}
      left={0}
      borderRightWidth={1}
      backgroundColor="white"
    >
      <Box
        {...prop}
      >
        {children}
      </Box>

    </Box>
  );
};
